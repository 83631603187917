import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import { getFromAPI, postToAPI, formatISODateWithTimezone } from "../../../Utils/utils";
import { toast } from 'react-toastify';
import { useBranch, BranchProvider } from './getBranchData'; // Import the useCustomer hook
import messages from "../../../Utils/lang";

export default function NewBranch({ onSuccess }) {

  const date = new Date();
  const futureDate = date.getDate();
  date.setDate(futureDate);
  const defaultValue = date.toLocaleDateString('en-CA');
  const initialInputData = {
    name: '',
    business_name: '',
    location: '',
    country: '',
    state: '',
    city: '',
    phone_number_1: '',
    pan_card_number: '',
    gst_number: '',
    tax_id: 0,
    tax_type: 1,
    branch_type_id: 1,
    bank_name: 0,
    bank_account_no: '0',
    bank_ifsc_code: '0',
    owner_user_id: 1,
    created_user_id: 1,
    last_modified_user_id: 1,
    created_date_time: formatISODateWithTimezone(new Date()),
    last_modified_date_time: formatISODateWithTimezone(new Date()),
    isonline: 0,
    isactive: 1,
    isdeleted: 0,
    reserve_varchar1: 'NA',
    reserve_varchar2: 'NA',
    reserve_float1: 0.0,
    reserve_float2: 0.0,
    reserve_int1: 0,
    reserve_int2: 0,
    reserve_double1: 0.0,
    reserve_date1: new Date().toISOString().split('T')[0]
  };

  const { fetchBranchData, getBranchData } = useBranch();
  const [branchInputData, setBranchInputData] = useState(initialInputData);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [taxData, setTaxData] = useState([]);
  // Function for handle input for phone_number

  const handleMobileChange = (e) => {
    const { name, value } = e.target;
    setBranchInputData((prev) => ({
      ...prev,
      [name]: value.replace(/\+/g, ''),
    }));
  };


  // Fetch country data
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const response = await getFromAPI('FtsCountries/');
        setCountryData(response);
      } catch (error) {
        console.error('Error fetching country data:', error);
      }
    };
    fetchCountryData();
  }, []);

  // Fetch bank data
  useEffect(() => {
    const fetchBankData = async () => {
      try {
        const response = await getFromAPI('FtsBanks/');
        setBankData(response);
      } catch (error) {
        console.error('Error fetching bank data:', error);
      }
    };
    fetchBankData();
  }, []);

  // Fetch tax data
  useEffect(() => {
    const fetchTaxData = async () => {
      try {
        const response = await getFromAPI('FtsTaxes/');
        setTaxData(response);
      } catch (error) {
        console.error('Error fetching tax data:', error);
      }
    };
    fetchTaxData();
  }, []);

  // Handle country change and fetch states
  const handleCountryChange = async (e) => {
    const selectedCountry = e.target.value;
    setBranchInputData((prevState) => ({ ...prevState, country: parseInt(selectedCountry) }));
    try {
      const response = await getFromAPI(`FtsState/?country_code=${selectedCountry}`);
      setStateData(response);
      setCityData([]); // Reset city data
    } catch (error) {
      console.error('Error fetching state data:', error);
    }
  };

  // Handle state change and fetch cities
  const handleStateChange = async (e) => {
    const selectedState = e.target.value;
    setBranchInputData((prevState) => ({ ...prevState, state: parseInt(selectedState) }));
    try {
      const response = await getFromAPI(`FtsCity/?state_id=${selectedState}`);
      setCityData(response);
    } catch (error) {
      console.error('Error fetching city data:', error);
    }
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!branchInputData.name.trim()) {
      toast.error(messages.branch.required_name);
      return;
    }
    if (!branchInputData.business_name.trim()) {
      toast.error(messages.branch.required_business_name);
      return;
    }
    if (!branchInputData.location.trim()) {
      toast.error(messages.branch.required_location);
      return;
    }
    if (!branchInputData.phone_number_1 || branchInputData.phone_number_1.length !== 10) {
      toast.error(messages.branch.required_mobile);
      return;
    }
    if (!branchInputData.country) {
      toast.error(messages.branch.required_country);
      return;
    }
    if (!branchInputData.state) {
      toast.error(messages.branch.required_state);
      return;
    }
    if (!branchInputData.city) {
      toast.error(messages.branch.required_city);
      return;
    }

    const isExists = getBranchData.find(branch => branch.phone_number_1 === branchInputData.phone_number_1)
    if (isExists) {
      toast.error(messages.branch.already_exist);
      return;
    }

    const isBranchNameExists = Array.isArray(getBranchData) && getBranchData.find(branch =>
      branch.name === branchInputData.name
    );
  
  if(isBranchNameExists)
  {
      toast.error(messages.branch.already_exist_name);
      return;
  }

    try {
      const response = await postToAPI('FtsBranch/', branchInputData);
      if (response.status) {
        toast.success(messages.branch.branch_add);
        fetchBranchData();
        if (onSuccess && response.data.id) {
          onSuccess(response.data.id);
        }
        setBranchInputData(initialInputData);
      } else {
        toast.error(messages.branch.failed_to_add);
      }
    } catch (error) {
      toast.error('An error occurred while adding the Branch');
    }
  };

  const handleCancel = () => {
    setBranchInputData(initialInputData);
  };

  const isFormValid = () => {
    return (
      branchInputData.name.trim() &&
      branchInputData.business_name.trim() &&
      branchInputData.location.trim() &&
      branchInputData.phone_number_1 &&
      branchInputData.country &&
      branchInputData.state &&
      branchInputData.city && branchInputData.gst_number
      && branchInputData.pan_card_number 
      && branchInputData.bank_account_no && branchInputData.bank_ifsc_code && branchInputData.bank_name

    );
  }
  return (
    <>
      <Card className='right-content-details'>
        <Card.Body>
          <Card.Title>New Branch</Card.Title>
          <Form>
            <Row>
              <Col xl={6}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Branch Name <span className='text-danger'>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Enter branch name '
                    name='name'
                    id='name'
                    value={branchInputData.name}
                    onChange={(e) => setBranchInputData((prev) => ({ ...prev, name: e.target.value }))}
                  />
                </div>
              </Col>
              <Col xl={6}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Business Name <span className='text-danger'>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Enter business name'
                    name='business_name'
                    id='business_name'
                    value={branchInputData.business_name}
                    onChange={(e) => setBranchInputData((prev) => ({ ...prev, business_name: e.target.value }))}
                  />
                </div>
              </Col>
              <Col xl={4}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Phone Number <span className='text-danger'>*</span></Form.Label>
                  <Form.Control
                    type="number"
                    placeholder='Enter phone number'
                    name='phone_number_1'
                    id='phone_number_1'
                    value={branchInputData.phone_number_1}
                    onChange={handleMobileChange}
                  />
                </div>
              </Col>
              <Col xl={8}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Location <span className='text-danger'>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Enter location '
                    name='location'
                    id='location'
                    value={branchInputData.location}
                    onChange={(e) => setBranchInputData((prev) => ({ ...prev, location: e.target.value }))}
                  />
                </div>
              </Col>
              <Col sm={6} xl={4}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Country <span className='text-danger'>*</span></Form.Label>
                  <Form.Select

                    name='country'
                    id='country'
                    value={branchInputData.country}
                    onChange={handleCountryChange}
                  >
                    <option value="">Select Country</option>
                    {countryData.map(item => (
                      <option key={item.id} value={item.calling_code}>{item.short_name}</option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
              <Col sm={6} xl={4}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>State <span className='text-danger'>*</span></Form.Label>
                  <Form.Select

                    name='state'
                    id='state'
                    value={branchInputData.state}
                    onChange={handleStateChange}
                  >
                    <option value="">Select State</option>
                    {stateData.map(item => (
                      <option key={item.id} value={item.state_id}>{item.state_name}</option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
              <Col sm={6} xl={4}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>City <span className='text-danger'>*</span> </Form.Label>
                  <Form.Select

                    name='city'
                    id='city'
                    value={branchInputData.city}
                    onChange={e => setBranchInputData((prevState) => ({
                      ...prevState,
                      city: parseInt(e.target.value),
                    }))
                    }
                  >
                    <option value="">Select City</option>
                    {cityData.map(item => (
                      <option key={item.id} value={item.id}>{item.city_name}</option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
              <Col sm={6} xl={4}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>GST No <span className='text-danger'>*</span> </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Enter gst no '
                    name='gst_number'
                    id='gst_number'
                    value={branchInputData.gst_number}
                    onChange={(e) =>
                      setBranchInputData((prev) => ({ ...prev, gst_number: e.target.value }))
                    }
                  />
                </div>
              </Col>
              <Col sm={6} xl={4}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>PAN No <span className='text-danger'>*</span> </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Enter pan no '
                    name='panNo'
                    id='panNo'
                    value={branchInputData.pan_card_number}
                    onChange={e => setBranchInputData(prev => ({ ...prev, pan_card_number: e.target.value }))}
                  />
                </div>
              </Col>
              <Col sm={6} xl={4}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Tax <span className='text-danger'>*</span> </Form.Label>
                  <Form.Select

                    name='tax'
                    id='tax'
                    value={branchInputData.tax_id}
                    onChange={e => setBranchInputData(prev => ({ ...prev, tax_id: e.target.value }))}
                  >
                    <option value="">Select Tax</option>
                    {taxData.map(item => (
                      <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
              <Col sm={6} xl={4}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Tax Type <span className='text-danger'>*</span></Form.Label>
                  <Form.Select name='taxType' id='taxType' value={branchInputData.tax_type} onChange={e => setBranchInputData(prev => ({ ...prev, tax_type: e.target.value }))}
                  >
                    <option value="1">Inclusive</option>
                    <option value="2">Exclusive</option>

                  </Form.Select>
                </div>
              </Col>
              <Col sm={6} xl={4}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Branch Type <span className='text-danger'>*</span></Form.Label>
                  <Form.Select name='branchType' id='branchType' value={branchInputData.branch_type_id} onChange={e => setBranchInputData(prev => ({ ...prev, branch_type_id: e.target.value }))}>
                    <option value="1">Self</option>
                    <option value="2">Franchies</option>
                  </Form.Select>
                </div>
              </Col>
              <Col xl={4}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Bank Name <span className='text-danger'>*</span></Form.Label>
                  <Form.Select
                    name='bankName'
                    id='bankName'
                    value={branchInputData.bank_name}
                    onChange={(e) =>
                      setBranchInputData((prev) => ({
                        ...prev,
                        bank_name: parseInt(e.target.value),
                      }))
                    }
                  >
                    <option value="">Select Bank Name</option>
                    {bankData.map(item => (
                      <option key={item.id} value={item.bank_id}>{item.bank_name}</option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Bank Account No <span className='text-danger'>*</span></Form.Label>
                  <Form.Control
                    type="number"
                    placeholder='Enter bank account no '
                    name='bankAccountNo'
                    id='bankAccountNo'
                    value={branchInputData.bank_account_no}
                    onChange={e => setBranchInputData(prev => ({ ...prev, bank_account_no: e.target.value }))}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className="mb-3 custom-form-input">
                  <Form.Label>Bank IFSC Code <span className='text-danger'>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Enter bank IFSC code '
                    name='bankIFSCCode'
                    id='bankIFSCCode'
                    value={branchInputData.bank_ifsc_code}
                    onChange={e => setBranchInputData(prev => ({ ...prev, bank_ifsc_code: e.target.value }))}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </Card.Body>
        <div className='custom-card-footer'>
          <div className='text-end btn-list'>
            <Button variant="primary" disabled = {!isFormValid()} onClick={handleSubmit}>Add</Button>{' '}
            <Button variant="secondary" onClick={handleCancel}>Cancel</Button>{' '}
          </div>
        </div>
      </Card>
    </>
  )
}
