import React, { useState, useEffect, useContext } from 'react';
import { Card, Modal, Form, Row, Col, Button, Table } from 'react-bootstrap';

import PaymentModeData from "./payment-mode-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { getFromAPI, deleteFromAPI, showAlert, putToAPI, formatISODateWithTimezone, handleDateFormat } from '../../../Utils/utils';
import { toast } from "react-toastify";
import { PaymentModeContext1 } from './paymentModeContext'
import { PaymentModeProvider, usePaymentMode } from './getPaymentModeData';
import messages from '../../../Utils/lang';

export default function ViewPaymentMode({ setPaymentModeId }) {
    // Default Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [tableData, setTableData] = useState([]);
    const { paymentModeData, fetchPaymentModeData } = usePaymentMode();
    const selectedPaymentModeId = useContext(PaymentModeContext1)
    const [account, setAccount] = useState([]);
    const initialInputData = {
        name: '',
        created_branch_id: 0,
        description: '',
        show_on_pdf: 0,
        invoices_only: 0,
        expenses_only: 0,
        selected_by_default: 0,
        active: 1,
        isdeleted: 0
    }
    const [inputData, setInputData] = useState({ ...initialInputData });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getFromAPI(`FtsPaymentModes/?id=${selectedPaymentModeId}`);

                if (response && response.length > 0) {
                    setInputData({
                        name: response[0].name,
                        selected_by_default: response[0].selected_by_default,
                        description: response[0].description,
                    });
                }
            } catch (e) {
                toast.error('Error fetching Payment Mode  data');
            }
        };
        if (selectedPaymentModeId) {
            fetchData();
        }
    }, [selectedPaymentModeId]);


    const fetchPaymentModeTable = async () => {
        try {
            const response = await getFromAPI(`FtsPaymentModes/?id=${selectedPaymentModeId}`);
            const accountName = await getAccountNamebyId(response[0].selected_by_default)
            const mappedData = [
                { tableheading1: "Name :", tablesubtext1: response[0].name },
                { tableheading1: "Description :", tablesubtext1: response[0].description },
                { tableheading1: "Auto Entry Account :", tablesubtext1: accountName }
            ]
            setTableData(mappedData);
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        fetchPaymentModeTable();
    }, [selectedPaymentModeId]);

    const handleDelete = async (itemId) => {
        try {
            await deleteFromAPI(`FtsPaymentModes/${itemId}/`);
            toast.success('Payment Mode deleted successfully');
            handleClose();
            fetchPaymentModeData();
            // Update selectedUnitId to the first unit if available
            if (paymentModeData.length > 1) {
                setPaymentModeId(paymentModeData[0].id); // Set to the first unit
            } else {
                setPaymentModeId(); // No units left
            }
        } catch (error) {
            return null;
        }
    };

    // Handle Delete Customer Entry
    const handleDeletePaymentMode = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Payment Mode?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDelete(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            return null;
        }
    };

    const handleEdit = () => {
        setShow(true);
    };

    const fetchAccount = async () => {
        try {
            const response = await getFromAPI("FtsAccount/");
            if (response) {
                setAccount(response);
            }
            else {
                toast.error('Failed to fetch Payment Modes', 'error');
            }
        } catch (error) {
            toast.error('An unexpected error occurred');
            return null;
        }
    };
    useEffect(() => {
        fetchAccount();
    }, []);

    const handleSaveChanges = async () => {

        if (!inputData.name || !inputData.name.trim()) {
            toast.error(messages.payment_mode.required_name, 'error');
            return;
        }
        if (inputData.selected_by_default.length === 0) {
            toast.error(messages.payment_mode.select_account, 'error');
            return;
        }

        const isExists = Array.isArray(paymentModeData) && paymentModeData.find(payment => 
            payment.name.trim() === inputData.name.trim() &&
            payment.id !== selectedPaymentModeId // Exclude the current branch being edited
        );
        
        if (isExists) {
            toast.error(messages.payment_mode.already_exist);
                return;
            }

        try {
            await putToAPI(`FtsPaymentModes/${selectedPaymentModeId}/`, inputData);
            toast.success('Payment Mode updated successfully', 'success');
            setShow(false);
            fetchPaymentModeData();
            fetchPaymentModeTable();
        } catch (error) {
            return null;
        }
    };

    const getAccountNamebyId = async (code) => {
        const response = await getFromAPI(`FtsAccount/?id=${code}`)
        if (response && response.length > 0) {
            return response[0].name;
        } else {
            return 'Not Found';
        }
    }

    // table data
    const [paymentModeTableData] = useState(PaymentModeData);
    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>View Payment Mode</Card.Title>
                        <div>
                            <Button variant="soft-info p-0 me-3" onClick={handleEdit} disabled={!selectedPaymentModeId} >
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                </div>
                            </Button>
                            <Button variant="soft-danger p-0" onClick={() => handleDeletePaymentMode(selectedPaymentModeId)} disabled={!selectedPaymentModeId}>
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                </div>
                            </Button>
                        </div>
                    </div>
                    <Table responsive className='table-lg border table-nowrap mb-0'>
                        <tbody>
                            {tableData.map((data, i) =>
                                <tr key={i}>
                                    <th>{data.tableheading1}</th>
                                    <td>{data.tablesubtext1}</td>

                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Modal centered show={show} size="xl" onHide={handleClose}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>Edit Payment Mode</h5>

                    <Form>
                        <Row>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Payment Mode Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder='Enter payment mode name...'
                                        name='name'
                                        id='name'
                                        value={inputData.name}
                                        onChange={e => setInputData({ ...inputData, name: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Default Account (For Auto Entry)</Form.Label>
                                    <Form.Select
                                        name='selected_by_default'
                                        id='selected_by_default'
                                        value={inputData.selected_by_default}
                                        onChange={e => setInputData({ ...inputData, selected_by_default: e.target.value })}
                                    >
                                        <option value="">None</option>
                                        {account.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col xl={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder='Enter description...'
                                        name='description'
                                        id='description'
                                        value={inputData.description}
                                        onChange={e => setInputData({ ...inputData, description: e.target.value })} />
                                </div>
                            </Col>
                        </Row>
                        <div className='text-end btn-list'>
                            <Button variant="primary" onClick={handleSaveChanges}>Update</Button>{' '}
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>{' '}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
