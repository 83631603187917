import React from 'react';
import { Modal } from 'react-bootstrap';
import FormInputs from './FormInputs';

export default function FormModal({ modalSize, formModalShow, handleFormModalClose, title, subTitle, rows, handleInputChange, handleSubmit, Buttons }) {
    return (
        <>
            <Modal centered show={formModalShow} size={modalSize ? modalSize : 'xl'} onHide={handleFormModalClose}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    {title && <h5 className='mb-4'>{title}</h5>}
                    {subTitle && <h5 className='fs-16 mb-3'>{subTitle}</h5>}
                    <FormInputs Buttons={Buttons} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
                </Modal.Body>
            </Modal>
        </>
    )
}