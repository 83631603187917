import React, { useContext, useState,useEffect } from 'react';
import { Card, Modal, Form, Row, Col, Button, Table } from 'react-bootstrap';

import IncomeCategoryData from "./income-category-data.json";

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { toast } from 'react-toastify';
import { IncomeProvider, useIncome } from './getIncome';
import { IncomeContext1 } from './incomeContext';
import { getFromAPI,postToAPI,putToAPI,deleteFromAPI,formatISODateWithTimezone,handleDateFormat,showAlert } from '../../../Utils/utils';
import  messages from '../../../Utils/lang';

export default function ViewIncomeCategory({setIncomeCategoryId}) {
    // Default Modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const incomeCategoryId = useContext(IncomeContext1)
    const {incomCategoryeData,fetchIncomeData} = useIncome();
    const [tableData, setTableData] = useState([]); // Initialize state with an empty array

    const initialInputData = {
        category_name : '',
        description : '',
        isexpense : 0,
        created_branch_id : 0,
        created_user_id : 0,
        last_modfied_user_id : 0,
        created_date_time : formatISODateWithTimezone(new Date()),
        last_modified_date_time : formatISODateWithTimezone(new Date()),
        isdeleted : 0
    }
    const [inputData,setInputData] = useState({...initialInputData});


    useEffect(() => {
        const fetchIncomeCategoryDetails = async () => {
            try {
                const response = await getFromAPI(`FtsExpenseCategory/?id=${incomeCategoryId}`);
                if (response && response.length > 0) {
                    const fetchedData = response[0];
                    setInputData({
                        category_name: fetchedData.category_name,
                        description : fetchedData.description
                    });
                }
            } catch (error) {
                return null;
            }
        };
    
        if (incomeCategoryId) {
            fetchIncomeCategoryDetails();
        }
    }, [incomeCategoryId]);
 
    const fetchIncomeCategoryTable = async () => {
        try {
            const response = await getFromAPI(`FtsExpenseCategory/?id=${incomeCategoryId}`);
                   
            // Map API response to match the table data structure
            const mappedData = [
                { tableheading1: "Name :", tablesubtext1: response[0].category_name},
                { tableheading1: "Description :", tablesubtext1: response[0].description},
                { tableheading1: "Created User Id :", tablesubtext1: response[0].created_user_id},
                { tableheading1: "Created Date Time :", tablesubtext1: response[0].created_date_time.replace('T', ' ').replace('Z', '') }


            ]

            setTableData(mappedData); // Update state with the mapped data
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        fetchIncomeData();
        fetchIncomeCategoryTable();
    }, [incomeCategoryId]);

    const handleDeleteIncomeCategory = async (itemId) => {
        try {
            const response = await deleteFromAPI(`FtsExpenseCategory/${itemId}/`);
            if (response) {
                toast.success(messages.incomeCategory.deleted_successfully,'success');
                fetchIncomeData(); // Update the customer list
                if(incomCategoryeData.length>0){
                    setIncomeCategoryId(incomCategoryeData[0].id);
                }
                else
                {
                    setIncomeCategoryId('');
                }
            }
        } catch (error) {
            return null;
        }

    };

    // Handle Delete Expense Category Entry
    const handleDelete = async (Id) => {
        try {
            showAlert(messages.incomeCategory.delete_alert, (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDeleteIncomeCategory(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            return null;
        }
    };

    const handleEdit = () => {
        setShow(true);
    };

    const handleSaveChanges = async () => {
         if (!inputData.category_name || !inputData.category_name.trim()) {
                toast.error(messages.incomeCategory.required_category_name);
                return;
            }
        if (!inputData.description || !inputData.description.trim()) {
                toast.error(messages.incomeCategory.required_description);
                return;
            }
        
        const isExists = Array.isArray(incomCategoryeData) && incomCategoryeData.find(income => 
            income.category_name.trim() === inputData.category_name.trim() &&
            income.id !== incomeCategoryId // Exclude the current branch being edited
        );
        
        if (isExists) {
            toast.error(messages.incomeCategory.already_exist);
                return;
            }
            
        try {
            await putToAPI(`FtsExpenseCategory/${incomeCategoryId}/`, inputData);
            toast.success(messages.incomeCategory.updated_successfully);
            setShow(false);
            fetchIncomeData();
            fetchIncomeCategoryTable();
        } catch (error) {
            return null;
        }
    };
  

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                            <Card.Title className='mb-0'>View Income Category</Card.Title>
                            <div className='d-flex'>
                                <button
                                    className='btn btn-link'
                                    style={{ marginRight: '0', padding: '0' }}
                                    onClick={handleEdit}
                                    disabled={!incomeCategoryId}  // Disable if no data

                                >
                                    <div className='avatar avatar-sm bg-soft-info text-info'>
                                        <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                    </div>
                                </button>
                                <button
                                    className='btn btn-link'
                                    onClick={() => handleDelete(incomeCategoryId)} // Use a function reference
                                    disabled={!incomeCategoryId}  // Disable if no data
                                >
                                    <div className='avatar avatar-sm bg-soft-danger text-danger'>
                                        <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                    </div>
                                </button>
                            </div>
                        </div>
                   
                    <Table responsive className='table-lg border table-nowrap mb-0'>
                        <tbody>
                            {tableData.map((data, i) =>
                                <tr key={i}>
                                    <th>{data.tableheading1}</th>
                                    <td>{data.tablesubtext1}</td>
                    
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Modal centered show={show} size="xl" onHide={handleClose}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>Edit Income Category</h5>

                    <Form>
                        <Row>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Income Category Name</Form.Label>
                                    <Form.Control 
                                       type="text" 
                                       placeholder='Enter Expense Category Name...'
                                       name='category_name' id='category_name' 
                                       value={inputData.category_name} 
                                       onChange={e=>setInputData({...inputData,category_name: e.target.value})}
                                    />
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Income Description</Form.Label>
                                    <Form.Control 
                                      as="textarea" 
                                      placeholder='Type income description...' 
                                      rows={4} 
                                      name='description' 
                                      id='description' 
                                      value={inputData.description} 
                                      onChange={e=>setInputData({...inputData,description: e.target.value})}/>
                                </div>
                            </Col>
                        </Row>
                        <div className='text-end btn-list'>
                            <Button variant="primary" onClick={handleSaveChanges}>Update</Button>{' '}
                            <Button variant="secondary" onClick={handleClose}>Cancel</Button>{' '}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
