import { postToAPI, getFromAPI } from './utils'; // Adjust the import path as needed
import { toast } from 'react-toastify';


// Handle input specifically for Mobile Number and Email
export const handleMobileChange = (e, setInputData) => {
    const { name, value } = e.target;
    setInputData(prev => ({
        ...prev,
        [name]: value.replace(/\s+/g, '') // Remove all spaces
    }));
};

// Fetch bank data from the API
export const fetchBankData = async (setBank) => {
    try {
        const response = await getFromAPI('FtsBanks/');
        setBank(response); // Save bank data
    } catch (error) {
        console.error('Error fetching bank data:', error);
    }
};

export const fetchCountryData = async (setCountry) => {
        try {
            const response = await getFromAPI("FtsCountries/");
            setCountry(response); // Save country data
        } catch (error) {
            console.error('Error fetching country data:', error);
        }
};

export const fetchTaxData = async (setTax) => {
        try {
            const response = await getFromAPI('FtsTaxes/');
            setTax(response); // Save tax data
        } catch (error) {
            console.error('Error fetching tax data:', error);
        }
};

export const fetchCutsomerData = async (setCustomer) => {
    try {
        const response = await getFromAPI('FtsClients/');
        setCustomer(response); 

    } catch (error) {
        console.error('Error fetching Customer data:', error);
    }
};

export const fetchUnitData = async (setUnit) => {
    try {
        const response = await getFromAPI('FtsUnit/');
        setUnit(response); 

    } catch (error) {
        console.error('Error fetching Unit data:', error);
    }
};

export const fetchBranchData = async (setBranch) => {
    try {
        const response = await getFromAPI('FtsBranch/');
        setBranch(response); 

    } catch (error) {
        console.error('Error fetching tax data:', error);
    }
};

export const fetchProductCategoryData = async (setProductCategory) => {
    try {
        const response = await getFromAPI('FtsProductCategory/');
        setProductCategory(response); 

    } catch (error) {
        console.error('Error fetching Product Category data:', error);
    }
};

export const fetchBrandData = async (setBrand) => {
    try {
        const response = await getFromAPI('FtsBrand/');
        setBrand(response); 

    } catch (error) {
        console.error('Error fetching brand data:', error);
    }
};

export const fetchVendorData = async(setVendorData) => {
    try{
        const response = await getFromAPI('FtsVendor/');
        setVendorData(response);
    } catch (error)
    {
        return null;
    }

};
   
export const fetchServiceCategory = async(setServiceCategoryData) => {
    try{
        const response = await getFromAPI('FtsServiceCategory/');
        setServiceCategoryData(response); 
    }  catch(error)
    {
      return null;
    }
};

export const fetchProductData = async(setProductData) => {
    try{
        const response = await getFromAPI('FtsItems/');
        setProductData(response);
    }
    catch(error) {
        return null;
    }
}

export const fetchServiceProdutData = async(setServiceProductData) => {
    try{
        const response = await getFromAPI('FtsServiceProductDetails/');
        setServiceProductData(response);
    }
    catch(error) {
        return null;
    }
}