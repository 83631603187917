import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { postToAPI, getFromAPI, formatISODateWithTimezone } from '../../Utils/utils';
import { toast } from 'react-toastify';
import { useAccount } from './getAccountData'; // Import the useCustomer hook
// icon
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';

// update

export default function NewAccount({ onSuccess }) {
    // State to manage form input values
    const [formData, setFormData] = useState({
        name: '',
        account_number: '',
        tax: '',  // Update to an integer
        category_id: '',  // Update to an integer
        acc_gst_number: 'NA',
        state: '',  // Update to an integer
        description: '',
        opening_balance: 0,
        share_of_profit: 'NA',
        account_across_branch: '',  // Update to an integer
        holder_name: 'NA',
        budget: '0',
        account_type: 1,  // Update to an integer for Credit (0 for Debit)
        current_balance: 0,
        account_nature: '',  // Update to an integer
        isdefault: 0,
        isactive: 1,
        created_branch_id: 1,
        created_user_id: 1,
        last_modified_user_id: 1,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isdeleted: 0,
    });

    const [formcategorydata, setFormcategorydata] = useState({
        name: '',
        code: '',
        type: '',
        bs_anx: '',
        index_no: 1,
        account_master_id: 1,
        created_branch_id: 1,
        created_user_id: 1,
        last_modfied_user_id: 1,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isdeleted: 0,
        reserve_varchar1: 'test',
        reserve_varchar2: 'test',
        reserve_float1: 0.0,
        reserve_float2: 0.0,
        reserve_int1: 0,
        reserve_int2: 0,
        reserve_double1: 0.0,
        reserve_date1: 1,
    });

    const [categories, setCategories] = useState([]);  // State to store fetched categories
    const [states, setStates] = useState([]);
    const [tax, setTax] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const { getAccountData, fetchAccountData } = useAccount();
    
    // Fetch category data
    const FetchCategoryData = () => {
        getFromAPI('FtsAccountCategory/').then(response => {

            // Directly set categories if the response is an array of categories
            if (Array.isArray(response)) {
                setCategories(response);  // Set the category data in state
            } else {
                console.error('Unexpected response structure:', response);
            }
        }).catch(error => {
            console.error('Error fetching category data:', error);
        });
    };

    const FetchTaxData = () => {
        getFromAPI('FtsTaxes/').then(response => {
            if (Array.isArray(response)) {
                setTax(response);  // Set the category data in state
            } else {
                console.error('Unexpected response structure:', response);
            }
        }).catch(error => {
            console.error('Error fetching tax data:', error);
        });
    };

    const FetchStateData = () => {
        getFromAPI('FtsState/').then(response => {
            if (Array.isArray(response)) {
                setStates(response);  // Set the category data in state
            } else {
                console.error('Unexpected response structure:', response);
            }
        }).catch(error => {
            console.error('Error fetching state data:', error);
        });
    };

    useEffect(() => {
        FetchCategoryData();
        FetchTaxData();
        FetchStateData();
    }, []);

    // Handle input change for all form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleCategoryChange = (e) => {
        const { name, value } = e.target;
        setFormcategorydata({
            ...formcategorydata,
            [name]: value,
        });
    };

    // Open modal
    const handleOpenModal = () => setShowModal(true);

    // Close modal
    const handleCloseModal = () => setShowModal(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name.trim()) {
            toast.error("Please Enter Account Name", 'error')
            return;
        }

        if (!formData.account_number.trim()) {
            toast.error("Please Enter Account Number", 'error')
            return;
        } else if (!/^\d+$/.test(formData.account_number.trim())) {
            toast.error("Account Number must be a number", 'error')
            return;
        }

        if (!formData.tax.trim()) {
            toast.error("Please Select a Tax", 'error')
            return;
        }

        if (formData.category_id === 0) {
            toast.error("Please Select a Category", 'error')
            return;
        }

        if (formData.state === 0) {
            toast.error("Please Select a State", 'error')
            return;
        }

        if (!formData.description.trim()) {
            toast.error("Please Enter Description", 'error')
            return;
        }

        if (formData.account_across_branch === 0) {
            toast.error("Please select Account Across Branch", 'error')
            return;
        }

        if (formData.account_type !== 0 && formData.account_type !== 1) {
            toast.error("Please select Account Type (Credit/Debit)", 'error')
            return;
        }

        if (!formData.opening_balance) {
            toast.error("Please Enter Opening Balance",'error')
            return;
        }

        if(!formData.share_of_profit || !formData.share_of_profit.trim())
        {
            toast.error("Please Enter Share of Profit",'error')
            return;
        }

        if(formData.account_across_branch.length === 0) {
            toast.error("Please select Account Across Branch",'error')
            return;
        }

        if (!formData.holder_name.trim()) {
            toast.error("Please Enter Holder Name", 'error')
            return;
        }

        if (!formData.budget.trim()) {
            toast.error("Please Enter Budget", 'error')
            return;
        }

         const isExists = Array.isArray(getAccountData) && getAccountData.find(account =>
            account.account_number === formData.account_number
        );
        
        if(isExists)
           {
                toast.error("Account Number already exists", 'error')
                return;
            }

        try {
            // Prepare updated form data with proper integer conversions
            const updatedFormData = {
                ...formData,
                tax: parseInt(formData.tax, 10) || 0, // Default to 0 if NaN
                category_id: parseInt(formData.category_id, 10) || 0,
                state: parseInt(formData.state, 10) || 0,
                account_across_branch: parseInt(formData.account_across_branch, 10) || 0,
                account_type: parseInt(formData.account_type, 10) || 0,
                account_nature: parseInt(formData.account_nature, 10) || 0,
            };

            const response = await postToAPI('FtsAccount/', updatedFormData); // Adjust API endpoint
            // Check for a successful status
            if (response && response.status === true) { // Check if response exists and status is true
                toast.success('Account added successfully', 'success');
                if(onSuccess&&response.data.id)
                {
                    onSuccess(response.data.id);
                }
                // Clear form after successful submission
                setFormData({
                    name: '',
                    account_number: '',
                    tax: '',
                    category_id: '',
                    acc_gst_number: '',
                    state: '',
                    description: '',
                    opening_balance: '',
                    share_of_profit: '',
                    account_across_branch: '',
                    holder_name: '',
                    budget: '',
                    account_type: '',
                    current_balance: '',
                    account_nature: '',
                    isdefault: '',
                    isactive: '',
                    created_branch_id: '',
                    created_user_id: '',
                    last_modified_user_id: '',
                    created_date_time: '',
                    last_modified_date_time: '',
                    isdeleted: '',
                });
            } else {
                // If the response status is not true, alert the message returned from API
                toast.error(response.message || 'Error adding account. Please try again.');
            }
        } catch (error) {
            console.error('Error posting account data:', error);
            toast.error('Error adding account. Please try again.');
        }
    };

    const isFormValid = () => {
        return (
            formData.name.trim() !== '' &&
            formData.account_number.trim() !== '' &&
            /^\d+$/.test(formData.account_number.trim()) &&
            formData.tax.trim() !== '' &&
            formData.category_id !== 0 &&
            formData.state !== 0 &&
            formData.description.trim() !== '' &&
            formData.account_across_branch !== 0 &&
            formData.account_type !== '' &&
            formData.opening_balance !== '' &&
            formData.share_of_profit.trim() !== '' &&
            formData.holder_name.trim() !== '' &&
            formData.budget.trim() !== ''
        );
    };

    const handleCategorySubmit = async (e) => {
        e.preventDefault();

        // Simple form validation to ensure fields are not empty
        if (!formcategorydata.name || !formcategorydata.code || !formcategorydata.type) {
            toast.error('Please fill in all the required fields', 'error');
            return;
        }

        try {
            // Ensure to handle API call asynchronously
            const response = await postToAPI('FtsAccountCategory/', formcategorydata); // Adjust API endpoint

            // Check for a successful status
            if (response && response.status === true) {
                toast.success('Category added successfully', 'success');
                handleCloseModal();

                // Clear the form after successful submission
                setFormcategorydata({
                    name: '',
                    code: '',
                    type: '',
                    bs_anx: '',
                    index_no: 1,
                    account_master_id: 1,
                    created_branch_id: 1,
                    created_user_id: 1,
                    last_modfied_user_id: 1,
                    created_date_time: formatISODateWithTimezone(new Date()),
                    last_modified_date_time: formatISODateWithTimezone(new Date()),
                    isdeleted: 0,
                    reserve_varchar1: '',
                    reserve_varchar2: '',
                    reserve_float1: 0.0,
                    reserve_float2: 0.0,
                    reserve_int1: 0,
                    reserve_int2: 0,
                    reserve_double1: 0.0,
                    reserve_date1: 1,
                });

                // Call the function to refresh the category dropdown
                FetchCategoryData(); // This should fetch the latest categories from the API
            } else {
                // If the response status is not true, alert the message returned from API
                alert(response.message || 'Error adding category. Please try again.');
            }
        } catch (error) {
            console.error('Error posting category data:', error);
            toast.error('Error adding category. Please try again.', 'error');
        }
    };

    // Function to check if form is valid
    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <Card.Title>Add Account</Card.Title>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Account Name <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter account name..."
                                        name='name'
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Account Number <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter account number..."
                                        name='account_number'
                                        value={formData.account_number}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax <span className='text-danger'>*</span></Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='tax'
                                        value={formData.tax}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Tax</option>
                                        {tax.map(tax => (
                                            <option key={tax.id} value={tax.id}>
                                                {tax.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Category<span className='text-danger'>*</span></Form.Label>
                                        <Form.Select
                                            aria-label="Default select example"
                                            name='category_id'
                                            value={formData.category_id}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Category</option>
                                            {categories.map(category => (
                                                <option key={category.id} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </div>

                                    <Button variant="light custom-form-input-btn ms-2 mt-2" onClick={handleOpenModal}>
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>GST Number<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter GST Number..."
                                        name='acc_gst_number'
                                        value={formData.acc_gst_number}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>State<span className='text-danger'>*</span></Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='state'
                                        value={formData.state_id}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select State<span className='text-danger'>*</span></option>
                                        {states.map(state => (
                                            <option key={state.id} value={state.id}>
                                                {state.state_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Description<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Type description..."
                                        name='description'
                                        value={formData.description}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Opening Balance<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter opening balance..."
                                        name='opening_balance'
                                        value={formData.opening_balance}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>(%)Share of Profit<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter share of profit..."
                                        name='share_of_profit'
                                        value={formData.share_of_profit}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xxl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Account Across Branch <span className='text-danger'>*</span> </Form.Label>
                                    <Form.Select
                                        aria-label="Default select example"
                                        name='account_across_branch'
                                        value={formData.account_across_branch}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Holder Name <span className='text-danger'>*</span> </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter holder name..."
                                        name='holder_name'
                                        value={formData.holder_name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Budget <span className='text-danger'>*</span> </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter budget..."
                                        name='budget'
                                        value={formData.budget}
                                        onChange={handleChange}
                                    />
                                </div>
                            </Col>
                        </Row>

                        {/* Credit/Debit Radio Buttons */}
                        <div className="d-flex">
                            <div className="mb-3 me-4">
                                <Form.Check
                                    type="radio"
                                    name="account_type"
                                    id="credit"
                                    label="Credit"
                                    checked={formData.account_type === 1}
                                    onChange={() => setFormData({ ...formData, account_type: 1 })}
                                />
                            </div>
                            <div className="mb-3">
                                <Form.Check
                                    type="radio"
                                    name="account_type"
                                    id="debit"
                                    label="Debit"
                                    checked={formData.account_type === 0}
                                    onChange={() => setFormData({ ...formData, account_type: 0 })}
                                />
                            </div>
                        </div>
                    </Form>
                </Card.Body>
                <div className='custom-card-footer'>
                    <div className="btn-list text-end">
                        <Button variant="primary" type="submit" onClick={handleSubmit} disabled={!isFormValid()} >Add</Button>{' '}
                        <Button variant="secondary" type="button">Cancel</Button>{' '}
                    </div>
                </div>
            </Card>

            {/* Modal for adding account category */}
            <Modal centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>Edit VendorAdd Account Category</h5>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Account Category Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter category name..." name="name" value={formcategorydata.name} onChange={handleCategoryChange} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter code..." name="code" value={formcategorydata.code} onChange={handleCategoryChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-3">
                            <Form.Label>Account Type</Form.Label>
                            <Form.Select
                                aria-label="Default select example"
                                name='type'
                                value={formcategorydata.type}
                                onChange={handleCategoryChange}
                            >
                                <option value="">Select</option>
                                <option value="1">Trading Account</option>
                                <option value="2">Bank Account</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3 d-flex gap-4">
                            <Form.Check
                                type="radio"
                                label="Expense"
                                name="accountNature"
                                id="expense"
                                checked={formcategorydata.bs_anx === 0}
                                onChange={() => setFormcategorydata({ ...formcategorydata, bs_anx: 0 })}
                            />
                            <Form.Check
                                type="radio"
                                label="Income"
                                name="accountNature"
                                id="income"
                                checked={formcategorydata.bs_anx === 1}
                                onChange={() => setFormcategorydata({ ...formcategorydata, bs_anx: 1 })}
                            />
                        </Form.Group>
                    </Form>
                    <div className='text-end btn-list'>
                        <Button variant="primary" onClick={handleCategorySubmit}>Add</Button>
                        <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
