import React, { useState, useEffect, useContext } from 'react';
import { Card, Button, Table } from 'react-bootstrap';
import { UnitContext } from './unitContext';
import UnitData from "./unit-data.json";
import { ProductProvider, useProduct } from './getUnit';
import { toast } from "react-toastify";
import { fetchUnitData } from '../../../Utils/function_list';
import  messages from '../../../Utils/lang';

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { getFromAPI, deleteFromAPI, showAlert, putToAPI } from '../../../Utils/utils';
import FormModal from '../../../Utils/FormModal';

export default function ViewUnit({ setSelectedUnitId }) {
    const selectedUnitId = useContext(UnitContext);
    const { unitData, fetchUnitData1 } = useProduct();
    const [tableData, setTableData] = useState([]); // Initialize state with an empty array

    // input value
    const [data, setData] = useState({
        name: ""
    });

    useEffect(() => {
        const fetchUnitDetails = async () => {
            try {
                const response = await getFromAPI(`FtsUnit/?id=${selectedUnitId}`);
                if (response && response.length > 0) {
                    const fetchedData = response[0];
                    setData({
                        name: fetchedData.name
                    });
                }
            } catch (error) {
                console.error('Error fetching unit details:', error);
            }
        };

        if (selectedUnitId) {
            fetchUnitDetails();
        }
    }, [selectedUnitId]);

    // table data
    const [unitTableData] = useState(UnitData);

    const fetchUnitTable = async () => {
        try {
            const response = await getFromAPI(`FtsUnit/?id=${selectedUnitId}`);
            // Map API response to match the table data structure
            const mappedData = [
                { tableheading1: "Name :", tablesubtext1: response[0].name }
            ]

            setTableData(mappedData); // Update state with the mapped data
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        fetchUnitData1();
        fetchUnitTable();
    }, [selectedUnitId]);

    const handleDeleteCustomer = async (itemId) => {
        try {
            const response = await deleteFromAPI(`FtsUnit/${itemId}/`);
            if (response) {
                toast.success('Unit deleted successfully', 'success');
                fetchUnitData1();
                fetchUnitTable();

                // Update selectedUnitId to the first unit if available
                if (unitData.length > 1) {
                    setSelectedUnitId(unitData[0].id); // Set to the first unit
                } else {
                    setSelectedUnitId(''); // No units left
                }
            }
        } catch (error) {
            toast.error('Unit Not Deleted', 'error');
        }
    };

    // Handle Delete Customer Entry
    const handleDeleteLocker = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Customer?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDeleteCustomer(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            console.error('Error deleting Customer:', error);
            toast.error('Error deleting Customer', 'error');
        }
    };

    const handleSaveChanges = async () => {
            if(!data.name.trim()){
                toast.error('Please Enter Add Unit', 'error');
                return;
            }

            const isExists = Array.isArray(unitData) && unitData.find(unit => 
                unit.name.trim() === data.name.trim() &&
                unit.id !== selectedUnitId // Exclude the current branch being edited
            );
                
            if (isExists) {
                toast.error(messages.unit.already_exist);
                return;
            }
            
            try {
            await putToAPI(`FtsUnit/${selectedUnitId}/`, data);
            toast.success('Unit details updated successfully', 'success');
            handleFormModalClose();
            fetchUnitData1();
            fetchUnitData(); // Update the customer list
            fetchUnitTable();
        } catch (error) {
            console.error('Error updating customer details:', error);
            toast.error('Failed to update customer details', 'error');
        }
    };
    // Edit modal
    const [formModalShow, setShowFormModal] = useState(false);
    const handleUnitEdit = () => setShowFormModal(true);
    const handleFormModalClose = () => setShowFormModal(false);

    const handleInputChange = (index, field, value) => {
        setData({ ...data, [field]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowFormModal(false);
    };
    const rows = [
        {
            columns: [
                { size: { lg: 12 }, label: 'Unit Name', type: 'text', name: 'name', id: 'unitName', placeholder: 'Enter unit name...', value: data.name, onChange: e => setData({ ...data, name: e.target.value }), errormsg: '', },
            ]
        }
    ];
    const Buttons = [
        { variant: "primary", text: "Update", onClick: handleSaveChanges },
        { variant: "secondary", text: "Cancel", onClick: handleFormModalClose },
    ]

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>View Unit</Card.Title>
                        <div>
                            <Button variant="soft-info p-0 me-3" onClick={handleUnitEdit} disabled={!selectedUnitId} >
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                </div>
                            </Button>
                            <Button variant="soft-danger p-0" onClick={() => handleDeleteLocker(selectedUnitId)} disabled={!selectedUnitId}>
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                </div>
                            </Button>
                        </div>
                    </div>

                    <Table responsive className='table-lg border table-nowrap mb-0'>
                        <tbody>
                            {tableData.map((data, i) =>
                                <tr key={i}>
                                    <th>{data.tableheading1}</th>
                                    <td>{data.tablesubtext1}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            {/* From Modal */}
            <FormModal title="Edit Unit" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
        </>
    )
}
