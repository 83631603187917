const messages = {
    vendor: {
        company_name_required: "Please enter company name",
        contact_person_name_required: "Please enter contact person name",
        email_required: "Please enter email address",
        phone_number_required: "Please enter mobile number",
        updated_successfully : "Vendor updated successfully",
        deleted_successfully : "Vendor deleted successfully",
        error_occurred : "An error occurred",
        vendor_not_found : "Vendor not found",
        vendor_added_successfully : "Vendor added successfully",
        alternate_mobile_number : "Please enter valid alternate number",
        website_url : "Please enter valid website url",
        country_required : "Please select country",
        state_required : "Please select state",
        city_required : "Please select city",
        street_required : "Please enter street",
        already_exist : "Mobile number already exist",
        failed_to_add : "Vendor not added",
        already_email_exist : "Vendor with this email already exists",
    },

    customer : {
        required_name : "Please enter customer name",
        required_email : "Please enter email address",
        required_phone_number : "Please enter valid Mobile Number",
        updated_successfully : "Customer updated Successfully",
        deleted_successfully : "Customer deleted Successfully",
        failed_to_add : "Customer not added",
        failed_to_update : "Customer not updated",
        customer_not_found : "Customer not found",
        already_exist : "Customer with same  mobile number already exist",
        failed_to_delete : "Customer not deleted", 
        delete_alert : "Are you sure you want to delete this customer?",
        add_customer : "Customer added successfully",
    },

    branch : {
        required_name : "Please enter branch name",
        required_business_name : "Please enter business name",
        required_address : "Please enter address",
        required_location : "Please enter location",
        required_mobile : "Please enter valid phone number",
        required_country : "Please select country",
        required_state : "Please select state",
        required_city : "Please select city",
        required_street : "Please enter street",
        updated_successfully : "Branch updated successfully",
        deleted_successfully : "Branch deleted successfully",
        failed_to_add : "Branch not added",
        failed_to_update : "Branch not updated",
        branch_not_found : "Branch not found",
        already_exist : "Branch with this phone number already exists",
        failed_to_delete : "Branch not deleted", 
        branch_add : "Branch added successfully", 
        already_exist_name : "Branch name already exists",
    },

    product : {
        required_name : "Please enter product name",
        required_description : "Please enter description",
        required_type : "Please select produt type",
        required_maximum_retail_price : "Please enter mrp",
        updated_successfully : "Product updated successfully",
        deleted_successfully : "Product deleted successfully",
        failed_to_add : "Product not added",
        failed_to_update : "Product not updated",
        product_not_found : "Product not found",
        already_exist : "Product name already exist",
        failed_to_delete : "Product not deleted", 
        required_product_purchase_price : "Please enter cost",
        required_product_mu_percentage : "Please enter mu",
        required_branches : "Please select branch",
        required_unit : "Please select unit",
        added_successfully : "Product added successfully",
        delete_alert : "Are you sure you want to delete this product?",

    },

    account : {
        required_account_name : "Please enter account name",
        required_account_number : "Please enter account number",
        updated_successfully : "Account updated successfully",
        deleted_successfully : "Account deleted successfully",
        failed_to_add : "Account not added",
        failed_to_update : "Account not updated",
        required_tax: "Please select a tax",
        required_category: "Please select a category",
        required_state: "Please select a state",    
        required_description:"Please enter a description",
        required_account_across_branch : "Please select account across branch",
        required_account_type : "Please select account type(credit/debit)",
        required_opening_balance : "Please enter a opening balance",
        required_share_of_profit: "Please enter a share of profit",
        required_holder_name : "Please enter a holder name",
        required_budget : "Please enter a required budget",
        added_account : "Account added successfully",
        already_exist : "Account with enter account number already exists",
    },

    accountCategory : {
        required_name : "Please enter account category name",
        updated_successfully : "Account category updated successfully",
        deleted_successfully : "Account category deleted successfully",
        failed_to_add : "Account category not added",
        failed_to_update : "Account category not updated",
        category_not_found : "Account category not found",
        already_exist : "Account category name already exist",
        failed_to_delete : "Account category not deleted",
        required_account_type : "Please select account type",
        added_successfully : "Account category added successfully",

    },

    branchAdmin : {
        required_name : "Please enter name",
        reuired_created_branch_id : "Please enter the branch name",
        required_email : "Please enter valid email",
        updated_successfully : "Branch admin updated successfully",
        deleted_successfully : "Branch admin deleted successfully",
        failed_to_add : "Branch admin not added",
        failed_to_update : "Branch admin not updated",
        required_password : "Please enter valid 8 character password",
        already_exist : "Email address already exist",
        failed_to_delete : "Branch admin not deleted",
        required_role : "Please select a role",
        added_successfully : "Branch admin added successfully",
        required_branch : "Please select a branch",
        phone_number_required : "Please enter phone number",
        name_required : "Please enter name",
        required_gender : "Please select gender",
        required_dob : "Please enter date of birth",
        required_joining_date : "Please enter required joining date",
        required_phone_number : "Please enter Valid Mobile Number",
        required_alternate_number : "Please enter a valid alternate mobile number",
        required_city : "Please select city",
    },

    expenseCategory : {
        required_category_name : "Please enter expense category name",
        required_description : "Please enter description",
        updated_successfully : "Expense category updated successfully",
        deleted_successfully : "Expense category deleted successfully",
        failed_to_add : "Expense category not added",
        failed_to_update : "Expense category not updated",
        added_successfully : "Expense category added successfully",
        delete_alert : "Are you sure you want to delete this income category?",

    },

    incomeCategory : {
        required_category_name : "Please enter income category name",
        required_description : "Please enter description",
        updated_successfully : "Income category updated successfully",
        deleted_successfully : "Income category deleted successfully",
        failed_to_add : "Income category not added",
        failed_to_update : "Income category not updated",
        added_successfully : "Income category added successfully",
        already_exist : "Income category already exists",
        delete_alert : "Are you sure you want to delete this income category?",
    },

    expenseCategory : {
        required_category_name : "Please enter expense category name",
        required_description : "Please enter description",
        updated_successfully : "Expense category updated successfully",
        deleted_successfully : "Expense category deleted successfully",
        failed_to_add : "Expense category not added",
        failed_to_update : "Expense category not updated",
        added_successfully : "Expense category added successfully",
        already_exist : "Expense category already exists",
        delete_alert : "Are you sure you want to delete this expense category?",
    },

    product_category : {
        required_category_name : "Please enter product category Name",
        updated_successfully : "Product category updated successfully",
        deleted_successfully : "Product category deleted successfully",
        failed_to_add : "Product category not added",
        failed_to_update : "Product category not updated",
        added_successfully : "Product category added successfully",
        already_exist : "Product category name already exist",
        failed_to_delete : "Product category not deleted",
        required_description : "Please enter description",
    },

    unit : {
        required_unit_name : "Please enter unit name",
        updated_successfully : "Unit updated successfully",
        deleted_successfully : "Unit deleted successfully",
        failed_to_add : "Unit not added",
        failed_to_update : "Unit not updated",
        added_successfully : "Unit added successfully",
        already_exist : "Unit name already exist",
        failed_to_delete : "Unit not deleted",
    },

    gst : {
        required_name : "Please enter tax name",
        required_tax_rate : "Please enter tax rate",
        updated_successfully : "GST updated successfully",
        deleted_successfully : "GST deleted successfully",
        failed_to_add : "GST not added",
        failed_to_update : "GST not updated",
        added_successfully : "GST added successfully",
        already_exist : "Tax name already exist",
        failed_to_delete : "GST not deleted",
    },

    payment_mode : {
        required_name : "Please enter payment mode name",
        updated_successfully : "Payment mode updated successfully",
        deleted_successfully : "Payment mode deleted successfully",
        failed_to_add : "Payment mode not added",
        failed_to_update : "Payment mode not updated",
        added_successfully : "Payment mode added successfully",
        already_exist : "Payment mode name already exist",
        failed_to_delete : "Payment mode not deleted",
        select_account : "Please select default account",
        
    },

    service : {
        required_name : "Please enter name",
        required_price : "Please enter price",
        updated_successfully : "Service updated successfully",
        deleted_successfully : "Service deleted successfully",
        failed_to_add : "Service not added",
        failed_to_update : "Service not updated",
        added_successfully : "Service added successfully",
        already_exist : "Service name already exist",
        failed_to_delete : "Service not deleted",
        required_service_category : "Please select service category",
        required_service_sac : "Please enter sac",
        required_description : "Please enter description",
        required_duration : "Please enter duration",
        required_earning : "Please enter earning",
        required_branch : "Please select branch",
        unexpected_error : "An unexpected error occurred",
        price_limit : "Price length should not exceed 10 digits",
        duration_length : "Duration length should not exceed 10 digits",
        earning_length : "Earning length should not exceed 10 digits",
    },

    serviceCategory : {
        required_service_category : "Service category name required",
        required_description : "Description is required",
        updated_successfully : "Service category updated successfully",
        deleted_successfully : "Service category deleted successfully",
        failed_to_add : "Service category not added",
        failed_to_update : "Service category not updated",
        added_successfully : "Service category added successfully",
    },

    serviceProductDetails : {
        required_weight : "Please enter used weight",
        reuired_product : "Please select product",
        added_successfully : "Service product details added successfully",
        already_exist : "Service product details already exist",
        failed_to_delete : "Service product details not deleted",
    },
  
    serviceCategory : {
        required_service_category : "Please enter service category name",
        required_description : "Please enter service description",
        updated_successfully : "Service category updated successfully",
        deleted_successfully : "Service category deleted successfully",
        failed_to_add : "Service category not added",
        failed_to_update : "Service category not updated",
        added_successfully : "Service category added successfully",
        already_exist : "Service category name already exist",
    }
};

export default messages;
