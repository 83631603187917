import React, { useState, useEffect } from 'react';
import Footer from '../../layout/Footer';
import ViewBranch from './ViewBranch';
import NewBranch from './NewBranch';
import { Container, Row, Col, Card, Form, Nav } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import { BranchContext1 } from './branchContext';
import { BranchProvider, useBranch } from './getBranchData';

import BranchData from "./branch-data.json";
import {getFromAPI,postToAPI} from "../../../Utils/utils";
import {toast} from 'react-toastify'; 

// Icon
import Icon from '@mdi/react';
import { mdiFormatListBulletedSquare, mdiPlus, mdiMagnify } from '@mdi/js';


function Branch() {
    const { getBranchData, fetchBranchData } = useBranch();
    const [selectedBranchId,setSelectedBranchId] = useState('');
    const [searchTerm,setSearchTerm] = useState('');
    const [showBranch, setShowBranch] = useState(false);
    
    useEffect(() => {
        fetchBranchData();
    },[selectedBranchId]);

    const filteredSearchCategoryData = getBranchData.filter(admin =>
        admin.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    
      useEffect(() => {
        const sidebarItems = document.querySelectorAll(".left-link-item");
        
        const handleItemClick = function () {
            // Remove active class from all sidebar links
            sidebarItems.forEach((item) => {
                const link = item.querySelector("a");
                if (link) link.classList.remove("active");
            });

            // Add active class to the clicked item
            const link = this.querySelector("a");
            if (link) link.classList.add("active");

            // Update selected customer ID
            const customerId = this.getAttribute("data-id");
            setSelectedBranchId(customerId);
            setShowBranch(false);

            // Switch tabs programmatically
            const viewCustomerTab = document.getElementById("pills-view-branch-tab");
            if (viewCustomerTab) viewCustomerTab.click();
        };

        // Attach event listeners
        sidebarItems.forEach((item) => {
            item.addEventListener("click", handleItemClick);
        });

        // Cleanup on unmount
        return () => {
            sidebarItems.forEach((item) => {
                item.removeEventListener("click", handleItemClick);
            });
        };
    }, [filteredSearchCategoryData]);
    
    useEffect(()=>{
        if(getBranchData.length>0&& (!selectedBranchId || !getBranchData.some(c => c.id === selectedBranchId)))  {
            setSelectedBranchId(getBranchData[0].id);
        }
    },[getBranchData]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    
    const [key, setKey] = React.useState(0);

    const handleNewCustomerSuccess = (newBranchId) => {
        fetchBranchData();
        setSelectedBranchId(newBranchId);
        setShowBranch(false);
        
        document.getElementById("pills-view-branch-tab")?.click();       
    };

    const handleNewBranchClick = () => {
        setShowBranch(true);
        setKey(prevKey => prevKey + 1);
    };
    
    return (
        <>
        <BranchContext1.Provider value={selectedBranchId}>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='d-md-flex justify-content-between align-items-center'>
                                            <Form className='custom-form-width'>
                                                <div className='custom-search-bar'>
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control className='' type="text" placeholder="Search branch..." name='branchSearch' id='branchSearch' onChange={handleSearchChange} value={searchTerm} />
                                                </div>
                                            </Form>
                                            <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className={`nav-link ${!showBranch ? 'active' : ''}`} id="pills-view-branch-tab" data-bs-toggle="pill" data-bs-target="#pills-view-branch" type="button" role="tab" aria-controls="pills-view-branch" aria-selected="true" onClick={() => setShowBranch(false)}>
                                                            <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />View Branch
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className={`nav-link ${showBranch ? 'active' : ''}`} id="pills-new-branch-tab" data-bs-toggle="pill" data-bs-target="#pills-new-branch" type="button" role="tab" aria-controls="pills-new-branch" aria-selected="false" onClick={handleNewBranchClick}>
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />New Branch
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4} xxl={3}>
                                <Card className='procust-left-list leftbar-list'>
                                    <SimpleBarReact>
                                        <Card.Body>
                                            <div className='left-list-bar'>
                                                {filteredSearchCategoryData.map((data, i) =>
                                                    <Nav.Item className='left-link-item' key={i} data-id={data.id}  onClick={() => setSelectedBranchId(data.id)}>
                                                        <Nav.Link className={data.id === selectedBranchId ? "active" : ""}>
                                                            <h5 className='product-tab-title'>{data.name}</h5>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>
                            <Col lg={8} xxl={9}>
                                <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-view-branch" role="tabpanel" aria-labelledby="pills-view-branch-tab" tabIndex="0">
                                        <ViewBranch setSelectedBranchId = {setSelectedBranchId}/>
                                    </div>
                                    <div className="tab-pane fade" id="pills-new-branch" role="tabpanel" aria-labelledby="pills-new-branch-tab" tabIndex="0">
                                        <NewBranch key={key} onSuccess={handleNewCustomerSuccess}/>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
            </BranchContext1.Provider>
        </>
    )
}


export default function CustomersWithProvider() {
    return (
        <BranchProvider>
            <Branch />
        </BranchProvider>
    );
}