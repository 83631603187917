import React, { useState, useEffect, useContext } from 'react';
import { Card, Button, Table } from 'react-bootstrap';
import { getFromAPI, deleteFromAPI, showAlert, putToAPI, formatISODateWithTimezone } from '../../../Utils/utils';
import { toast } from 'react-toastify'
import FormModal from '../../../Utils/FormModal';
// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { BranchContext1 } from './branchContext';
import { useBranch } from './getBranchData'; // Import the useCustomer hook
import messages from "../../../Utils/lang";

export default function ViewBranch({ setSelectedBranchId }) {
    const [country, setCountry] = useState([]);
    const [State, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [Tax, setTax] = useState([]);
    const [Bank, setBank] = useState([]);
    const selectedBranchId = useContext(BranchContext1);
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');
    // table data
    const initialInputData = {
        name: '',
        business_name: '',
        location: '',
        country: '',
        state: '',
        city: '',
        phone_number_1: '',
        pan_card_number: 'NA',
        gst_number: 'NA',
        tax_id: 0,
        tax_type: 1,
        branch_type_id: 1,
        bank_name: 0,
        bank_account_no: '',
        bank_ifsc_code: '',
        owner_user_id: 1,
        created_user_id: 1,
        last_modified_user_id: 1,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isonline: 0,
        isactive: 1,
        isdeleted: 0,
        reserve_varchar1: 'NA',
        reserve_varchar2: 'NA',
        reserve_float1: 0.0,
        reserve_float2: 0.0,
        reserve_int1: 0,
        reserve_int2: 0,
        reserve_double1: 0.0,
        reserve_date1: new Date().toISOString().split('T')[0]
    };

    const { getBranchData, fetchBranchData } = useBranch();
    const [branchInputData, setBranchInputData] = useState({ ...initialInputData });
    const [tableData, setTableData] = useState([]); // Initialize state with an empty array

    useEffect(() => {
        const fetchBranchDetails = async () => {
            try {
                const response = await getFromAPI(`FtsBranch/?id=${selectedBranchId}`);
                if (response && response.length > 0) {
                    const BranchResponse = response[0];

                    setBranchInputData({
                        name: BranchResponse.name,
                        business_name: BranchResponse.business_name,
                        phone_number_1: BranchResponse.phone_number_1,
                        location: BranchResponse.location,
                        pan_card_number: BranchResponse.pan_card_number,
                        gst_number: BranchResponse.gst_number,
                        tax_id: BranchResponse.tax_id,
                        tax_type: BranchResponse.tax_type,
                        branch_type_id: BranchResponse.branch_type_id,
                        country: BranchResponse.country,
                        state: BranchResponse.state,
                        city: BranchResponse.city,
                        bank_name: BranchResponse.bank_name,
                        owner_user_id: BranchResponse.owner_user_id,
                        created_user_id: BranchResponse.created_user_id,
                        bank_ifsc_code: BranchResponse.bank_ifsc_code,
                        bank_account_no: BranchResponse.bank_account_no,
                        last_modified_user_id: BranchResponse.last_modified_user,
                        created_date_time: new Date().toISOString(),
                        last_modified_date_time: new Date().toISOString(),

                    });

                    // Fetch state based on country
                    if (BranchResponse.country) {
                        const stateResponse = await getFromAPI(`FtsState/?country_code=${BranchResponse.country}`);
                        setState(stateResponse); // Set state dropdown
                    }

                    // Fetch city based on state
                    if (BranchResponse.state) {
                        const cityResponse = await getFromAPI(`FtsCity/?state_id=${BranchResponse.state}`);
                        setCity(cityResponse); // Set city dropdown
                    }
                }
            } catch (error) {
                return null;
            }
        };

        if (selectedBranchId) {
            fetchBranchDetails();
        }
    }, [selectedBranchId]);

    const fetchBranchTableData = async () => {
        try {
            const response = await getFromAPI(`FtsBranch/?id=${selectedBranchId}`);
            let country = response[0].country;
            const countryName = await getCountryName(country);
            const stateCode = response[0].state; // Assuming state contains the state_code
            const stateName = await getStateNameByCode(stateCode);
            const cityID = response[0].city; // Assuming city contains the id
            const cityName = await getCityByName(cityID);
            const Tax = response[0].tax_id
            const taxName = await gettaxById(Tax)
            const bank = parseInt(response[0].bank_name)
            const bankName = await getBankName(bank)

            let responseType = '';
            if (response[0].branch_type_id === 1) {
                responseType = 'Self';
            }
            else if (response[0].branch_type_id === 2) {
                responseType = 'Franchies';
            }
            else {
                responseType = 'Self'
            }
            const mappedData = [
                { accountheading1: "Name :", accountsubtext1: response[0].name, accountheading2: "Business Name :", accountsubtext2: response[0].business_name },
                { accountheading1: "Created Date Time :", accountsubtext1: response[0].created_date_time, accountheading2: "Country:", accountsubtext2: countryName },
                { accountheading1: "State :", accountsubtext1: stateName, accountheading2: "City :", accountsubtext2: cityName },
                { accountheading1: "GSTIN :", accountsubtext1: response[0].gst_number, accountheading2: "PAN :", accountsubtext2: response[0].pan_card_number },
                { accountheading1: "Phone Number :", accountsubtext1: response[0].phone_number_1, accountheading2: "Country:", accountsubtext2: response[0].location },
                { accountheading1: "Tax :	", accountsubtext1: taxName, accountheading2: "Branch Type:", accountsubtext2: responseType },
                { accountheading1: "Bank Name :", accountsubtext1: bankName, accountheading2: "Account No :", accountsubtext2: response[0].bank_account_no },
                { accountheading1: "IFSC Code :", accountsubtext1: response[0].bank_ifsc_code },
            ];
            setTableData(mappedData)
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        fetchBranchTableData();
    }, [selectedBranchId]);

    const handleDelete = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Branch?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDeleteBranch(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            toast.error('Error deleting Branch', 'error');
            return null;
        }
    };


    const handleDeleteBranch = async (itemId) => {
        try {
            const response = await deleteFromAPI(`FtsBranch/${itemId}/`);
            if (response) {
                toast.success('Branch deleted successfully', 'success');
                if (getBranchData.length > 0) {
                    setSelectedBranchId(getBranchData[0].id)
                }
                else {
                    setSelectedBranchId('')
                }
            }
        } catch (error) {
            toast.error('Branch Not Deleted', 'error');
        }

        fetchBranchData(); // Update the customer list
        fetchBranchTableData(); // Refetch table data if needed
    };

    const handleSaveChanges = async () => {
    
        if (!branchInputData.name.trim()) {
            toast.error(messages.branch.required_name);
            return;
          }
          if (!branchInputData.business_name.trim()) {
            toast.error(messages.branch.required_business_name);
            return;
          }
          if (!branchInputData.location.trim()) {
            toast.error(messages.branch.required_location);
            return;
          }
          if (!branchInputData.phone_number_1 || branchInputData.phone_number_1.length !== 10) {
            toast.error(messages.branch.required_mobile);
            return;
          }
          if (!branchInputData.country) {
            toast.error(messages.branch.required_country);
            return;
          }
          if (!branchInputData.state) {
            toast.error(messages.branch.required_state);
            return;
          }
          if (!branchInputData.city) {
            toast.error(messages.branch.required_city);
            return;
          }

        const isExists = Array.isArray(getBranchData) && getBranchData.find(branch => 
            branch.phone_number_1.trim() === branchInputData.phone_number_1.trim() &&
            branch.id !== selectedBranchId // Exclude the current branch being edited
        );

        if (isExists) {
            toast.error(messages.branch.already_exist);
            return;
        }

        const isBranchNameExists = Array.isArray(getBranchData) && getBranchData.find(branch =>
            branch.name === branchInputData.name
            && branch.id!== selectedBranchId
         );
        
        if(isBranchNameExists)
        {
            toast.error(messages.branch.already_exist_name);
            return;
        }

        try {
            // Update the branch data with the new values
            await putToAPI(`FtsBranch/${selectedBranchId}/`, branchInputData);
            // Show a success toast
            toast.success('Branch details updated successfully', 'success');
            // Close the edit modal
            handleFormModalClose();
            // Refetch the branch data to update the table
            fetchBranchData();
            // Refetch the branch table data to update the view
            fetchBranchTableData();
        } catch (error) {
            // Log any errors
            console.error('Error updating Branch details:', error);
            // Show an error toast
            toast.error('Failed to update Branch details', 'error');
        }
    };


    const getStateNameByCode = async (code) => {
        try {
            const stateResponse = await getFromAPI(`FtsState/?state_code=${code}`);
            // 
            //Assuming stateResponse is an array of states
            if (stateResponse) {
                return stateResponse[0].state_name; // Return the state name
            } else {
                return null; // Return null if not found
            }
        } catch (error) {
            console.error("Error fetching states:", error);
            return null; // Return null in case of an error
        }
    };

    const getCityByName = async (code) => {
        try {
            const CityResponse = await getFromAPI(`FtsCity/?id=${code}`);

            if (CityResponse) {
                return CityResponse[0].city_name; // Return the state name
            } else {
                return null; // Return null if not found
            }
        } catch (error) {
            console.error("Error fetching states:", error);
            return null; // Return null in case of an error
        }
    };

    const getCountryName = async (code) => {
        if (!code) {
            return null; // Return null if not found
        }
        try {
            const countryResponse = await getFromAPI(`FtsCountries/?calling_code=${code}`);
            if (countryResponse) {
                return countryResponse[0].short_name; // Return the state name
            } else {
                return null; // Return null if not found
            }
        } catch (error) {
            console.error("Error fetching states:", error);
            return null; // Return null in case of an error
        }
    };

    const gettaxById = async (id) => {
        if (!id) {
            return null; // Return null if not found
        }
        try {
            const taxResponse = await getFromAPI(`FtsTaxes/?id=${id}`);
            if (taxResponse) {
                return taxResponse[0].name; // Return the state name
            }
            else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching states:", error);
            return null; // Return null in case of an error
        }
    };

    const getBankName = async (code) => {
        if (!code) return null;
        try {
            const bankResponse = await getFromAPI(`FtsBanks/?bank_id=${code}`);
            if (bankResponse) {
                return bankResponse[0].bank_name; // Return the state name
            } else {
                return null; // Return null if not found
            }
        } catch (error) {
            console.error("Error fetching Bank:", error);
            return null; // Return null in case of an error
        }
    };
    // Handle input specifically for Mobile Number and Email
    const handleMobileChange = (e) => {
        const { name, value } = e.target;
        // Remove spaces from the input value
        setBranchInputData((prev) => ({
            ...prev,
            [name]: value.replace(/\s+/g, ''), // Remove all spaces
        }));
    };

    useEffect(() => {
        const fetchCountryData = async () => {
            try {
                const response = await getFromAPI("FtsCountries/");
                setCountry(response); // Save country data
            } catch (error) {
                console.error('Error fetching country data:', error);
            }
        };

        fetchCountryData();
    }, []);

    useEffect(() => {
        const fetchBankData = async () => {
            try {
                const response = await getFromAPI('FtsBanks/');
                setBank(response); // Save tax data
            } catch (error) {
                console.error('Error fetching tax data:', error);
            }
        };

        fetchBankData();
    }, []);


    useEffect(() => {
        const fetchTaxData = async () => {
            try {
                const response = await getFromAPI('FtsTaxes/');
                setTax(response); // Save tax data
            } catch (error) {
                console.error('Error fetching tax data:', error);
            }
        };

        fetchTaxData();
    }, []);


    const handleCountryChange = async (e) => {
        const selectedCountry = e.target.value;
        setBranchInputData(prevState => ({ ...prevState, country: selectedCountry }));

        try {
            const response = await getFromAPI(`FtsState/?country_code=${selectedCountry}`);
            setState(response); // Save state data
            setCity([]); // Reset city data
        } catch (error) {
            console.error('Error fetching state data:', error);
        }
    };

    const handleStateChange = async (e) => {
        const selectedState = e.target.value;
        setBranchInputData(prevState => ({ ...prevState, state: selectedState }));

        try {
            const response = await getFromAPI(`FtsCity/?state_id=${selectedState}`);
            setCity(response);
        } catch (error) {
            console.error('Error fetching city data:', error);
        }
    };

    // Edit Branch modal
    const [formModalShow, setShowFormModal] = useState(false);
    const handleBranchModalEdit = () => setShowFormModal(true);
    const handleFormModalClose = () => setShowFormModal(false);

    const handleInputChange = (index, field, value) => {
        setBranchInputData({ ...branchInputData, [field]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowFormModal(false);
    };

    const rows = [
        {
          columns: [
            { size: { lg: 6 }, label: 'Branch Name *', type: 'text', name: 'name', id: 'name', placeholder: 'Enter branch name...', value: branchInputData.name, onChange: e => setBranchInputData({ ...branchInputData, name: e.target.value }), errormsg: '', },
            { size: { lg: 6 }, label: 'Business Name *', type: 'text', name: 'business_name', id: 'business_name', placeholder: 'Enter business name...', value: branchInputData.business_name, onChange: e => setBranchInputData({ ...branchInputData, business_name: e.target.value }), errormsg: '', },
            { size: { lg: 4 }, label: 'Phone Number *', type: 'number', name: 'phone_number_1', id: 'phone_number_1', placeholder: 'Enter phone number...', value: branchInputData.phone_number_1, onChange: handleMobileChange, errormsg: '', },
            { size: { lg: 8 }, label: 'Location *', type: 'text', name: 'location', id: 'location', placeholder: 'Enter location...', value: branchInputData.location, onChange: e => setBranchInputData({ ...branchInputData, location: e.target.value }), errormsg: '', },
            { size: { md: 6, xl: 4 }, label: 'Country *', type: 'select', name: 'country', id: 'country', value: branchInputData.country, onChange: handleCountryChange, errormsg: '',
              options: [{ value: '', label: 'Select Country' }, ...country.map((c) => ({ value: c.calling_code, label: c.short_name }))]
            },
            { size: { md: 6, xl: 4 }, label: 'State *', type: 'select', name: 'state', id: 'state', value: branchInputData.state, onChange: handleStateChange, errormsg: '',
              options: [{ value: '', label: 'Select State' }, ...State.map((s) => ({ value: s.state_code, label: s.state_name }))]
            },
            { size: { md: 6, xl: 4 }, label: 'City *', type: 'select', name: 'city', id: 'city', value: branchInputData.city, onChange: e => setBranchInputData({ ...branchInputData, city: e.target.value }), errormsg: '',
              options: [{ value: '', label: 'Select City' }, ...city.map((c) => ({ value: c.id, label: c.city_name }))]
            },
            { size: { md: 6, xl: 4 }, label: 'GST No *', type: 'text', name: 'gst_number', id: 'gst_number', placeholder: 'Enter value...', value: branchInputData.gst_number, onChange: e => setBranchInputData({ ...branchInputData, gst_number: e.target.value }), errormsg: '', },
            { size: { lg: 6, xl: 4 }, label: 'PAN No *', type: 'text', name: 'pan_card_number', id: 'pan_card_number', placeholder: 'Enter value...', value: branchInputData.pan_card_number, onChange: e => setBranchInputData({ ...branchInputData, pan_card_number: e.target.value }), errormsg: '', },
            { size: { md: 6, xl: 4 }, label: 'Tax *', type: 'select', name: 'tax_id', id: 'tax_id', value: branchInputData.tax_id, onChange: e => setBranchInputData({ ...branchInputData, tax_id: e.target.value }), errormsg: '',
              options: [{ value: '', label: 'Select Tax' }, ...Tax.map(item => ({ value: item.id, label: item.name }))]
            },
            { size: { md: 6, xl: 4 }, label: 'Tax Type *', type: 'select', name: 'tax_type', id: 'tax_type', value: branchInputData.tax_type, onChange: e => setBranchInputData({ ...branchInputData, tax_type: e.target.value }), errormsg: '',
              options: [{ value: '1', label: 'Inclusive' }, { value: '2', label: 'Exclusive' }]
            },
            { size: { xl: 4 }, label: 'Branch Type *', type: 'select', name: 'branch_type_id', id: 'branchType', value: branchInputData.branch_type_id, onChange: e => setBranchInputData({ ...branchInputData, branch_type_id: e.target.value }), errormsg: '',
              options: [{ value: '1', label: 'Self' }, { value: '2', label: 'Franchies' }]
            },
            { size: { xl: 4 }, label: 'Bank Name *', type: 'select', name: 'bank_name', id: 'bank_name', value: branchInputData.bank_name, onChange: e => setBranchInputData({ ...branchInputData, bank_name: e.target.value }), errormsg: '',
              options: [{ value: '', label: 'Select Bank Name' }, ...Bank.map(item => ({ value: item.bank_id, label: item.bank_name }))]
            },
            { size: { lg: 6 }, label: 'Bank Account No *', type: 'number', name: 'bank_account_no', id: 'bank_account_no', placeholder: 'Enter value...', value: branchInputData.bank_account_no, onChange: e => setBranchInputData({ ...branchInputData, bank_account_no: e.target.value }), errormsg: '', },
            { size: { lg: 6 }, label: 'Bank IFSC Code *', type: 'text', name: 'bank_ifsc_code', id: 'bank_ifsc_code', placeholder: 'Enter value...', value: branchInputData.bank_ifsc_code, onChange: e => setBranchInputData({ ...branchInputData, bank_ifsc_code: e.target.value }), errormsg: '', },
          ]
        }
    ];
      
    const Buttons = [
        { variant: "primary", text: "Update", onClick: handleSaveChanges },
        { variant: "secondary", text: "Cancel", onClick: handleFormModalClose },
    ]

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>Details</Card.Title>
                        <div>
                            <Button variant="soft-info p-0 me-3" onClick={handleBranchModalEdit} disabled={!selectedBranchId} >
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                </div>
                            </Button>
                            <Button variant="soft-danger p-0" onClick={() => handleDelete(selectedBranchId)} disabled={!selectedBranchId}>
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                </div>
                            </Button>
                        </div>
                    </div>
                    <Table responsive className='table-lg border table-nowrap mb-0'>
                        <tbody>
                            {tableData.map((data, i) =>
                                <tr key={i}>
                                    <th>{data.accountheading1}</th>
                                    <td>{data.accountsubtext1}</td>
                                    <th>{data.accountheading2}</th>
                                    <td>{data.accountsubtext2}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            {/* From Modal */}
            <FormModal title="Edit Branch" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
        </>
    )
}
