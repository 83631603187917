import React, { useState, useEffect } from 'react';
import Footer from '../../layout/Footer';
import ViewExpenseCategory from './ViewExpenseCategory';
import { Container, Row, Col, Card, Form, Nav, Button } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";

import ExpenseCategoryData from "./expense-category-data.json";
import { toast } from 'react-toastify';
import { ExpenseCategoryProvider, useExpenseCategory } from './getExpenseCategory';
import { ExpenseCategoryContext1 } from './expenseCategoryContext';
import { getFromAPI, postToAPI, putToAPI, formatISODateWithTimezone, handleDateFormat } from '../../../Utils/utils';

// Icons
import Icon from '@mdi/react';
import { mdiFormatListBulletedSquare, mdiPlus, mdiMagnify } from '@mdi/js';

function ExpenseCategory() {

    const [expenseData, setExpenseData] = useState([]);
    const [expenseCategoryId, setExpenseCategoryId] = useState('');
    const { expenseCategoryData, fetchExpenseCategoryData } = useExpenseCategory();
    const [searchTerm, setSearchTerm] = useState('');

    const initialInputData = {
        category_name: '',
        description: '',
        isexpense: 1,
        created_branch_id: 0,
        created_user_id: 0,
        last_modfied_user_id: 0,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isdeleted: 0
    }

    const [inputData, setInputData] = useState({ ...initialInputData });

    const handleCancel = () => {
        setInputData({ ...initialInputData });
        setExpenseCategoryId('');
    };

    useEffect(() => {
        fetchExpenseCategoryData(setExpenseData);
    }, [expenseCategoryId]);

    const handleAddExpenseCategoryData = async () => {
        if (!inputData.category_name || !inputData.category_name.trim()) {
            toast.error('Please Enter Expense Category Name');
            return;
        }
        if (!inputData.description || !inputData.description.trim()) {
            toast.error('Please Enter Description');
            return;
        }

        // Check expense category exists
        const existingCategory = expenseCategoryData.find(item => item.category_name.toLowerCase() === inputData.category_name.toLowerCase());
        if (existingCategory) {
            toast.error('Expense Category already exists');
            return;
        }

        try {
            const response = await postToAPI('FtsExpenseCategory/', inputData);
            if (response.status) {
                toast.success('Expense Category Added Successfully');
                await fetchExpenseCategoryData()
                setInputData(initialInputData);

                // Get the ID of the newly added unit
                const newUnitId = response.data.id;

                // Set the newly added product as selected
                setExpenseCategoryId(newUnitId);

                // Switch to view tab
                const viewCategoryTab = document.getElementById("pills-view-unit-tab");
                if (viewCategoryTab) {
                    viewCategoryTab.click();
                }
                // Set focus and highlight the new unit in sidebar
                setTimeout(() => {
                    const sidebarItem = document.querySelector(`.left-link-item[data-id="${newUnitId}"] a`);
                    if (sidebarItem) {
                        // Remove active class from all items
                        const allLinks = document.querySelectorAll(".left-link-item a");
                        allLinks.forEach(link => link.classList.remove("active"));

                        // Add active class to new item
                        sidebarItem.classList.add("active");
                        sidebarItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    }
                }, 100);
            } else {
                toast.error('Unit Already Exists!', 'error');
            }
        } catch (error) {
            toast.error('An unexpected error occurred', 'error');
        }
    };


    const filteredExpenseCategoryData = expenseCategoryData.filter(admin =>
        admin.category_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };


    useEffect(() => {
        const sidebarItems = document.querySelectorAll(".left-link-item"); // Use querySelectorAll for better targeting

        // Loop over all sidebar items
        sidebarItems.forEach((item) => {
            item.addEventListener('click', function () {
                // Remove active class from all sidebar links
                const activeLink = document.querySelector(".left-link-item a.active");
                if (activeLink) activeLink.classList.remove("active");

                // Add active class to the clicked item
                const link = this.querySelector('a');
                if (link) link.classList.add('active');

                // Update selected vendor ID
                const expenseId = this.getAttribute('data-id');
                setExpenseCategoryId(expenseId);

                // Trigger click on the "View Unit" tab
                const expenseUnitTab = document.getElementById("pills-view-unit-tab");
                if (expenseUnitTab) {
                    expenseUnitTab.click();  // Ensure the tab is switched programmatically
                }

            });
        });

        // Cleanup listeners on component unmount
        return () => {
            sidebarItems.forEach((item) => {
                item.removeEventListener('click', () => { });
            });
        };
    }, [filteredExpenseCategoryData]);

    // Modified useEffect to handle initial selection and updates
    useEffect(() => {
        if (expenseCategoryData.length > 0) {
            if (!expenseCategoryId || !expenseCategoryData.find(expense => expense.id === expenseCategoryId)) {
                setExpenseCategoryId(expenseCategoryData[0].id);
                setFocusOnSidebarItem(expenseCategoryData[0].id);
            }
    }
    }, [expenseCategoryData, setExpenseCategoryId]);

    // Modified setFocusOnSidebarItem function
    const setFocusOnSidebarItem = (unitId) => {
            // Remove active class from all items
            const allLinks = document.querySelectorAll(".left-link-item a");
            allLinks.forEach(link => link.classList.remove("active"));
    
            // Add active class to the new item
            const newItem = document.querySelector(`.left-link-item[data-id="${unitId}"] a`);
            if (newItem) {
                newItem.classList.add("active");
                newItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
    };

    return (
        <>
            <ExpenseCategoryContext1.Provider value={expenseCategoryId}>
                <div className="page-wrapper">
                    <div className='page-content'>
                        <Container>
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <div className='d-md-flex justify-content-between align-items-center'>
                                                <Form className='custom-form-width'>
                                                    <div className='custom-search-bar'>
                                                        <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                        <Form.Control className='' type="text" placeholder="Search expenseCategory..." name='expenseCategorySearch' id='expenseCategorySearch' onChange={handleSearchChange} value={searchTerm} />
                                                    </div>
                                                </Form>
                                                <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                    <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link active" id="pills-view-unit-tab" data-bs-toggle="pill" data-bs-target="#pills-view-unit" type="button" role="tab" aria-controls="pills-view-unit" aria-selected="true">
                                                                <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />View Expense Category
                                                            </button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-new-unit-tab" data-bs-toggle="pill" data-bs-target="#pills-new-unit" type="button" role="tab" aria-controls="pills-new-unit" aria-selected="false">
                                                                <Icon path={mdiPlus} className="btn-icon me-1" />New Expense Category
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={4} xxl={3}>
                                    <Card className='procust-left-list leftbar-list'>
                                        <SimpleBarReact>
                                            <Card.Body>
                                                <div className='left-list-bar'>
                                                    {filteredExpenseCategoryData.map((data, i) =>
                                                        <Nav.Item className='left-link-item' key={i} data-id={data.id}  onClick={() => {
                                                            setExpenseCategoryId(data.id);
                                                            setFocusOnSidebarItem(data.id);
                                                        }}>
                                                            <Nav.Link className={data.id === expenseCategoryId ? "active" : ""}>
                                                                <h5 className='product-tab-title'>{data.category_name}</h5>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    )}
                                                </div>
                                            </Card.Body>
                                        </SimpleBarReact>
                                    </Card>
                                </Col>
                                <Col lg={8} xxl={9}>

                                    <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-view-unit" role="tabpanel" aria-labelledby="pills-view-unit-tab" tabIndex="0">
                                            <ViewExpenseCategory setExpenseCategoryId={setExpenseCategoryId} />
                                        </div>
                                        <div className="tab-pane fade" id="pills-new-unit" role="tabpanel" aria-labelledby="pills-new-unit-tab" tabIndex="0">
                                            <Card className='right-content-details'>
                                                <Card.Body>
                                                    <Card.Title>New Expense Category</Card.Title>
                                                    <Form>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Expense Category Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder='Enter Expense Category Name...'
                                                                name='category_name'
                                                                id='category_name'
                                                                value={inputData.category_name}
                                                                onChange={e => setInputData({ ...inputData, category_name: e.target.value })}
                                                            />
                                                        </div>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Expense Description</Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                placeholder='Type expense description...'
                                                                rows={4} name='expenseDescription'
                                                                id='expenseDescription'
                                                                value={inputData.description}
                                                                onChange={e => setInputData({ ...inputData, description: e.target.value })}

                                                            />
                                                        </div>
                                                    </Form>
                                                </Card.Body>
                                                <div className='custom-card-footer'>
                                                    <div className='text-end btn-list'>
                                                        <Button variant="primary" onClick={handleAddExpenseCategoryData}>Add</Button>{' '}
                                                        <Button variant="secondary" onClick={handleCancel}>Cancel</Button>{' '}
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Footer />
                </div>
            </ExpenseCategoryContext1.Provider>
        </>
    )
}

export default function CustomersWithProvider() {
    return (
        <ExpenseCategoryProvider>
            <ExpenseCategory />
        </ExpenseCategoryProvider>
    );
}