import React, { useState, useEffect, useContext } from 'react';
import { Card, Button, Table } from 'react-bootstrap';
import { productContext } from './categoryContext';
import { getFromAPI, putToAPI, showAlert, deleteFromAPI, formatISODateWithTimezone } from '../../../Utils/utils';
// import ProductCategoryData from "./Product-category-data.json";
import FormModal from '../../../Utils/FormModal';
import { toast } from 'react-toastify'
import  messages from '../../../Utils/lang';

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { ProductProvider, useProduct } from  './getCategory';
export default function ViewProductCategory({setSelectProductId}) {
    const selectProductId = useContext(productContext);
    const [tableData, setTableData] = useState([]); // Initialize state with an empty array
    const date = new Date();
    const futureDate = date.getDate();
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');
    const {productData,fetchProductCategoryData} = useProduct()

    // input value
    const [data, setData] = useState({
        name: '',
        description: '',
        created_branch_id: 1,
        created_user_id: 1,
        last_modfied_user_id: 1,
        isdeleted: 0,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
    });

    useEffect(() => {
        const fetchProductCategoryDetails = async () => {
            try {
                const response = await getFromAPI(`FtsProductCategory/?id=${selectProductId}`);
                if (response && response.length > 0) {
                    const fetchedData = response[0];
                    setData({
                        name: fetchedData.name,
                        description: fetchedData.description,
                    });
                }
            } catch (error) {
                return null;
            }
        };

        if (selectProductId) {
            fetchProductCategoryDetails();
        }
    }, [selectProductId]);

    const fetchProductCategoryTable = async () => {
        try {
            const response = await getFromAPI(`FtsProductCategory/?id=${selectProductId}`);
            // Map API response to match the table data structure
            const mappedData = [
                { tableheading1: "Name :", tablesubtext1: response[0].name },
                { tableheading1: "Description :", tablesubtext1: response[0].description },
                { tableheading1: "Created User Id:", tablesubtext1: response[0].created_user_id },
                { tableheading1: "Created Date Time :",tablesubtext1: response[0].created_date_time.replace('T', ' ').replace('Z', '') 


                }
            ]

            setTableData(mappedData); // Update state with the mapped data
        } catch (error) {
            console.error('Error fetching Product Category data:', error);
        }
    };

    useEffect(() => {
        fetchProductCategoryTable();
    }, [selectProductId]);

    const handleSaveChanges = async () => {
          if(!data.name.trim()){
                toast.error('Please Enter Product Category', 'error');
                return;
            }

            if(!data.description.trim()){
                toast.error('Please Enter Product Description', 'error');
                return;
            }

        try {
            await putToAPI(`FtsProductCategory/${selectProductId}/`, data);
            toast.success('Product Category details updated successfully', 'success');
            handleFormModalClose();
            fetchProductCategoryTable();
            fetchProductCategoryData()

        } catch (error) {
            toast.error('Failed to update product category details', 'error');
        }
    };

    const handleDeleteProductCategory = async (itemId) => {
        try {
            const response = await deleteFromAPI(`FtsProductCategory/${itemId}/`);
            if (response) {
                toast.success('Product Category deleted successfully', 'success');
                setTableData([]);
                fetchProductCategoryData()
                 // Update selectedUnitId to the first unit if available
                 if (productData.length > 1) {
                    setSelectProductId(productData[0].id); // Set to the first unit
                } else {
                    setSelectProductId(''); // No units left
                }

            }
        } catch (error) {
            toast.error('Product Category Not Deleted', 'error');
        }

    };

    // Handle Delete Customer Entry
    const handleDelete = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Product Category?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDeleteProductCategory(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            toast.error('Error deleting Product Category', 'error');
        }
    };

    // Edit modal
    const [formModalShow, setShowFormModal] = useState(false);
    const handleProdCategoryEdit = () => setShowFormModal(true);
    const handleFormModalClose = () => setShowFormModal(false);

    const handleInputChange = (index, field, value) => {
        setData({ ...data, [field]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowFormModal(false);
    };

    const rows = [
        {
            columns: [
                {
                    size: { lg: 12 },
                    label: 'Product Category Name',
                    type: 'text',
                    name: 'name',
                    id: 'productCategoryName',
                    placeholder: 'Enter Product Category Name',
                    value: data.name,
                    onChange: e => setData({ ...data, name: e.target.value }),
                    errormsg: '',
                },
                {
                    size: { lg: 12 },
                    label: 'Product Description',
                    type: 'text',
                    rows: 4,
                    name: 'description',
                    id: 'description',
                    placeholder: 'Enter Product Description',
                    value: data.description,
                    onChange: e => setData({ ...data, description: e.target.value }),
                    errormsg: '',
                },
            ],
        },
    ];
    
    const Buttons = [
        { variant: "primary", text: "Update", onClick: handleSaveChanges },
        { variant: "secondary", text: "Cancel", onClick: handleFormModalClose },
    ]

    // table data
    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>View Product Category</Card.Title>
                        <div>
                            <Button variant="soft-info p-0 me-3" onClick={handleProdCategoryEdit} disabled={!selectProductId} >
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                </div>
                            </Button>
                            <Button variant="soft-danger p-0" onClick={() => handleDelete(selectProductId)} disabled={!selectProductId}>
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                </div>
                            </Button>
                        </div>
                    </div>
                    <Table responsive className='table-lg border table-nowrap mb-0'>
                    <tbody>
                            {Array.isArray(tableData) && tableData.map((data, i) => (
                                <tr key={i}>
                                    <th>{data.tableheading1}</th>
                                    <td>{data.tablesubtext1}</td>
                                    <th>{data.tableheading2}</th>
                                    <td>{data.tablesubtext2}</td>
                                </tr>
                            ))}
                        </tbody>

                    </Table>
                </Card.Body>
            </Card>

            {/* From Modal */}
            <FormModal title="Edit Product Category" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
        </>
    )
}
