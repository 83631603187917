import React, { useState, useEffect, useContext, useRef } from 'react'
import { Card, Button, Table, Modal, Form, Row, Col, Dropdown, DropdownButton, InputGroup } from 'react-bootstrap';
import { productContext } from './productContext';
import { getFromAPI, deleteFromAPI, showAlert, putToAPI, postToAPI, formatISODateWithTimezone } from '../../Utils/utils';
import { toast, ToastContainer } from "react-toastify";
import Icon from '@mdi/react';
import { mdiStore, mdiBarcodeScan, mdiTrashCanOutline, mdiSquareEditOutline, mdiPlus, mdiChevronDown } from '@mdi/js';
import Barcode from 'react-barcode'; // Import the Barcode component
import JsBarcode from 'jsbarcode';
import { fetchTaxData, fetchProductCategoryData, fetchUnitData, fetchBrandData, fetchVendorData } from '../../Utils/function_list';
import { useProduct, CustomerProvider } from './getProductData'; // Import the useCustomer hook
import Select from 'react-select';
import messages from '../../Utils/lang';
// table-data page
import ProductTableData from "./product-data.json";
const filepath = 'http://localhost:8000/media/product';

export default function ViewProduct({setSelectedProductId}) {
    // product details data
    const [tableData, setTableData] = useState([]); // Initialize state with an empty array
    const [tableData1, setTableData1] = useState([]); // Initialize state with an empty array
    const selectedProductId = useContext(productContext);
    const { productData,fetchproductData1 } = useProduct();
    const [showEditModal, setShowEditModal] = useState(false);
    const [barcodeModal, setBarcodeModal] = useState(false);
    const [Tax, setTax] = useState([]);
    const [Brand, setBrand] = useState([]);
    const [Unit, setUnit] = useState([]);
    const [productCategory, setProductCategory] = useState([]);
    const [Branch, setBranch] = useState([]);
    const [vendor, setVendorData] = useState([]);
    const barcodeRef = useRef([]);  // Initialize barcodeRef as an empty array
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [barcodeImage, setBarcodeImage] = useState('');
    const [productName, setProductName] = useState('');
    const [hsnCode, setHsnCode] = useState('');
    const [mrp, setMrp] = useState('');
    const [quantity, setQuantity] = useState(1); // State to store the input value
    const printRef = useRef(); // Ref for the printable area
    const BranchRef = useRef(null);
    const [productVisibility, setProductVisibility] = useState(null); // New state for fetched value
    const [productVisibility1, setProductVisibility1] = useState(null); // New state for fetched value
    // Todo: set branch product data
    const [branchProductInputdata, setBranchProductInputdata] = useState();
    const [BranchName,setBranchName] = useState('');
    const [description,setDescription] = useState('');
    // Todo: set warehous product data 
    const [warehouseBranchProductInputdata, setWarehouseBranchProductInputdata] = useState();

    const initialWarehouseInputData = {
        opening_consumable_qty: 0,
        opening_retail_qty: 0,
        alert_consumable_quantity: 0,
        alert_retail_quantity: 0
    }

    const [warehouseProductInputdata, setWarehouseProductInputdata] = useState(initialWarehouseInputData);

    const [inputData, setInputData] = useState({
        description: '',
        type: '',
        rate: 0.0,
        group_id: 0,
        product_code: '',
        product_category_id: 0,
        vendor_id: 0,
        weight: 1,
        unit_id: 0,
        product_purchase_price: 0.0,
        product_minimum_sell_price: 0.0,
        maximum_retail_price: 0.0,
        product_mu_percentage: 0,
        long_description: "",
        rating_id: 0,
        hsn_code: '',
        brand_id: 0,
        image: '',
        barcode_type: '',
        barcode_number: '',
        license_number: '',
        isservice: 0,
        minimum_consumption: 0.0,
        ismeasurable: 0,
        has_sub_product: 0,
        isproductimported: 0,
        iscoldstorage_require: 0,
        isonline: 0,
        created_branch_id: 1,
        created_user_id: 1,
        last_modified_user_id: 1,
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isdeleted: 0,
        reserve_varchar1: "",
        reserve_varchar2: "",
        reserve_float1: 0.0,
        reserve_float2: 0.0,
        reserve_int1: 0,
        reserve_int2: 0,
        reserve_double1: 0.0,
        branches: [],
        product_sell_price: 0,
        discount_value: 0,
        discount_type: 0,
        tax_value: 0,
        tax_type: 0
    });
    const initialUnitData = {
        name: '',
    };
    const [unitInputData, setUnitInputData] = useState(initialUnitData);
    const [printCount, setPrintCount] = useState(1); // Default to 1 print

    const fetchBranchData = async () => {
        try {
            const response = await getFromAPI('FtsBranch/');
            const branches = response.map(branch => ({ value: branch.id, label: branch.name }));
            setBranch(branches);
    
            // Prefill branches in inputData if needed
            setInputData(prevData => ({
                ...prevData,
                branches: prevData.branches || branches
            }));

        } catch (error) {
            return null;
        }
    };
    

    useEffect(()=>{
       fetchBranchData();
    },[])  



      // Fetch the option data to determine visibility
      const fetchInstoreOnFloorData = async () => {
        try {
            const response = await getFromAPI('FtsOptions/?name=product_on_floor_in_store');
            if (response[0]?.value) setProductVisibility(response[0].value); // Store the fetched value in state
        } catch (error) {
            return null;
        }
    };

    // Fetch the option data to determine visibility
    const fetchConsumbleRetailData = async () => {
        try {
            const response = await getFromAPI('FtsOptions/?name=product_consumable_stock');
            if (response[0]?.value) setProductVisibility1(response[0].value); // Store the fetched value in state
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        fetchInstoreOnFloorData();
        fetchConsumbleRetailData();
    }, []);


    const fetchProductData = async () => {
        try {
            if (!selectedProductId) {
                // Clear all data when no product is selected
                setTableData([]);
                setTableData1([]);
                setDescription('');
                setInputData({
                    description: '',
                    type: '',
                    rate: 0.0,
                    group_id: 0,
                    product_code: '',
                    product_category_id: 0,
                    vendor_id: 0,
                    weight: 1,
                    unit_id: 0,
                    product_purchase_price: 0.0,
                    product_minimum_sell_price: 0.0,
                    maximum_retail_price: 0.0,
                    product_mu_percentage: 0,
                    long_description: "",
                    rating_id: 0,
                    hsn_code: '',
                    brand_id: 0,
                    image: '',
                    barcode_type: '',
                    barcode_number: '',
                    license_number: '',
                    isservice: 0,
                    minimum_consumption: 0.0,
                    ismeasurable: 0,
                    has_sub_product: 0,
                    isproductimported: 0,
                    iscoldstorage_require: 0,
                    isonline: 0,
                    created_branch_id: 1,
                    created_user_id: 1,
                    last_modified_user_id: 1,
                    last_modified_date_time: formatISODateWithTimezone(new Date()),
                    isdeleted: 0,
                    reserve_varchar1: "",
                    reserve_varchar2: "",
                    reserve_float1: 0.0,
                    reserve_float2: 0.0,
                    reserve_int1: 0,
                    reserve_int2: 0,
                    reserve_double1: 0.0,
                    branches: [],
                    product_sell_price: 0,
                    discount_value: 0,
                    discount_type: 0,
                    tax_value: 0,
                    tax_type: 0

                });
                setWarehouseProductInputdata({
                    opening_consumable_qty: 0,
                    opening_retail_qty: 0,
                    alert_consumable_quantity: 0,
                    alert_retail_quantity: 0
                });
                return;
            }
            const response = await getFromAPI(`FtsWarehouse/?product=${selectedProductId}`);
            if (!response || response.length === 0) {
                // Handle case where no data is returned
                setDescription('');
                setTableData([]);
                setTableData1([]);
                return;
            }

            setDescription(response[0].product.description || '');
            // Fetch customer data
            const discount = parseInt(response[0].productBranchData.discount_type) === 1 ? response[0].productBranchData.discount_value + ' Rs. Off' : response[0].productBranchData.discount_value + ' % Off';
            // Map API response to match the table data structure
            const mappedData = [
                { productheading1: "Product code :", productsubtext1: response[0].product.product_code, productheading2: "Category :", productsubtext2: getProductCategoryNameById(response[0].product.product_category_id) },
                {
                    productheading1: "Type :",
                    productsubtext1: 
                        parseInt(response[0].product.type) === 1 
                            ? 'Consumable' 
                            : parseInt(response[0].product.type) === 2 
                                ? 'Retail' 
                                : 'Both (Consumbale and Retail)',  // When product.type is 0
                    productheading2: "Unit :",
                    productsubtext2: getUnitNameById(response[0].product.unit_id)
                },               
                { productheading1: "Cost Price :", productsubtext1: response[0].product.product_purchase_price, productheading2: "Sell Price :", productsubtext2: response[0].productBranchData.product_sell_price },
                { productheading1: "Brand :", productsubtext1: "NA", productheading2: "MU :", productsubtext2: response[0].product.product_mu_percentage },
                { productheading1: "vendor :", productsubtext1: getVendorNameById(response[0].product.vendor_id), productheading2: "Discount :", productsubtext2: discount },
                { productheading1: "HSN :", productsubtext1: response[0].product.hsn_code, productheading2: "Product MRP :", productsubtext2: response[0].product.maximum_retail_price },
                { productheading1: "Product Sold :", productsubtext1: '0', productheading2: "Product Used :", productsubtext2: '0' },

            ];

            const mappedData1 = [
                { retailQuantity: "Opening Qty (R) : ", retailQuantityData: response[0].opening_retail_qty, consumableQuantity: "Opening Qty (C) :", consumableQuantityData: response[0].opening_consumable_qty },
                
                // Conditional rendering based on productVisibility
                ...(productVisibility === '2' || productVisibility === '0' ? [{
                    retailQuantity: "On Floor (R) : ", retailQuantityData: response[0].onfloor_retail_qty, consumableQuantity: "On Floor (C) :", consumableQuantityData: response[0].onfloor_consumable_qty
                }] : []),
            
                ...(productVisibility === '1' || productVisibility === '0' ? [{
                    retailQuantity: "In Store (R) : ", retailQuantityData: response[0].instore_retail_qty, consumableQuantity: "In Store (C) :", consumableQuantityData: response[0].instore_consumable_qty
                }] : []),
            
                { retailQuantity: "Alert Qty (R) : ", retailQuantityData: response[0].alert_retail_quantity, consumableQuantity: "Alert Qty (C) :", consumableQuantityData: response[0].alert_consumable_quantity },
                { retailQuantity: "Damage Qty (R) : ", retailQuantityData: "NA", consumableQuantity: "Damage Qty (C) :", consumableQuantityData: "NA" },
                { retailQuantity: "Transferred Qty (R) : ", retailQuantityData: "NA", consumableQuantity: "Transferred Qty (C) :", consumableQuantityData: "NA" },
                { retailQuantity: "Return Qty (R) :	", retailQuantityData: "NA", consumableQuantity: "Return Qty (C) :", consumableQuantityData: "NA" },
                { retailQuantity: "Total Gift Card Spending :", retailQuantityData: "NA" }
            ];

            setTableData(mappedData); // Update state with the mapped data
            setTableData1(mappedData1)

            const branchIds = response.length > 1 
            ? response.map(item => item.branch.id).join(',') 
            : response[0].branch.id;
            let branchNames = ''
            if (response.length > 1) {
                branchNames = response.map((item) => item.branch.name).join(", ");
                setBranchName(branchNames)
            } else {
                branchNames = response[0].branch.name;
                setBranchName(branchNames)
            }

            setInputData({ ...inputData, description: response[0].product.description, }); // Assuming response[0] contains all necessary data
            setInputData({
                description: response[0].product.description,
                type: response[0].product.type,
                product_code: response[0].product.product_code,
                hsn_code: response[0].product.hsn_code,
                brand_id: response[0].product.brand_id,
                product_category_id: response[0].product.product_category_id,
                unit_id: response[0].product.unit_id,
                vendor_id: response[0].product.vendor_id,
                maximum_retail_price: response[0].product.maximum_retail_price,
                product_purchase_price: response[0].product.product_purchase_price,
                product_mu_percentage: response[0].product.product_mu_percentage,
                long_description: response[0].product.long_description,
                isonline: response[0].product.isonline,
                created_branch_id: response[0].product.created_branch_id,
                created_user_id: response[0].product.created_user_id,
                last_modified_user_id: response[0].product.last_modified_user_id,
                last_modified_date_time: formatISODateWithTimezone(new Date()),
                isdeleted: response[0].product.isdeleted,
                tax_type : response[0].productBranchData.tax_type,
                discount_value : response[0].productBranchData.discount_value,
                tax_value : response[0].productBranchData.tax_value,
                branch_id : response[0].branch.id,
                product_sell_price : response[0].productBranchData.product_sell_price,
                discount_type : response[0].productBranchData.discount_type,
                branches : String(branchIds),
            });

            setWarehouseProductInputdata({
                opening_consumable_qty: response[0].opening_consumable_qty,
                opening_retail_qty: response[0].opening_retail_qty,
                alert_consumable_quantity: response[0].alert_consumable_quantity,
                alert_retail_quantity: response[0].alert_retail_quantity,
                onfloor_consumable_qty: response[0].onfloor_consumable_qty,
                onfloor_retail_qty: response[0].onfloor_retail_qty,
                instore_consumable_qty : response[0].instore_consumable_qty,
                instore_retail_qty : response[0].instore_retail_qty,
    
            });

        } catch (error) {
            return null;
        }
    };


    useEffect(() => {
        fetchProductData();
    }, [selectedProductId]);

    useEffect(() => {
        fetchTaxData(setTax);
        fetchBranchData(setBranch);
        fetchBrandData(setBrand);
        fetchProductCategoryData(setProductCategory);
        fetchUnitData(setUnit);
        fetchVendorData(setVendorData)
    }, []);


    const handleCloseModal = () => {
        setShowEditModal(false);
    };

    const handleCloseBarcode = () => {
        setBarcodeModal(false);
        setPrintCount(1);

    };

    const showMyStore = event => {
        document.getElementById("my-store").classList.remove('d-none');
        document.getElementById("view-product").classList.add('d-none');
    }

    // Handle Delete Product Entry
    const handleDelete = async (Id) => {
        try {
            showAlert(messages.product.delete_alert, 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDeleteProduct(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            toast.error('Error deleting Product', 'error');
            return null;
        }
    };

    const handleDeleteProduct = async (itemId) => {
        try {
            const response = await deleteFromAPI(`FtsItems/${itemId}/`);
            if (response.status) {
                toast.success('Product deleted successfully', 'success');
                // Optionally, redirect or update state to reflect deletion
                 // Clear all data immediately after successful deletion
                 setDescription('');
                 setTableData([]);
                 setTableData1([]);
                 setSelectedProductId(null);
                fetchproductData1();
                if(productData.length>0)
                {
                    setSelectedProductId(productData[0].id);
                }
                else
                {
                    setSelectedProductId('');
                }
                setTableData([]);
            }
        } catch (error) {
            toast.error('Product Not Deleted', 'error');
        }

        fetchproductData1();
    };

    const getVendorNameById = (VendorID) => {
        let ven = vendor.find(v => v.id === VendorID);
        return ven ? ven.company_name : 'NA';
    };

    const getProductCategoryNameById = (prodCatID) => {
        let prodCat = productCategory.find(pc => pc.id === prodCatID);
        return prodCat ? prodCat.name : 'NA';
    };

    const getUnitNameById = (UnitID) => {
        let unit = Unit.find(u => u.id === UnitID);
        return unit ? unit.name : 'NA';
    };

    const handleEdit = () => {
        setShowEditModal(true);
    };

    const handleSaveChanges = async () => {
        if (!inputData.description.trim()) {
            toast.error('Please enter valid product name', 'error')
            return;
        }
        if (inputData.type === '') {
            toast.error('Please Select valid product type', 'error')
            return;
        }

        const isExists = Array.isArray(productData) && productData.find(prod =>
            prod.description === inputData.description
            && prod.id!== selectedProductId
         );
        
        if(isExists)
        {
            toast.error(messages.product.already_exist);
            return;
        }

        try {
             // Update the input data
            const updatedInputData = {
                ...inputData,
            };

            const FinalProductInputData = { ...updatedInputData, warehouseData: warehouseProductInputdata }
            // Currently using inputData to update product as we are not updating product quantity
            await putToAPI(`FtsItems/${selectedProductId}/`, FinalProductInputData);
            toast.success('Product details updated successfully', 'success');
            setShowEditModal(false);
            fetchProductData();
            fetchproductData1();

        } catch (error) {
            toast.error('Failed to update Product details', 'error');
            return null;
        }

        // TODO: update branch related product data

        // TODO: update Warehouse data

    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setInputData({
            ...inputData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleAddUnit = async () => {
        try {
            //Post the data to the Api
            const response = await postToAPI("FtsUnit/", unitInputData)
            if (response.status) {
                toast.success('Unit Added Successfully')
                handleClose()
                fetchUnitData(setUnit);
                setUnitInputData(initialUnitData)
            }
            else {
                toast.error('Failed to add customer');
            }
        } catch (error) {
            toast.error('An unexpected error occurred');
            return null
        }
    };
    const handleCheckboxChange = (e) => {
        setInputData({ ...inputData, isonline: e.target.checked });
    };

    const fetchProductData1 = async () => {
        if (selectedProductId) {
            try {
                const response = await getFromAPI(`FtsItems/?id=${selectedProductId}`);
    
                if (response && response.length > 0) {
                    const product = response[0]; // Get the first product object
                    setInputData(product);
                    setProductName(product.description); // Set product name
                    setHsnCode(product.hsnCode); // Set HSN code
                    setMrp(product.maximum_retail_price); // Set MRP
                    setBarcodeImage(`${filepath}/barcode_${product.product_code}.png`); // Set barcode image URL
                } else {
                    // Handle case where no product is found
                    clearProductData(); // Clear the form or reset state if no product is found
                }
            } catch (error) {
                console.error("Error fetching product data:", error);
            }
        }
    };
    
    // Helper function to clear product data
    const clearProductData = () => {
        setInputData(null);
        setProductName('');
        setHsnCode('');
        setMrp('');
        setBarcodeImage('');
    };
    
    useEffect(() => {
        fetchProductData1();
    }, [selectedProductId]);


    // Handle the quantity input change
    const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
    };

    // Reset quantity to 1 when modal is closed
    const handleCloseAndReset = () => {
        setQuantity(1); // Reset quantity
        handleCloseBarcode(); // Close the modal
    };
    // Handle print functionality
    const handlePrint = () => {
        window.print();
    };

    // Handle multi-select changes
    const handleSelectChange = (selectedOptions) => {
        setInputData(prevInputData => ({
        ...prevInputData,
        branches: selectedOptions,
        }));
    };


    return (
        <>
            <Card className='view-product-content right-content-details' id='view-product'>
                <Card.Body>
                    <Card.Title>{description}</Card.Title>
                    <div className='d-flex justify-content-between aling-items-center mb-3'>
                        <div className="btn-list">
                            <Button variant="light" onClick={showMyStore}><Icon path={mdiStore} className="btn-icon me-1" /> My Store</Button>{' '}
                            <Button variant="light" onClick={() => setBarcodeModal(true)}><Icon path={mdiBarcodeScan} className="btn-icon me-1" /> Barcode</Button>{' '}
                        </div>
                        <div>
                            {selectedProductId && (<>
                                <Button variant="soft-info p-0 me-3" onClick={handleEdit} >
                                    <div className='avatar avatar-sm'>
                                        <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                    </div>
                                </Button>
                                <Button variant="soft-danger p-0" onClick={() => handleDelete(selectedProductId)}>
                                    <div className='avatar avatar-sm'>
                                        <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                    </div>
                                </Button>
                            </>)}
                        </div>
                    </div>

                    <Table responsive className='table-lg border'>
                        <tbody>
                            {tableData.map((data, i) =>
                                <tr key={i} className='table-details-list'>
                                    <th>{data.productheading1}</th>
                                    <td>{data.productsubtext1}</td>
                                    <th>{data.productheading2}</th>
                                    <td>{data.productsubtext2}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    <Table responsive className='table-lg border'>
                        <thead className='table-light'>
                            <tr>
                                <th>Retail (R) Quantity</th>
                                <th></th>
                                <th>Consumable (C) Quantity</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData1.map((data, i) =>
                                <tr key={i} className='table-details-list'>
                                    <th>{data.retailQuantity}</th>
                                    <td>{data.retailQuantityData}</td>
                                    {
                                      productVisibility1 === '1' ? (
                                        <>
                                        <th>{data.consumableQuantity}</th>
                                        <td>{data.consumableQuantityData}</td>
                                        </>
                                    ) : (
                                        <td>Not Available</td> // Or any other fallback content
                                    )
                                    }
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            <Modal centered show={barcodeModal} onHide={handleCloseAndReset}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>Product Barcode</h5>
                    <Form>
                        {/* Display product details dynamically based on input */}
                        <div className="mt-3 mb-4">
                            <div className='border rounded py-3 px-4 mb-3'>
                                <p className='mb-3'>Product Name: {productName}</p>
                                <p className='mb-3'>HSN Code: {hsnCode}</p>
                                <p className='mb-3'>MRP: {mrp}</p>
                                <img src={barcodeImage} alt="Barcode" style={{ width: '150px', height: 'auto' }} />
                            </div>

                            {/* Repeated details based on the entered quantity */}
                            {Array.from({ length: quantity - 1 }, (_, i) => (
                                <div key={i} className='border rounded py-3 px-4 mb-3'>
                                    <p className='mb-3'>Product Name: {productName}</p>
                                    <p className='mb-3'>HSN Code: {hsnCode}</p>
                                    <p className='mb-3'>MRP: {mrp}</p>
                                    <img src={barcodeImage} alt="Barcode" style={{ width: '150px', height: 'auto' }} />
                                </div>
                            ))}
                        </div>
                        <Form.Group className='mb-3 custom-form-input' controlId="quantity">
                            <Form.Label>Enter Number of Barcode to Print</Form.Label>
                            <Form.Control type="number" value={quantity} onChange={handleQuantityChange}
                                placeholder="Enter Number of Barcode to Print (e.g. 2, 5, 10)"
                            />
                        </Form.Group>
                    </Form>
                    <div className='text-end btn-list'>
                        <Button variant="secondary" onClick={handleCloseAndReset}>Close</Button>
                        <Button variant="primary" onClick={handlePrint}>Print</Button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal centered size="xl" show={showEditModal} onHide={handleCloseModal}>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>Edit Product</h5>
                    <Form>
                        <Row>
                            <Col lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Product Name</Form.Label>
                                    <Form.Control type="text" name='productName' id='productName' placeholder="Enter Product Name..." value={inputData?.description || ''}
                                        onChange={e => setInputData({ ...inputData, description: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Product Type</Form.Label>
                                    <Form.Select name='productType' id='productType' value={inputData?.type || ''}
                                        onChange={e => setInputData({ ...inputData, type: e.target.value })}
                                    >
                                        <option>Select Product Type</option>
                                        {productVisibility1 === '1' && (
                                        <>
                                            <option value="0">Both (Consumable and Retail)</option>
                                            <option value="1">Consumable</option>
                                        </>
                                        )}
                                        <option value="2">Retail</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Product Code</Form.Label>
                                    <Form.Control type="text" placeholder="Enter code..." name='productCode' id='productCode' value={inputData?.product_code||''}
                                        onChange={e => setInputData({ ...inputData, product_code: e.target.value })} disabled={true}
                                    />
                                </div>
                            </Col>
                            <Col lg={5}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>HSN</Form.Label>
                                    <Form.Control type="text" placeholder="Enter HSN..." name='productHsn' id='productHsn' value={inputData?.hsn_code||''}
                                        onChange={e => setInputData({ ...inputData, hsn_code: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col lg={7}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Select name='productBrand' id='productBrand' value={inputData?.brand_id || ''}
                                        onChange={e => setInputData({ ...inputData, brand_id: e.target.value })}>
                                        <option value="">Select Brand</option>
                                        {Brand.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Product Category</Form.Label>
                                        <Form.Select name='productCategory' id='productCategory' value={inputData?.product_category_id || ''}
                                            onChange={e => setInputData({ ...inputData, product_category_id: e.target.value })}>
                                            <option value="">Select Product Category</option>
                                            {productCategory.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Unit</Form.Label>
                                        <Form.Select name='productUnit' id='productUnit' value={inputData?.unit_id || ''}
                                            onChange={e => setInputData({ ...inputData, unit_id: e.target.value })}>
                                            <option value="">Select Unit</option>
                                            {Unit.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alert Qty (Retail)</Form.Label>
                                    <Form.Control type="number" placeholder="Enter Qty..." name='alertRetail' id='alertRetail'
                                        value={warehouseProductInputdata.alert_retail_quantity}
                                        onChange={e => setWarehouseProductInputdata({ ...warehouseProductInputdata, alert_retail_quantity: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alert Qty (Consumable)</Form.Label>
                                    <Form.Control type="number" placeholder="Enter Qty..." name='alertConsumable' id='alertConsumable' value={warehouseProductInputdata.alert_consumable_quantity}
                                        onChange={e => setWarehouseProductInputdata({ ...warehouseProductInputdata, alert_consumable_quantity: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Selling Price</Form.Label>
                                    <Form.Control type="number" placeholder="Enter price..." name='sellingPrice' id='sellingPrice' value={inputData?.product_sell_price||''}
                                        onChange={e => setInputData({ ...inputData, product_sell_price: e.target.value })}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <h5 className='my-4'>Opening Quantity</h5>
                        <Row>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Consumable</Form.Label>
                                    <Form.Control type="number" placeholder="Enter consumable..." name='productConsumable' id='productConsumable'
                                        value={warehouseProductInputdata.opening_consumable_qty}
                                        onChange={e => setWarehouseProductInputdata({ ...warehouseProductInputdata, opening_consumable_qty: e.target.value })} disabled
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label> Opening Qty (Retail)</Form.Label>
                                    <Form.Control type="nmber" placeholder="Enter qty..." name='openingRetail' id='openingRetail' value={warehouseProductInputdata.opening_retail_qty}
                                        onChange={e => setWarehouseProductInputdata({ ...warehouseProductInputdata, opening_retail_qty: e.target.value })} disabled
                                    />
                                </div>
                            </Col>
                        </Row>

                        <h5 className='my-4'>Other Details</h5>
                        <Row>
                            <Col md={5}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Vendors</Form.Label>
                                        <Form.Select name='productVendors' id='productVendors' value={inputData?.vendor_id||''}
                                            onChange={e => setInputData({ ...inputData, vendor_id: e.target.value })}
                                        >
                                            <option value="">Select Vendor</option>
                                            {vendor.map(item => (
                                                <option key={item.id} value={item.id}>{item.company_name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                   
                                </div>
                            </Col>
                            <Col lg={7}>
                                <Row>
                                    <Col lg={4}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>MRP</Form.Label>
                                            <Form.Control type="number" placeholder="Enter MRP..." name='productMrp' id='productMrp' value={inputData?.maximum_retail_price||''}
                                                onChange={e => setInputData({ ...inputData, maximum_retail_price: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>Cost</Form.Label>
                                            <Form.Control type="number" placeholder="Enter cost..." name='procuctCost' id='procuctCost' value={inputData?.product_purchase_price||''}
                                                onChange={e => setInputData({ ...inputData, product_purchase_price: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={4}>
                                        <div className="mb-3 custom-form-input">
                                            <Form.Label>MU</Form.Label>
                                            <Form.Control type="number" placeholder="Enter MU..." name='productMu' id='productMu' value={inputData?.product_mu_percentage||''}
                                                onChange={e => setInputData({ ...inputData, product_mu_percentage: e.target.value })}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={3}>
                                <InputGroup className="mb-3 custom-form-input">
                                <Form.Control
                                        type = "number"
                                        placeholder="Enter discount..."
                                        name="reserve_int1"
                                        id="reserve_int1"
                                        value={inputData?.discount_value||''} 
                                        onChange={e => setInputData({ ...inputData, discount_value: e.target.value })}
                                    />
                                    <Form.Label>Discount</Form.Label>
                                    <DropdownButton
                                        variant="light"
                                        title={
                                            <>
                                                {inputData?.discount_type === 0 ? `% Off` : 'Rs. Off'} 
                                                <Icon path={mdiChevronDown} className="drop-arrow ms-1 icon-size-15" />
                                            </>
                                        }
                                        id="input-group-dropdown-2"
                                        align="end"
                                        onSelect={e => setInputData({ ...inputData, discount_type: parseInt(e) })} // Trigger when a dropdown item is selected
                                    >
                                        <Dropdown.Item eventKey="0">% Off</Dropdown.Item>
                                        <Dropdown.Item eventKey="1">Rs. Off</Dropdown.Item>
                                </DropdownButton>
                                </InputGroup>
                            </Col>
                            <Col lg={2}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select name='productTax' id='productTax' value={inputData?.tax_value||''}
                                        onChange={e => setInputData({ ...inputData, tax_value: e.target.value })}
                                    >
                                        <option value="">None</option>
                                        {Tax.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col lg={2}>
                            <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax type</Form.Label>
                                    <Form.Select
                                        name='taxType'
                                        id='taxType'
                                        value={inputData?.tax_type||''}
                                        onChange={e => setInputData({ ...inputData, tax_type: parseInt(e.target.value) })}
                                        >
                                        <option>Select tax type</option>
                                        <option value="1">Exclusive</option>
                                        <option value="2">Inclusive</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col lg={5}>
                               <div className="mb-3 custom-form-input">
                                    <Form.Label className='z-1'>Branch</Form.Label>
                                    <Form.Control
                                        type = "text"
                                        placeholder="productBranch"
                                        name="productBranch"
                                        id="productBranch"
                                        value={BranchName}
                                        disabled 
                                    />

                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control as="textarea" rows={3} placeholder="Enter description..." name='productDescription' id='productDescription'
                                        value={inputData?.long_description||''} onChange={e => setInputData({ ...inputData, long_description: e.target.value })}
                                    />
                                </div>
                                <Form.Group className="mb-3">
                                    <Form.Check type="checkbox" label="Is Online" name='productOnline' id='productOnline' checked={inputData?.isonline||''} onChange={handleCheckboxChange} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSaveChanges}>Save Changes</Button>
                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
