import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Card, Button, Table } from 'react-bootstrap';
import Footer from '../layout/Footer';
import FormModal from '../../Utils/FormModal';

// icon
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';

import LeaveTableData from './leave-data.json';
import { useNavigate } from 'react-router-dom';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import InputElement from '../../Utils/elements/InputElement';
import SelectElement from '../../Utils/elements/SelectElement';
import LeaveApproval from './LeaveApproval';
import { toast } from 'react-toastify';
import { getFromAPI, postToAPI, deleteFromAPI, showAlert, putToAPI } from '../../Utils/utils';

export default function Leave() {
    // datatable
    const [leaveData, setLeaveData] = useState([]);
    const [reportToEmail, setReportToEmail] = useState("");
    const [reportToName, setReportToName] = useState("");
    const useEmail = localStorage.getItem('email');
    const useName = localStorage.getItem('firstname');
    const isAdmin = Number(localStorage.getItem('admin'));
    const [edit, setEdit] = useState('');
    const [adminLeaveData, setAdminApprovalLeaveData] = useState([]);
    const [annualLeaveDays, setAnnualLeaveDays] = useState(0);
    const [sickLeaveDays, setSickLeaveDays] = useState(0);
    const [casualLeaveDays, setCasualLeaveDays] = useState(0);
    const [paidLeaveDays, setPaidLeaveDays] = useState(0);
    const navigate = useNavigate(); // Initialize navigate function

    const fetchBranchAdminData = async () => {
        try {
            const staffResponse = await getFromAPI(`leave_data/?email=${useEmail}`);

            // Ensure staffResponse and staff_admin_data are present before setting state
            if (staffResponse && staffResponse.staff_admin_data) {
                setReportToEmail(staffResponse.staff_admin_data.email); // Adjusted to access email correctly
                setReportToName(staffResponse.staff_admin_data.firstname); // Adjusted to access firstname correctly

            } else {
                console.error("staff_admin_data is undefined in the response");
            }
        } catch (error) {
            console.error("Error fetching branch admin data:", error);
        }
    };


    const initialLockerData = {
        leaveEmployeeName: useName,
        leaveType: '',
        leaveDes: '',
        leaveStartDate: '',
        leaveEndDate: '',
        leaveTotalDays: '',
        leave_report_to_name: '',
        leave_report_to_email: '',
        leaver_email: useEmail,
        leave_part_selection: '',
        leave_rejection_reason: '',
        leave_status: 'Pending',
        halfDayType: '',
    };

    const [data, setData] = useState(initialLockerData);

    useEffect(() => {
        fetchBranchAdminData();
    }, []);

    const fetchLeaveData = async () => {
        try {
            const response = await getFromAPI(`FtsLeave/?leaver_email=${useEmail}&&leave_employee_name=${useName}`)
            if (response) {
                setLeaveData(response)
                countLeaveStatuses(response); // Call the function to count the leave statuses

            }
            else {
                console.log("No data found")
            }
        }
        catch (error) {
            console.error("Error fetching leave data:", error)
        }
    };

    useEffect(() => {
        fetchLeaveData();
    }, []);

    // Helper function to convert leave days (strings like "8 Hours", "1 Day") to float
    const convertToFloat = (leaveString) => {
        // If it's a number, return it directly
        if (typeof leaveString === 'number') {
            return leaveString;
        }

        // Remove non-numeric characters (e.g., "Hours", "Day", etc.)
        const numericValue = parseFloat(leaveString.replace(/[^\d.-]/g, ''));

        // Check if the parsed value is a valid number
        if (!isNaN(numericValue)) {
            return numericValue;
        }

        return 0; // Return 0 if the value isn't valid
    };

    const countLeaveStatuses = (leaveData) => {
        let totalAnnualLeaves = 30; // Total annual leaves
        let totalPaidLeavePool = 10; // Initial paid leave pool
        let totalCasualLeavePool = 10; // Initial casual leave pool
        let totalSickLeavePool = 10; // Initial sick leave pool

        let usedPaidLeaveDays = 0;
        let usedCasualLeaveDays = 0;
        let usedSickLeaveDays = 0;

        // Loop through each leave to count the leave types and total days
        leaveData.forEach(leave => {
            if (leave.leave_status === 'Approved') { // Only approved leaves

                let leaveDurationInDays = 0;

                // Convert leave total days to float
                if (leave.leave_total_days) {
                    leaveDurationInDays = convertToFloat(leave.leave_total_days);
                }
                // Deduct leave days from respective pools
                switch (leave.leave_type) {
                    case 'Paid Leave':
                        if (totalPaidLeavePool >= leaveDurationInDays) {
                            usedPaidLeaveDays += leaveDurationInDays;
                            totalPaidLeavePool -= leaveDurationInDays;
                            totalAnnualLeaves -= leaveDurationInDays;
                        } else {
                            console.warn(
                                `Not enough paid leave days in pool. Requested: ${leaveDurationInDays}, Available: ${totalPaidLeavePool}`
                            );
                        }
                        break;
                    case 'Casual Leave':
                        if (totalCasualLeavePool >= leaveDurationInDays) {
                            usedCasualLeaveDays += leaveDurationInDays;
                            totalCasualLeavePool -= leaveDurationInDays;
                            totalAnnualLeaves -= leaveDurationInDays;
                        } else {
                            console.warn(
                                `Not enough casual leave days in pool. Requested: ${leaveDurationInDays}, Available: ${totalCasualLeavePool}`
                            );
                        }
                        break;
                    case 'Sick Leave':
                        if (totalSickLeavePool >= leaveDurationInDays) {
                            usedSickLeaveDays += leaveDurationInDays;
                            totalSickLeavePool -= leaveDurationInDays;
                            totalAnnualLeaves -= leaveDurationInDays;
                        } else {
                            console.warn(
                                `Not enough sick leave days in pool. Requested: ${leaveDurationInDays}, Available: ${totalSickLeavePool}`
                            );
                        }
                        break;
                    default:
                        console.warn("Unknown leave type:", leave.leave_type);
                        break;
                }
            }
        });

        // Ensure no negative values
        totalAnnualLeaves = Math.max(0, totalAnnualLeaves);
        totalPaidLeavePool = Math.max(0, totalPaidLeavePool);
        totalCasualLeavePool = Math.max(0, totalCasualLeavePool);
        totalSickLeavePool = Math.max(0, totalSickLeavePool);

        // Update state (if using React)
        setPaidLeaveDays(totalPaidLeavePool);
        setCasualLeaveDays(totalCasualLeavePool);
        setSickLeaveDays(totalSickLeavePool);
        setAnnualLeaveDays(totalAnnualLeaves); // Remaining annual leaves
    };

    const fetchAdminApprovedLeave = async () => {
        try {
            const response = await getFromAPI(`FtsLeave/?leave_report_to_email=${useEmail}`);
            if (response) {
                setAdminApprovalLeaveData(response);
            } else {
                setAdminApprovalLeaveData([])
            }
        } catch (error) {
            console.error("Error fetching admin-approved leave:", error);
        }
    };

    useEffect(() => {
        fetchAdminApprovedLeave();
    }, []);


    // Add/Edit modal
    const [formModalShow, setShowFormModal] = useState(false);
    const handleLeaveModal = () => {
        setEdit('');
        setShowFormModal(true);
        setData(initialLockerData);

    };

    const handleFormModalClose = () => {
        setEdit('');
        setShowFormModal(false)
        setData(initialLockerData);

    };

    const handleAddLeave = async () => {
        const requestData = {
            leave_report_to_name: reportToName,
            leave_report_to_email: reportToEmail,
            leaver_email: useEmail,
            leave_status: 'Pending',
            leave_employee_name: data.leaveEmployeeName,
            leave_type: data.leaveType,
            leave_description: data.leaveDes,
            leave_start_date: data.leaveStartDate,
            leave_end_date: data.leaveEndDate,
            leave_total_days: data.leaveTotalDays,
            leave_part_selection: data.leave_part_selection,
        }
        try {
            const response = await postToAPI('FtsLeave/', requestData)
            if (response) {
                toast.success('Leave Request Submitted Successfully', 'success');
                fetchLeaveData();
                setShowFormModal(false);
                setData(initialLockerData);
            }
            else {
                toast.error('Leave Request Not Submitted:')
            }
        } catch (e) {
            // console.error('Error:',e);
        }
    };

    useEffect(() => {
        return () => {
            if ($.fn.dataTable.isDataTable('#mainDatatableLeave')) {
                $('#mainDatatableLeave').DataTable().destroy();
            }
        };
    }, [leaveData]);

    // Handle Delete Locker Entry
    const handleDeleteLeave = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Leave?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    deleteLeave(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            console.error('Error deleting Leave:', error);
            toast.error('Error deleting Leave', 'error');
        }
    };

    //Function for the deleteing locker fromt the database 
    const deleteLeave = async (Id) => {
        try {
            const response = await deleteFromAPI(`FtsLeave/${Id}/`);
            if (response) {
                fetchLeaveData();
                toast.success('Leave deleted successfully!', 'success');
            } else {
                showAlert('Error deleting Leave', 'error');
            }
        } catch (error) {
            console.error('Error deleting Leave:', error);
            toast.error('Error deleting Leave', 'error');
        }
    };

    const UpdatedModalContent = async (id) => {
        // Find the data based on the id
        const data = leaveData.find((team) => String(team.id) === String(id));

        // Check if data exists before continuing
        if (!data) {
            return;
        }

        // Initialize leave part selection array based on date range
        const startDate = new Date(data.leave_start_date);
        const endDate = new Date(data.leave_end_date);
        let dateArray = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            dateArray.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        // Parse existing leave_part_selection or create default values
        let leavePartSelections = [];
        try {
            // Try to parse existing selection if it exists
            if (data.leave_part_selection) {
                const cleanedString = data.leave_part_selection.replace(/'/g, '"');
                leavePartSelections = JSON.parse(cleanedString);
            }
        } catch (e) {
            console.warn("Error parsing leave_part_selection:", e);
        }

        // Ensure we have a selection for each date, defaulting to 'Full Day' if none exists
        const normalizedSelections = dateArray.map((_, index) => {
            return leavePartSelections[index] || 'Full Day';
        });

        // Calculate total days based on selections
        let totalHours = normalizedSelections.reduce((total, part) => {
            if (part.toLowerCase() === "first half" || part.toLowerCase() === "second half") {
                return total + 4;
            } else {
                return total + 8; // Default to full day (8 hours)
            }
        }, 0);

        const totalDays = (totalHours / 8).toFixed(1);

        // Update the state with all necessary data
        setData({
            leaveEmployeeName: data.leave_employee_name,
            leaveType: data.leave_type,
            leaveEndDate: data.leave_end_date,
            leaveStartDate: data.leave_start_date,
            leaveTotalDays: totalDays,
            leave_part_selection: normalizedSelections,
            leaveDes: data.leave_description,
            leave_status: data.leave_status,
        });

        // Update any UI elements that need to reflect the selections
        normalizedSelections.forEach((value, index) => {
            const element = document.getElementById(`leaveType_${index}`);
            if (element) {
                element.value = value;
            }
        });
    };

    const handleEditClick = (id) => {
        setShowFormModal(true);
        UpdatedModalContent(id);
        setEdit(id);
    };

    // function for the updateing data add & edit time in database 
    const updateDataInDatabase = useCallback(async (itemDetails) => {
        const updateUrl = `FtsLeave/${edit}/`;
        const leavePartSelection =
            typeof itemDetails.leave_part_selection === "string"
                ? itemDetails.leave_part_selection
                : JSON.stringify(itemDetails.leave_part_selection);


        const requestBody = {
            leave_report_to_name: reportToName,
            leave_report_to_email: reportToEmail,
            leaver_email: useEmail,
            leave_employee_name: itemDetails.leaveEmployeeName,
            leave_type: itemDetails.leaveType,
            leave_description: itemDetails.leaveDes,
            leave_start_date: itemDetails.leaveStartDate,
            leave_end_date: itemDetails.leaveEndDate,
            leave_total_days: itemDetails.leaveTotalDays,
            leave_status: itemDetails.leave_status,
            leave_part_selection: leavePartSelection,
            leave_report_to_email: reportToEmail,
            leave_report_to_name: reportToName
        };

        try {
            const data = await putToAPI(updateUrl, requestBody);
            if (data) {
                fetchLeaveData();
                setShowFormModal(false);
                toast.success('Leave updated successfully!', 'success');
                setEdit('')
            } else {
                console.error('Unexpected response:', data.message);
            }
        } catch (error) {
            // Handle network or other errors
            console.error('Error updating Leave::', error);
            toast.error('Cannot update leave request, and no further modifications are allowed');
        }
    }, [leaveData, edit, data]);

    // Handle both Add and Update actions
    const handleBothActions = () => {

        if (!data.leaveStartDate) {
            toast.error('Please enter Start Date')
            return;
        } else if (!data.leaveEndDate) {
            toast.error('Please select end date', 'error');
            return;
        }

        if (data.leaveStartDate > data.leaveEndDate) {
            toast.error('Start date should be before end date', 'error');
            return;
        }

        if (!data.leaveType) {
            toast.error('Please select Leave Type', 'error');
            return;
        }

        // Validate immutable fields
        if (data.leaveEmployeeName !== useName) {
            toast.error("Employee Name not editable");
            return;
        }


        const totalHours = calculateTotalHours();
        const totalDays = (totalHours / 8).toFixed(1);

        if (data.leaveTotalDays !== totalDays) {
            toast.error('Total days should not be edited manually', 'error');
            return;
        }

        if (edit === '') {
            handleAddLeave();
        }
        else {
            updateDataInDatabase(data);
        }
    };

    useEffect(() => {
        return () => {
            if ($.fn.dataTable.isDataTable('#mainDatatableLeaveList')) {
                $('#mainDatatableLeaveList').DataTable().destroy();
            }
        };
    }, [adminLeaveData]);

    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableLeaveList')) {
            $('#mainDatatableLeaveList').DataTable({
                dom: "Bflrtip",
                pageLength: 25,
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
                        filename: 'myRetails_Leave', // Set the filename for the Excel export
                        title: 'myRetails Leave' // Remove title from the Excel sheet if not needed
                    }
                ],
                data: adminLeaveData.map((data) => [
                    data.leave_employee_name,
                    data.leave_type,
                    `${data.leave_start_date} to ${data.leave_end_date}`,
                    data.leave_total_days,
                    data.leave_status,
                    data.id,
                    data.leave_tokens // Add token to the data array
                ]),
                columns: [
                    { title: "Employee Name" },
                    { title: "Leave Type" },
                    { title: "Leave Period" },
                    { title: "Days/Hours Taken" },
                    {
                        title: "Leave Status",
                        render: function (data, type, row) {
                            let badgeClass = '';
                            if (data === 'Pending') {
                                badgeClass = 'badge bg-soft-warning text-dark'; // Warning badge
                            } else if (data === 'Approved') {
                                badgeClass = 'badge bg-soft-success'; // Success badge
                            } else if (data === 'Rejected') {
                                badgeClass = 'badge bg-soft-danger'; // Danger badge
                            }
                            return `<span class="${badgeClass}">${data}</span>`;
                        }
                    },
                    {
                        title: "Action",
                        createdCell: function (td) {
                            td.classList.add('py-2');
                        },
                        render: function (data, type, row) {
                            return `<button class="btn btn-light leaveDetails" 
                                data-id="${row[5]}" 
                                data-leave_tokens="${row[6]}">Details</button>`;
                        }
                    },
                ],
                autoWidth: false,
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }

        // Update the wrapper element class based on the existence of buttons
        const wrapperElement = document.getElementById("mainDatatableLeaveList_wrapper");
        if (wrapperElement) {
            const hasButtons = document.getElementsByClassName("dt-buttons").length > 0;
            wrapperElement.classList.toggle("dtl-right", hasButtons);
        }

        // Add click event listener for leaveDetails button
        const tableBody = document.querySelector('#mainDatatableLeaveList tbody');
        const handleRowClick = (event) => {
            if (event.target && event.target.classList.contains('leaveDetails')) {
                const leaveId = event.target.getAttribute('data-id');
                const token = event.target.getAttribute('data-leave_tokens');
                navigate(`/leavedata/${leaveId}/${token}`);
            }
        };

        if (tableBody) {
            tableBody.addEventListener('click', handleRowClick);
        }

        // Cleanup event listener on unmount
        return () => {
            if (tableBody) {
                tableBody.removeEventListener('click', handleRowClick);
            }
        };
    }, [adminLeaveData]);


    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('#mainDatatableLeave')) {
            $('#mainDatatableLeave').DataTable({
                dom: "Bflrtip",
                pageLength: 25,
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-outline-secondary',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon me-1"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
                        filename: 'myRetails_Leave', // Set the filename for the Excel export
                        title: 'myRetails Leave' // Remove title from the Excel sheet if not needed
                    }
                ],
                data: leaveData.map((data, i) => [
                    data.leave_employee_name,
                    data.leave_type,
                    `${data.leave_start_date} to ${data.leave_end_date}`,
                    data.leave_total_days,
                    data.leave_status,
                    data.id
                ]),
                columns: [
                    { title: "Employee Name" },
                    { title: "Leave Type" },
                    { title: "Leave Period" },
                    { title: "Days/Hours Taken" },
                    {
                        title: "Leave Status",
                        render: function (data, type, row) {
                            let badgeClass = '';
                            if (data === 'Pending') {
                                badgeClass = 'badge bg-soft-warning text-dark'; // Warning badge
                            } else if (data === 'Approved') {
                                badgeClass = 'badge bg-soft-success'; // Success badge
                            } else if (data === 'Rejected') {
                                badgeClass = 'badge bg-soft-danger'; // Danger badge
                            }
                            return `<span class="${badgeClass}">${data}</span>`;
                        }
                    },
                    {
                        title: "Action",
                        createdCell: function (td) {
                            td.classList.add('py-2');
                        },
                        render: function (data, type, row) {
                            const leaveStatus = row[4]; // Assuming 'Leave Status' is in the 4th column
                            const isDisabled = leaveStatus === "Approved" || leaveStatus === "Rejected";

                            return `
                              <div class="d-flex gap-2">
                                  <button class="btn btn-soft-info p-0 table-btn lkr-edit" data-id="${row[5]}" ${isDisabled ? "disabled" : ""}>
                                      <div class="avatar avatar-xs lkr-edit" data-id="${row[5]}">
                                          <svg viewBox="0 0 24 24" data-id="${row[5]}" role="presentation" class="avatar-icon lkr-edit">
                                              <path d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z" style="fill: currentcolor;"></path>
                                          </svg>
                                      </div>
                                  </button>
                                  <button class="btn btn-soft-danger p-0 table-btn lkr-delete" data-id="${row[5]}" ${isDisabled ? "disabled" : ""}>
                                      <div class="avatar avatar-xs lkr-delete" data-id="${row[5]}">
                                          <svg viewBox="0 0 24 24" data-id="${row[5]}" role="presentation" class="avatar-icon lkr-delete">
                                              <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" style="fill: currentcolor;"></path>
                                          </svg>
                                      </div>
                                  </button>
                              </div>`;
                        }
                    },
                ],
                autoWidth: false,
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }

        if (document.getElementsByClassName("dt-buttons").length) {
            document.getElementById("mainDatatableLeave_wrapper").classList.add("dtl-right");
        } else {
            document.getElementById("mainDatatableLeave_wrapper").classList.remove("dtl-right");
        }
        document.querySelector('#mainDatatableLeave tbody').addEventListener('click', function (event) {
            if (event.target && event.target.classList.contains('lkr-edit')) {
                const pi_id = event.target.getAttribute('data-id');
                handleEditClick(pi_id);
            }
        });
        document.querySelector('#mainDatatableLeave tbody').addEventListener('click', function (event) {
            if (event.target && event.target.classList.contains('lkr-delete')) {
                const pi_id = event.target.getAttribute('data-id');
                handleDeleteLeave(pi_id);
            }
        });
    }, [leaveData]);


    const [leaveApprovalModalShow, setLeaveApprovalModalShow] = useState(false);
    const [leaveId, setLeaveId] = useState(null);
    const handleSHowLeaveApprovalModal = (leave_id) => {
        setLeaveId(leave_id);
        setLeaveApprovalModalShow(true);
    }
    const handleLeaveApprovalModalClose = () => setLeaveApprovalModalShow(false);

    const leaveTableHeader = [];
    // TODO: handle this table data on changes of leave data
    const generateDateRange = useCallback(() => {
        const startDate = new Date(data.leaveStartDate);
        const endDate = new Date(data.leaveEndDate);
        const dates = [];
        let currentDate = new Date(startDate);

        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        // Ensure leave_part_selection matches the number of dates
        if (dates.length !== data.leave_part_selection.length) {
            setData((prevData) => {
                const updatedLeavePartSelection = [...prevData.leave_part_selection];
                while (updatedLeavePartSelection.length < dates.length) {
                    updatedLeavePartSelection.push(null);
                }
                return {
                    ...prevData,
                    leave_part_selection: updatedLeavePartSelection.slice(0, dates.length), // Trim to match date range
                };
            });
        }

        return dates;
    }, [data.leaveStartDate, data.leaveEndDate, data.leave_part_selection]); // Add leave_part_selection to the dependency array


    const handleInputChange = (index, field, value) => {

        if (field === "leave_part_selection") {
            setData((prevData) => {
                const updatedLeavePartSelection = [...prevData.leave_part_selection];
                updatedLeavePartSelection[index] = value;

                // Ensure the length of leave_part_selection matches the number of dates
                const numDates = generateDateRange().length;
                while (updatedLeavePartSelection.length < numDates) {
                    updatedLeavePartSelection.push(null); // Add null values for additional dates
                }

                return {
                    ...prevData,
                    leave_part_selection: updatedLeavePartSelection,
                };
            });
        } else {
            setData((prevData) => ({
                ...prevData,
                [field]: value,
            }));
        }
    };

    const leaveTableRowData = generateDateRange().map((date, index) => {
        // Determine the selected leave type for the current index
        const leavePartSelection = data.leave_part_selection[index];

        // Set total hours based on the selected leave type
        let totalHours = '';
        if (leavePartSelection === 'Full Day') {
            totalHours = '8 Hours';
        } else if (leavePartSelection === 'First Half' || leavePartSelection === 'Second Half') {
            totalHours = '4 Hours';
        } else {
            totalHours = '8 Hours';
        }

        return {
            startDate: date.toLocaleDateString(),
            leaveType: (
                <SelectElement
                    handleOnChange={(e) => {
                        handleInputChange(index, "leave_part_selection", e.target.value);
                    }}
                    selectName={`leaveType_${index}`}
                    selectId={`leaveType_${index}`}
                    selectValue={data.leave_part_selection[index] || ''} // Preselect the value
                    selectOptions={[
                        { value: 'Full Day', label: 'Full Day' },
                        { value: 'First Half', label: 'First Half' },
                        { value: 'Second Half', label: 'Second Half' },
                    ]}
                />

            ),
            totalHours, // Add total hours for the current row
        };
    });

    // Function to calculate the sum of total hours from all rows
    const calculateTotalHours = () => {
        return leaveTableRowData.reduce((total, row) => {
            // Extract numeric value of totalHours (e.g., '8 Hours' -> 8)
            const hours = row.totalHours.split(' ')[0]; // Assuming 'X Hours' format
            return total + parseInt(hours, 10); // Sum up the total hours
        }, 0);
    };

    // Calculate total days and update the state
    useEffect(() => {
        const totalHours = calculateTotalHours();
        const totalDays = (totalHours / 8).toFixed(1);

        // Update the `data` state with the calculated total days
        setData((prevData) => ({
            ...prevData,
            leaveTotalDays: totalDays,
        }));
    }, [data.leave_part_selection]); // Run this effect whenever `leaveTableRowData` changes

    const rows = [
        {
            columns: [
                { size: { lg: 12 }, label: 'Employee Name', type: 'text', name: 'leaveEmployeeName', id: 'leaveEmployeeName', placeholder: 'Enter title...', value: data.leaveEmployeeName, errormsg: '', readOnly: true, disabled: true },
                {
                    size: { md: 12 }, label: 'Leave Type', type: 'select', name: 'leaveType', id: 'leaveType', value: data.leaveType, errormsg: '',
                    options: [
                        { value: "select", label: "Select Leave Type" },
                        { value: "Casual Leave", label: "Casual Leave" },
                        { value: "Paid Leave", label: "Paid Leave" },
                        { value: "Sick Leave", label: "Sick Leave" }
                    ],
                },
                { size: { md: 12 }, label: 'Leave Description', type: 'textarea', name: 'leaveDes', formrow: '3', id: 'leaveDes', placeholder: 'Enter massage...', value: data.leaveDes, errormsg: '' },
                {
                    size: { sm: 6, lg: 4 },
                    label: 'Start Date',
                    type: 'date',
                    name: 'leaveStartDate',
                    id: 'leaveStartDate',
                    placeholder: 'DD/MM/YYYY',
                    value: data.leaveStartDate,
                    max: '9999-12-31', // Restricting year to 4 digits
                    errormsg: '',
                },
                { size: { sm: 6, lg: 4 }, label: 'End Date', type: 'date', name: 'leaveEndDate', id: 'leaveEndDate', placeholder: 'DD/MM/YYYY', value: data.leaveEndDate, errormsg: '', },
                {
                    size: { lg: 4 },
                    label: "Total Days",
                    type: "text",
                    name: "leaveTotalDays",
                    id: "leaveTotalDays",
                    placeholder: 'Total Days',
                    value: data.leaveTotalDays, // Display calculated total days
                    errormsg: "",
                    readOnly: true, // Make this field read-only as it's auto-calculated
                    disabled: true,
                },
                { type: 'table', tableSize: 'sm', thead: leaveTableHeader, tbodydata: leaveTableRowData, }
            ]
        }
    ];
    const Buttons = [
        { variant: "primary", text: "Ok", onClick: handleBothActions },
        { variant: "secondary", text: "Cancel", onClick: handleFormModalClose },
    ]


    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col xs={6} lg={3}>
                                <Card className='border border-success bg-soft-success text-center'>
                                    <Card.Body className='p-4'>
                                        <h3 className='mb-3 fw-normal text-success'>Annual Leave</h3>
                                        <h4 className='mb-2 fw-normal'>{annualLeaveDays}/30</h4>
                                        <span className='d-inline-block py-1 px-3 rounded-1 bg-success fw-normal fs-14 text-white'>Available</span>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} lg={3}>
                                <Card className='border border-info bg-soft-info text-center'>
                                    <Card.Body className='p-4'>
                                        <h3 className='mb-3 fw-normal text-info'>Casual Leave</h3>
                                        <h4 className='mb-2 fw-normal'>{casualLeaveDays}/10</h4>
                                        <span className='d-inline-block py-1 px-3 rounded-1 bg-info fw-normal fs-14 text-white'>Available</span>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} lg={3}>
                                <Card className='border border-warning bg-soft-warning text-center'>
                                    <Card.Body className='p-4'>
                                        <h3 className='mb-3 fw-normal text-warning'>Paid Leave</h3>
                                        <h4 className='mb-2 fw-normal'>{paidLeaveDays}/10</h4>
                                        <span className='d-inline-block py-1 px-3 rounded-1 bg-warning fw-normal fs-14 text-white'>Available</span>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={6} lg={3}>
                                <Card className='border border-danger bg-soft-danger text-center'>
                                    <Card.Body className='p-4'>
                                        <h3 className='mb-3 fw-normal text-danger'>Sick Leave</h3>
                                        <h4 className='mb-2 fw-normal'>{sickLeaveDays}/10</h4>
                                        <span className='d-inline-block py-1 px-3 rounded-1 bg-danger fw-normal fs-14 text-white'>Available</span>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <div className='d-flex align-items-center justify-content-between mb-4'>
                                            <Card.Title className='mb-0'>Leave</Card.Title>
                                            <Button variant="light mb-sm-0 mb-3" onClick={handleLeaveModal}>
                                                <Icon path={mdiPlus} className="btn-icon me-1" /> Add Leave
                                            </Button>
                                        </div>
                                        <Table responsive bordered className='table-nowrap' id='mainDatatableLeave'>
                                            <thead className='table-light'></thead>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        {isAdmin == 1 && (
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <Card.Title >Leave List</Card.Title>
                                            <Table responsive bordered className='table-nowrap' id='mainDatatableLeaveList'>
                                                <thead className='table-light'></thead>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                    </Container>
                </div>
                <Footer />
            </div>

            {/* From Modal */}
            <FormModal title={edit ? "Edit Leave" : "Add Leave"} modalSize="lg" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleBothActions} />

            <LeaveApproval leaveId={leaveId} leaveApprovalModalShow={leaveApprovalModalShow} handleFormModalClose={handleLeaveApprovalModalClose} />
        </>
    )
}
