import React, { useState,useContext,useEffect } from 'react';
import { Card, Button, Table } from 'react-bootstrap';
import ServiceCategoryData from "./service-category-data.json";
// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import FormModal from '../../../Utils/FormModal';
import {ServiceModeProvider,useServiceCategory} from './getServiceCategory';
import { toast } from "react-toastify";
import {serviceCategoryContext } from './serviceCategoryContext'
import { deleteFromAPI, getFromAPI, putToAPI, showAlert,formatISODateWithTimezone,handleDateFormat } from '../../../Utils/utils';
import messages from "../../../Utils/lang";

export default function ViewServiceCategory({setServiceCategoryId}) {
  // table data
  const [serviceCategoryTableData] = useState(ServiceCategoryData);
  const selectedCategoryId = useContext(serviceCategoryContext)
  const {serviceModeData,fetchServiceCategory} = useServiceCategory();
  const [tableData, setTableData] = useState([]); // Initialize state with an empty array
  const created_branch_id1 = localStorage.getItem('createdBranchId');

  // input value
  const [data, setData] = useState({
        name: "",
        description: "",
        created_branch_id: created_branch_id1,
        created_user_id: created_branch_id1,
        last_modified_user_id: created_branch_id1,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isdeleted: 0,
        reserve_varchar1: "",
        reserve_varchar2: "",
        reserve_float1: 0.0,
        reserve_float2: 0.0,
        reserve_int1: 0,
        reserve_int2: 0,
        reserve_double1: "0",
        reserve_date1: handleDateFormat(formatISODateWithTimezone(new Date()))
  });

  useEffect(() => {
    const fetchServiceDetails = async () => {
      try{
        const response = await getFromAPI(`FtsServiceCategory/?id=${selectedCategoryId}`);
        if (response && response.length > 0) {
          const fetchedData = response[0];
          setData({
            name : fetchedData.name,
            description: fetchedData.description
          });
      }
    } catch (error) {
      toast.error('Error fetching service category details:', error);
    }
  };
  if(selectedCategoryId){
    fetchServiceDetails();
  }
},[selectedCategoryId]);

const fetchServiceTable = async () => {
  try {
    const response = await getFromAPI(`FtsServiceCategory/?id=${selectedCategoryId}`);
    const mappedData = [
      { tableheading1: "Name :", tablesubtext1: response[0].name },
      { tableheading1: "Description :", tablesubtext1: response[0].description },
      { tableheading1: "Created Date Time :",tablesubtext1:response[0].created_date_time}
    ]
    setTableData(mappedData);
  }catch(error) {
    return null;
  }  
};

useEffect(()=> {
  fetchServiceTable();
  fetchServiceCategory();
},[selectedCategoryId])

const handleDeleteService = async (itemId) => {
  try {
    const response = await deleteFromAPI(`FtsServiceCategory/${itemId}/`);
    if (response) {
      toast.success(messages.serviceCategory.deleted_successfully);
      fetchServiceTable();
      fetchServiceCategory();
    }
    if (serviceModeData.length > 1) {
      setServiceCategoryId(serviceModeData[0].id);
    } else {
      setServiceCategoryId('');
    }
  } catch (e) {
    toast.error(`Error deleting service`);
  }
};

const handleDeleteServiceCategory = async(Id) => {
  try{
     showAlert('Are you sure you want to delete this service category?','confirm',(result) => {
      if(result){
        handleDeleteService(Id);
      }
      else {
        // User canceled the deletion
        // You can handle this case if needed
      }
     });
  } catch(e)
  {
    toast.error('Error deleting service category:', e);
  }
};

const handleSaveChanges = async () => {
  // Validate name field
  if (!data.name.trim()) {
    toast.error(messages.serviceCategory.required_service_category);
    return;
  }

  if (!data.description.trim()) {
    toast.error(messages.serviceCategory.required_description);
    return;
  }

  // Check if the service category already exists
  const isExist = 
    Array.isArray(serviceModeData) &&
    serviceModeData.some(
      (serviceMode) => 
        serviceMode.name.trim() === data.name.trim() && 
        serviceMode.id !== selectedCategoryId
    );

  if (isExist) {
    toast.error(messages.serviceCategory.already_exist);
    return;
  }

  try {
    // Update the service category
    await putToAPI(`FtsServiceCategory/${selectedCategoryId}/`, data);
    toast.success(messages.serviceCategory.updated_successfully);
    
    // Close the modal and refresh the data
    handleFormModalClose();
    fetchServiceCategory();
    fetchServiceTable();
  } catch (e) {
    // Handle API error
    toast.error(`Failed to update service category details:`);
  }
};

const onChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }
  // Edit modal
  const [formModalShow, setShowFormModal] = useState(false);
  const handleServCategoryEdit = () => setShowFormModal(true);
  const handleFormModalClose = () => setShowFormModal(false);

  const handleInputChange = (index, field, value) => {
    setData({ ...data, [field]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setShowFormModal(false);
  };

  const rows = [
    {
      columns: [
        {
          size: { lg: 12 },
          label: 'Service Category Name',
          type: 'text',
          name: 'name',
          id: 'name',
          placeholder: 'Enter service category name...',
          value: data.name,
          onChange: (e) =>
            setData((prevState) => ({
              ...prevState,
              name: e.target.value,
            })),
          errormsg: '',
        },
        {
          size: { lg: 12 },
          label: 'Service Description',
          type: 'textarea',
          rows: 4,
          name: 'description',
          id: 'description',
          placeholder: 'Service category description...',
          value: data.description,
          onChange: (e) =>
            setData((prevState) => ({
              ...prevState,
              name: e.target.value,
            })),
          errormsg: '',
        },
      ],
    },
  ];
  
  const Buttons = [
    { variant: "primary", text: "Update", onClick: handleSaveChanges },
    { variant: "secondary", text: "Cancel", onClick: handleFormModalClose },
  ]
  
  return (
    <>
      <Card className='right-content-details'>
        <Card.Body>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <Card.Title className='mb-0'>View Service Category</Card.Title>
            <div className='d-flex'>
              <div className="btn-list">
                <Button variant="soft-info p-0" onClick={handleServCategoryEdit}>
                  <div className='avatar avatar-sm'>
                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                  </div>
                </Button>{' '}
                <Button variant="soft-danger p-0" onClick={()=>handleDeleteServiceCategory(selectedCategoryId)}>
                  <div className='avatar avatar-sm'>
                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                  </div>
                </Button>{' '}
              </div>
            </div>
          </div>
          <Table responsive className='table-lg border table-nowrap mb-0'>
            <tbody>
              {tableData.map((data, i) =>
                <tr key={i}>
                  <th>{data.tableheading1}</th>
                  <td>{data.tablesubtext1}</td>
                  <th>{data.tableheading2}</th>
                  <td>{data.tablesubtext2}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* From Modal */}
      <FormModal title="Edit Service Category" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
    </>
  )
}
