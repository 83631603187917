import React, { useState, useEffect } from 'react';
import Footer from '../layout/Footer';
import ViewAccount from './ViewAccount';
import NewAccount from './NewAccount';
import { Container, Row, Col, Card, Form, Nav } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import { getFromAPI, showAlert } from '../../Utils/utils';
import Icon from '@mdi/react';
import { mdiMagnify, mdiAccountDetails, mdiPlus } from '@mdi/js';
import { AccountContext1 } from './accountContext';
import { AccountProvider, useAccount } from './getAccountData';

const Accounts = () => {
    const [loading, setLoading] = useState(true);
    const { getAccountData, fetchAccountData } = useAccount();
    const [selectedAccountId, setSelectedAccountId] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [showAccount, setShowAccount] = useState(false);
    const [key, setKey] = React.useState(0);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        fetchAccountData();
    }, [selectedAccountId]);

    // Filter and sort accounts in ascending order
    const filteredAccount = getAccountData
        .filter((admin) => admin.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name)); // Sort by name

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        if (searchTerm && filteredAccount.length === 0) {
            setErrorMessage('No account found.');
        } else {
            setErrorMessage('');
        }
    }, [searchTerm, filteredAccount]);

    useEffect(() => {
        const sidebarItems = document.querySelectorAll(".left-link-item");

        sidebarItems.forEach((item) => {
            item.addEventListener('click', function () {
                const activeLink = document.querySelector(".left-link-item a.active");
                if (activeLink) activeLink.classList.remove("active");

                const link = this.querySelector('a');
                if (link) link.classList.add('active');

                const BranchId = this.getAttribute('data-id');
                setSelectedAccountId(BranchId);
                setShowAccount(false);

                const viewAccountTab = document.getElementById("pills-view-account-tab");
                if (viewAccountTab) {
                    viewAccountTab.click();
                }
            });
        });

        return () => {
            sidebarItems.forEach((item) => {
                item.removeEventListener('click', () => {});
            });
        };
    }, [filteredAccount]);

    useEffect(() => {
        if (getAccountData.length > 0 && (!selectedAccountId || !getAccountData.some((c) => c.id === selectedAccountId))) {
            setSelectedAccountId(filteredAccount[0].id);
        }
    }, [getAccountData]);

    const handleNewAccountSuccess = (newAccountId) => {
        fetchAccountData();
        setSelectedAccountId(newAccountId);
        setShowAccount(false);

        const viewTab = document.getElementById("pills-view-account-tab");
        if (viewTab) viewTab.click();
    };

    const handleNewProductClick = () => {
        setShowAccount(true);
        setKey((prevKey) => prevKey + 1);
    };

    return (
        <AccountContext1.Provider value={selectedAccountId}>
            <div className="page-wrapper">
                <div className="page-content">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-md-flex justify-content-between align-items-center">
                                            <Form className="custom-form-width">
                                                <div className="custom-search-bar">
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search account..."
                                                        name="accountSearch"
                                                        id="accountSearch"
                                                        onChange={handleSearchChange}
                                                    />
                                                </div>
                                            </Form>
                                            <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={`nav-link ${!showAccount ? 'active' : ''}`}
                                                            id="pills-view-account-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-view-account"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-view-account"
                                                            aria-selected="true"
                                                            onClick={() => setShowAccount(false)}
                                                        >
                                                            <Icon path={mdiAccountDetails} className="btn-icon me-1" />
                                                            View Account
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={`nav-link ${showAccount ? 'active' : ''}`}
                                                            id="pills-new-account-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-new-account"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-new-account"
                                                            aria-selected="false"
                                                            onClick={handleNewProductClick}
                                                        >
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />
                                                            New Account
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} xxl={3}>
                                <Card className="procust-left-list leftbar-list">
                                    <SimpleBarReact>
                                        <Card.Body>
                                        <div className="left-list-bar">
                                                {errorMessage ? (
                                                    <p className="text-danger text-center">{errorMessage}</p>
                                                ) : (
                                                    filteredAccount.map((data, i) => (
                                                        <Nav.Item
                                                            className="left-link-item"
                                                            key={i}
                                                            data-id={data.id}
                                                            onClick={() => setSelectedAccountId(data.id)}
                                                        >
                                                            <Nav.Link className={data.id === selectedAccountId ? 'active' : ''}>
                                                                <h5 className="product-tab-title">{data.name}</h5>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ))
                                                )}
                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>
                            <Col lg={8} xxl={9}>
                                <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-view-account"
                                        role="tabpanel"
                                        aria-labelledby="pills-view-account-tab"
                                        tabIndex="0"
                                    >
                                        <ViewAccount setSelectedAccountId={setSelectedAccountId} />
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-new-account"
                                        role="tabpanel"
                                        aria-labelledby="pills-new-account-tab"
                                        tabIndex="0"
                                    >
                                        <NewAccount key={key} onSuccess={handleNewAccountSuccess} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </AccountContext1.Provider>
    );
};

export default function CustomersWithProvider() {
    return (
        <AccountProvider>
            <Accounts />
        </AccountProvider>
    );
}
