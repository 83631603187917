import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, Form, Button, InputGroup, DropdownButton, Dropdown, Modal } from 'react-bootstrap';
import { postToAPI, getFromAPI,formatISODateWithTimezone } from '../../Utils/utils';
import { toast, ToastContainer } from 'react-toastify';
import { fetchTaxData, fetchProductCategoryData, fetchUnitData, fetchBrandData, fetchVendorData } from '../../Utils/function_list';
// react-select
import Select from 'react-select';
import { CustomerProvider, useProduct } from './getProductData';
import QuickVendorModal from '../../Utils/QuickVendorModal';

// icon
import Icon from '@mdi/react';
import { mdiPlus, mdiChevronDown } from '@mdi/js';
import { Prev } from 'react-bootstrap/esm/PageItem';
import messages from '../../Utils/lang';

export default function NewProduct({ onSuccess }) {
    const { customerData, fetchproductData1 } = useProduct();
    const [Tax, setTax] = useState([]);
    const [productData, setProductData] = useState([]);
    const [Brand, setBrand] = useState([]);
    const [Unit, setUnit] = useState([]);
    const [productCategory, setProductCategory] = useState([]);
    const [Branch, setBranch] = useState([]);
    const [vendor, setVendorData] = useState([]);
    const [productVisibility1, setProductVisibility1] = useState(null); // New state for fetched value

    // Fetch the option data to determine visibility
      const fetchConsumbleRetailData = async () => {
        try {
            const response = await getFromAPI('FtsOptions/?name=product_consumable_stock');
            if (response[0]?.value) setProductVisibility1(response[0].value); // Store the fetched value in state
        } catch (error) {
            return null;
        }
    };

    useEffect(() => {
        fetchConsumbleRetailData();
    }, []);

    // Default Modal
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        setproductCategoryInoutData(initialProductData);
    }
    const handleShow = () => setShow(true);
    const [unitShow, setUnitShow] = useState(false)
    const handleUnitClose = () => {
        setUnitShow(false);
        setUnitInputData(initialUnitData);
    }
    const handleUnitShow = () => setUnitShow(true)

    const BranchRef = useRef(null);

    const initialInputData = {
        description: '',
        type: "2",
        rate: 0.0,
        group_id: 0,
        product_code: '',
        product_category_id: 0,
        vendor_id: 0,
        weight: 1,
        unit_id: 0,
        product_purchase_price: 0.0,
        product_minimum_sell_price: 0.0,
        maximum_retail_price: 0.0,
        product_mu_percentage: 0,
        long_description: "",
        rating_id: 0,
        hsn_code: '',
        brand_id: 0,
        image: '',
        barcode_type: '',
        barcode_number: '',
        license_number: '',
        isservice: 0,
        minimum_consumption: 0.0,
        ismeasurable: 0,
        has_sub_product: 0,
        isproductimported: 0,
        iscoldstorage_require: 0,
        isonline: 0,
        created_branch_id: 1,
        created_user_id: 1,
        last_modified_user_id: 1,
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isdeleted: 0,
        reserve_varchar1: "",
        reserve_varchar2: "",
        reserve_float1: 0.0,
        reserve_float2: 0.0,
        reserve_int1: 0,
        reserve_int2: 0,
        reserve_double1: 0.0,
        branches: [],
        product_sell_price: 0,
        discount_value: 0,
        discount_type: 0,
        tax_value: 0,
        tax_type: 0
    };

    const initialWarehouseInputData = {
        opening_consumable_qty: 0,
        opening_retail_qty: 0,
        alert_consumable_quantity: 0,
        alert_retail_quantity: 0
    }

    const initialUnitData = {
        name: '',
        isdeleted: 0
    };

    const initialProductData = {
        name: '',
        description: '',
        created_branch_id: 1,
        created_user_id: 1,
        last_modfied_user_id: 1,
        isdeleted: 0,
        created_date_time: formatISODateWithTimezone(new Date()), 
        last_modified_date_time: formatISODateWithTimezone(new Date())
    };

    const handleCancel = () => {
        setInputData(initialInputData);
        BranchRef.current.clearValue();
        setwarehouseProductInputdata(initialWarehouseInputData);
        setproductCategoryInoutData(initialProductData);
        setUnitInputData(initialUnitData);
    }
    const [productCategoryInputData, setproductCategoryInoutData] = useState(initialProductData);
    const [warehouseProductInputdata, setwarehouseProductInputdata] = useState(initialWarehouseInputData);
    const [inputData, setInputData] = useState(initialInputData);
    const [unitInputData, setUnitInputData] = useState(initialUnitData);

    useEffect(() => {
        fetchTaxData(setTax);
        fetchBrandData(setBrand);
        fetchUnitData(setUnit);
        fetchVendorData(setVendorData)
        fetchProductCategoryData(setProductCategory);
    }, []);

  
    const fetchBranchData = async() => {
        try{
            const response = await getFromAPI('FtsBranch/');
            const branches = response.map(branch => ({ value: branch.id, label: branch.name }));
            setBranch(branches);
        }
        catch(error){
            return null;
        }
    };

    useEffect(()=>{
       fetchBranchData();
    },[])  
    
    // Check if all required fields are filled
      const isFormValid = () => {
        return (
                inputData.description &&
                inputData.type 
        );
    };

    const handleProductSubmit = async (e) => {
        e.preventDefault();
        if (isFormValid()) {

        // Validate input fields
        if (!inputData.description.trim()) {
            toast.error(messages.product.required_name);
            return;
        }
    
        if (inputData.type === '') {
            toast.error(messages.product.required_type);
            return;
        }

        if (inputData.maximum_retail_price === ''){
            toast.error(messages.product.required_maximum_retail_price);
            return;
        }
   
        if (inputData.product_purchase_price === '') {
            toast.error(messages.product.required_product_purchase_price);
            return;
        }

        if (inputData.product_mu_percentage === ''){
            toast.error(messages.product.required_product_mu_percentage);
            return;
        }

        if (inputData.branches.length === 0)
        {
            toast.error(messages.product.required_branches);
            return;
        }

        const existingProductResponse = await getFromAPI(`FtsItems/?description=${encodeURIComponent(inputData.description)}&isdeleted=0`);
            
        if (existingProductResponse.length>0) {
                toast.error(messages.product.already_exist);
                return;
        }
       
        // Update the input data
        const updatedInputData = {
            ...inputData,
            branches: inputData.branches.map(branch => branch.value).join(',')
        };
    
        // TODO: Implement logic to check the role of the user adding the product (e.g., super admin, admin, branch)
    
        try {
            // Prepare the final data object for API submission
            const finalProductInputData = { ...updatedInputData, warehouseData: warehouseProductInputdata };
    
            // Post data to the API
            const response = await postToAPI("FtsItems/", finalProductInputData);
    
            if (response && response.status) {
                toast.success(messages.product.added_successfully);
                fetchproductData1();
                setInputData(initialInputData);
                BranchRef.current.clearValue();
                setwarehouseProductInputdata(initialWarehouseInputData);
                if (onSuccess && response.data.id) {
                    onSuccess(response.data.id); // Call the callback with new customer ID
                }
                setUnitInputData(initialUnitData);
            } else {
                toast.error(messages.product.failed_to_add);
            }
        } catch (error) {
            return null;
        }
    }
    };
    
    const handleUnitInputChange = (e) => {
        const { value } = e.target;
        
        // Use regex to allow only numbers
            setUnitInputData((prevData) => ({
                ...prevData,
                name: value,
            }));
    };
    

    const handleAddUnit = async () => {
        if(!unitInputData.name.trim()){
            toast.error(messages.unit.required_unit_name);
            return;
        }
        try {
            // Post the data to the API
            const response = await postToAPI("FtsUnit/", unitInputData);

            if (response.status) {
                toast.success(messages.unit.added_successfully, 'success');
                handleUnitClose();
                fetchUnitData();
            } else {
                toast.error(messages.unit.already_exist, 'error');
            }
        } catch (error) {
            toast.error(messages.unit.already_exist, 'error');
        }
    };

    const handleAddProductCategory = async () => {

        if (!productCategoryInputData.name.trim()) {
            toast.error(messages.product_category.required_category_name, 'error');
            return;
        }

        const isExists = productCategory.find(category => category.name === productCategory.name)
        if (isExists) {
            toast.error(messages.product_category.already_exist, 'error');
            return;
        }
        try {
            // Post the data to the API
            const response = await postToAPI("FtsProductCategory/", productCategoryInputData);

            if (response.status) {
                toast.success(messages.product_category.added_successfully, 'success');
                handleClose();
                fetchProductCategoryData(setProductCategory);

            } else {
                toast.error(messages.product_category.failed_to_add, 'error');
            }
        } catch (error) {
            toast.error(messages.product_category.failed_to_add, 'error');
        }
    };

    const handleCheckboxChange = (e) => {
        setInputData({ ...inputData, isonline: e.target.checked });
    };

    // Define the handleSelect function here
    const handleSelect = (discount) => {
        setInputData((prevState) => ({
            ...prevState,
            reserve_int1: discount, // Update with the selected discount in reserve_int1
        }));
    };

    // Handle multi-select changes
    const handleSelectChange = (selectedOptions) => {
        setInputData(prevInputData => ({
        ...prevInputData,
        branches: selectedOptions,
        }));
    };  
    const [vendorModalShow, setVendorModalShow] = useState(false);
    const handleShowVendor = () => setVendorModalShow(true);
    const handleCloseVendor = () => setVendorModalShow(false);

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <Form>
                        <Card.Title>Add Product</Card.Title>

                        <Row>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Product Name <span className='text-danger'>*</span> </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name='productName'
                                        id='productName'
                                        autocomplete = 'off'
                                        placeholder="Enter Product Name..."
                                        value={inputData.description}
                                        onChange={e => setInputData({ ...inputData, description: e.target.value })}
                                        
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Product Type <span className='text-danger'>*</span> </Form.Label>
                                    <Form.Select
                                        
                                        name='productType'
                                        id='productType'
                                        value={inputData.type}
                                        onChange={e => setInputData({ ...inputData, type: e.target.value })}
                                    >
                                        <option value="2">Retail</option>
                                        {productVisibility1 === '1' && (
                                        <>
                                            <option value="0">Both (Consumable and Retail)</option>
                                            <option value="1">Consumable</option>
                                        </>
                                        )}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Product Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter code..."
                                        name='productCode'
                                        id='productCode'
                                        value={inputData.product_code}
                                        onChange={e => setInputData({ ...inputData, product_code: e.target.value })}
                                        disabled={true}
                                    />
                                </div>
                            </Col>
                            <Col sm={6} md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>HSN</Form.Label>
                                    <Form.Control type="text"
                                        placeholder="Enter HSN..."
                                        name='productHsn'
                                        id='productHsn'
                                        autocomplete = 'off'
                                        value={inputData.hsn_code}
                                        onChange={e => setInputData({ ...inputData, hsn_code: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Brand</Form.Label>
                                    <Form.Select
                                        
                                        name='productBrand'
                                        id='productBrand'
                                        value={inputData.brand_id}
                                        onChange={e => setInputData({ ...inputData, brand_id: parseInt(e.target.value) })}>
                                        <option value="">Select Brand</option>
                                        {Brand.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}

                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Product Category</Form.Label>
                                        <Form.Select
                                            
                                            name='productCategory'
                                            id='productCategory'
                                            value={inputData.product_category_id}
                                            onChange={e => setInputData({ ...inputData, product_category_id: parseInt(e.target.value) })}>
                                            <option value="">Select Product Category</option>
                                            {productCategory.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>

                                    <Button variant="light custom-form-input-btn ms-2 mt-2" onClick={handleShow}>
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                    <Modal centered show={show} onHide={handleClose}>
                                        <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                                        <Modal.Body>
                                            <div className="mb-3 custom-form-input">

                                                <Form.Label>Add Product Category</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter product category..."
                                                    name='addProductCategory'
                                                    id='addProductCategory'
                                                    value={productCategoryInputData.name}
                                                    onChange={e => setproductCategoryInoutData({ ...productCategoryInputData, name: e.target.value })}
                                                />
                                            </div>
                                            <div className="mb-3 custom-form-input">
                                                <Form.Label>Add Product Description</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter product Description..."
                                                    name='addProductDescription'
                                                    id='addProductDescription'
                                                    value={productCategoryInputData.description}
                                                    onChange={e => setproductCategoryInoutData({ ...productCategoryInputData, description: e.target.value })}
                                                />
                                            </div>
                                            <div className='text-end'>
                                                <Button variant="primary" onClick={handleAddProductCategory}>Add Category</Button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Unit</Form.Label>
                                        <Form.Select
                                            
                                            name='productUnit'
                                            id='productUnit'
                                            value={inputData.unit_id}
                                            onChange={e => setInputData({ ...inputData, unit_id: parseInt(e.target.value) })}>
                                            <option value="">Select Unit</option>
                                            {Unit.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>

                                    <Button variant="light custom-form-input-btn ms-2 mt-2" onClick={handleUnitShow}>
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                    <Modal centered show={unitShow} onHide={handleUnitClose}>
                                        <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                                        <Modal.Body>
                                            <div className="mb-3 custom-form-input">
                                                <Form.Label>Add Unit</Form.Label>
                                                <Form.Control type="text"
                                                    placeholder="Enter Unit..."
                                                    name='addUnit' id='addUnit'
                                                    value={unitInputData.name}
                                                    onChange={handleUnitInputChange}
                                                    
                                                />
                                            </div>
                                            <div className='text-end'>
                                                <Button variant="primary" onClick={handleAddUnit}>Add Unit</Button>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alert Qty (Retail)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Qty..."
                                        name='alertRetail'
                                        id='alertRetail'
                                        value={warehouseProductInputdata.alert_retail_quantity}
                                        onChange={e => setwarehouseProductInputdata({...warehouseProductInputdata, alert_retail_quantity: e.target.value})}
                                    />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alert Qty (Consumable)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter Qty..."
                                        name='alertConsumable'
                                        id='alertConsumable'
                                        value={warehouseProductInputdata.alert_consumable_quantity}
                                        onChange={e => setwarehouseProductInputdata({...warehouseProductInputdata, alert_consumable_quantity: e.target.value})}
                                    />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Selling Price</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter price..."
                                        name='sellingPrice'
                                        id='sellingPrice'
                                        value={inputData.product_sell_price}
                                        onChange={e => setInputData({ ...inputData, product_sell_price: e.target.value })}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Card.Title>Opening Quantity</Card.Title>
                    <Row>
                        {(inputData.type === "0" || inputData.type === "1") && (
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Consumable</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter consumable..."
                                        name='productConsumable'
                                        id='productConsumable'
                                        value={warehouseProductInputdata.opening_consumable_qty}
                                        onChange={e => setwarehouseProductInputdata({
                                            ...warehouseProductInputdata,
                                            opening_consumable_qty: e.target.value
                                        })}
                                    />
                                </div>
                            </Col>
                        )}

                        {(inputData.type === "0" || inputData.type === "2") && (
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Opening Qty (Retail)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter qty..."
                                        name='openingRetail'
                                        id='openingRetail'
                                        value={warehouseProductInputdata.opening_retail_qty}
                                        onChange={e => setwarehouseProductInputdata({
                                            ...warehouseProductInputdata,
                                            opening_retail_qty: e.target.value
                                        })}
                                    />
                                </div>
                            </Col>
                        )}
                    </Row>

                        <Card.Title>Other Details</Card.Title>
                        <Row>
                            <Col sm={6} xxl={3}>
                                <div className="mb-3 d-flex">
                                    <div className='custom-form-input w-100'>
                                        <Form.Label>Vendor</Form.Label>
                                        <Form.Select
                                            
                                            name='productVendors'
                                            id='productVendors'
                                            value={inputData.vendor_id}
                                            onChange={e => setInputData({ ...inputData, vendor_id: parseInt(e.target.value) })}
                                        >
                                            <option value="">Select Vendor</option>
                                            {vendor.map(item => (
                                                <option key={item.id} value={item.id}>{item.company_name}</option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                    <Button variant="light custom-form-input-btn ms-2 mt-2" onClick = {handleShowVendor}>
                                        <Icon path={mdiPlus} size={1} />
                                    </Button>
                                    
                                </div>
                            </Col>
                            <Col sm={6} xxl={3}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>MRP</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter MRP..."
                                    name='productMrp'
                                    id='productMrp'
                                    value={inputData.maximum_retail_price }
                                    onChange={e => setInputData({
                                        ...inputData,
                                        maximum_retail_price: e.target.value === '' ? '' : parseFloat(e.target.value)
                                    })}
                                />
                            </div>
                        </Col>

                            <Col sm={6} xxl={3}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Cost</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter cost..."
                                        name='procuctCost' id='procuctCost'
                                        value={inputData.product_purchase_price}
                                        onChange={e => setInputData({ ...inputData, product_purchase_price: e.target.value === '' ? '': parseFloat(e.target.value) })}

                                    />
                                </div>
                            </Col>
                            <Col sm={6} xxl={3}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>MU</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter MU..."
                                        name='productMu'
                                        id='productMu'
                                        value={inputData.product_mu_percentage}
                                        onChange={e => setInputData({ ...inputData, product_mu_percentage: e.target.value === '' ? '' : parseInt(e.target.value) })}
                                    />
                                </div>
                            </Col>
                          <Col md={4}>
                                <InputGroup className="mb-3 custom-form-input">
                                    <Form.Control
                                        type = "number"
                                        placeholder="Enter discount..."
                                        name="reserve_int1"
                                        id="reserve_int1"
                                        value={inputData.discount_value} 
                                        onChange={e => setInputData({ ...inputData, discount_value: e.target.value })}
                                    />
                                    <Form.Label>Discount</Form.Label>
                                    <DropdownButton
                                        variant="light"
                                        title={
                                            <>
                                                {inputData.discount_type === 0 ? `% Off` : 'Rs. Off'} 
                                                <Icon path={mdiChevronDown} className="drop-arrow ms-1 icon-size-15" />
                                            </>
                                        }
                                        id="input-group-dropdown-2"
                                        align="end"
                                        onSelect={e => setInputData({ ...inputData, discount_type: parseInt(e) })} // Trigger when a dropdown item is selected
                                    >
                                        <Dropdown.Item eventKey="0">% Off</Dropdown.Item>
                                        <Dropdown.Item eventKey="1">Rs. Off</Dropdown.Item>
                                    </DropdownButton>
                                </InputGroup>
                            </Col>
                            <Col sm={6} md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select
                                        name='productTax'
                                        id='productTax'
                                        value={inputData.tax_value}
                                        onChange={e => setInputData({ ...inputData, tax_value: parseInt(e.target.value) })}
                                    >
                                        <option value="">Select Tax</option>
                                        {Tax.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))}

                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} md={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax type</Form.Label>
                                    <Form.Select
                                        name='taxType'
                                        id='taxType'
                                        value={inputData.tax_type}
                                        onChange={e => setInputData({ ...inputData, tax_type: parseInt(e.target.value) })}
                                        >
                                        <option>Select tax type</option>
                                        <option value="1">Exclusive</option>
                                        <option value="2">Inclusive</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label className='z-1'>Branch <span className='text-danger'>*</span></Form.Label>
                                    <Select 
                                       options = {Branch}
                                       name='productBranch' 
                                       isMulti={true} // Ensure isMulti is set to true
                                       ref={BranchRef} 
                                       onChange={handleSelectChange}
                                       value={inputData.branches}
                                       getOptionLabel={(option) => option.label}
                                       getOptionValue={(option) => option.value}
                                    />

                                </div>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter description..."
                                        name='productDescription'
                                        id='productDescription'
                                        value={inputData.long_description}
                                        onChange={e => setInputData({ ...inputData, long_description: e.target.value })}

                                    />
                                </div>
                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check
                                        type="checkbox"
                                        label="Is Online"
                                        name='productOnline'
                                        id='productOnline'
                                        value={inputData.isonline}
                                        onChange={handleCheckboxChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <div className="btn-list text-end">
                                    <Button variant="primary"  onClick={handleProductSubmit} disabled={!isFormValid()}  // Disable the button if form is not valid
                                    >Add Product</Button>
                                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>

                </Card.Body>
            </Card>

         <QuickVendorModal addVendorShow={vendorModalShow} addVendorClose={handleCloseVendor} />

        </>
    )
}
