import React, { useState, useEffect, useContext } from 'react';
import { Card, Table, Button } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';
import { getFromAPI, putToAPI, deleteFromAPI, showAlert, formatISODateWithTimezone } from '../../Utils/utils';
import { toast } from 'react-toastify'; // Ensure react-toastify is installed
import { AccountContext1 } from './accountContext';
import { useAccount } from './getAccountData'; // Import the useCustomer hook
import FormModal from '../../Utils/FormModal';


export default function EditAccount({ setSelectedAccountId }) {
    const initialInputData = {
        name: '',
        account_number: '',
        tax: '',  // Update to an integer
        category_id: '',  // Update to an integer
        acc_gst_number: 'NA',
        state: 0,  // Update to an integer
        description: '',
        opening_balance: '',
        share_of_profit: 'NA',
        account_across_branch: '',  // Update to an integer
        holder_name: 'NA',
        budget: 'NA',
        account_type: 1,  // Update to an integer for Credit (0 for Debit)
        current_balance: 0,
        account_nature: '',  // Update to an integer
        isdefault: 0,
        isactive: 1,
        created_branch_id: 1,
        created_user_id: 1,
        last_modified_user_id: 1,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isdeleted: 0,
    };

    const { getAccountData, fetchAccountData } = useAccount();
    const [accountInputData, setAccountInputData] = useState({ ...initialInputData });
    const selectedAccountId = useContext(AccountContext1);
    const [tableData, setTableData] = useState([]); // Initialize state with an empty array
    const [tax, setTax] = useState([]);
    const [categories, setCategories] = useState([]);  // State to store fetched categories
    const [states, setStates] = useState([]);

    useEffect(() => {
        const fetchAccountDetails = async () => {
            try {
                const response = await getFromAPI(`FtsAccount/?id=${selectedAccountId}`);
                if (response && response.length > 0) {
                    const accountResponse = response[0];

                    setAccountInputData({
                        name: accountResponse.name,
                        account_number: accountResponse.account_number,
                        tax: accountResponse.tax,  // Update to an integer
                        category_id: accountResponse.category_id,  // Update to an integer
                        acc_gst_number: accountResponse.acc_gst_number,
                        description: accountResponse.description,
                        opening_balance: accountResponse.opening_balance,
                        share_of_profit: accountResponse.share_of_profit,
                        account_across_branch: accountResponse.account_across_branch,  // Update to an integer
                        holder_name: accountResponse.holder_name, // Update to an integer
                        budget: accountResponse.budget, // Update to an integer
                        account_type: accountResponse.account_type,  // Update to an integer for Credit (0 for Debit)
                        current_balance: accountResponse.current_balance,
                        account_nature: accountResponse.account_nature,  // Update to an integer
                        isdefault: accountResponse.isdefault,
                        isactive: accountResponse.isactive, // Update to an integer,
                        created_branch_id: accountResponse.created_branch_id,
                        created_user_id: accountResponse.created_user_id, // Update
                        last_modified_user_id: accountResponse.last_modified_user_id,
                        created_date_time: formatISODateWithTimezone(new Date()),
                        last_modified_date_time: formatISODateWithTimezone(new Date()),
                        isdeleted: 0,
                    });

                }
            } catch (error) {
                console.error('Error fetching Account data:', error);
            }
        };

        if (selectedAccountId) {
            fetchAccountDetails();

        }
    }, [selectedAccountId]);

    const fetchAccountTableData = async () => {
        try {

            const response = await getFromAPI(`FtsAccount/?id=${selectedAccountId}`);
            const categoryName = await getAccountCategoryByCode(response[0].category_id);
            let account_accross = '';
            let accountNature = '';
            if (response[0].account_across_branch === 1) {
                account_accross = 'Yes';
            }
            else {
                account_accross = 'No';
            }

            if (response[0].account_nature === 2) {
                accountNature = 'Debit';
            }
            else if (response[0].account_nature === 1) {
                accountNature = 'Credit';
            }
            const mappedData = [
                { accountheading1: "NAME:", accountsubtext1: response[0].name },
                { accountheading1: "HOLDER NAME:", accountsubtext1: response[0].holder_name },
                { accountheading1: "CATEGORY ID :", accountsubtext1: categoryName },
                { accountheading1: "ACCOUNT NUMBER:", accountsubtext1: response[0].account_number },
                { accountheading1: "OPENING BALANCE:", accountsubtext1: response[0].opening_balance },
                { accountheading1: "ACCOUNT TYPE:", accountsubtext1: response[0].account_type },
                { accountheading1: "ACCOUNT NATURE:", accountsubtext1: accountNature },
                { accountheading1: "DESCRIPTION:", accountsubtext1: response[0].description },
                { accountheading1: "ACCOUNT ACROSS BRANCH:", accountsubtext1: account_accross },
                { accountheading1: "SHARE OF PROFIT:", accountsubtext1: response[0].share_of_profit },
                { accountheading1: "BUDGET:", accountsubtext1: response[0].budget },
                { accountheading1: "ACC GST NUMBER:", accountsubtext1: response[0].acc_gst_number },
            ];
            setTableData(mappedData)
        } catch (error) {
            console.error("Error fetching Account Data:", error);
        }
    };

    const handleDelete = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Account?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDeleteBranch(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            console.error('Error deleting Branch:', error);
            toast.error('Error deleting Branch', 'error');
        }
    };

    // Filter and sort accounts in ascending order
    const filteredAccount = getAccountData
    .sort((a, b) => a.name.localeCompare(b.name)); // Sort by name

    const handleDeleteBranch = async (itemId) => {
        try {
            const response = await deleteFromAPI(`FtsAccount/${itemId}/`);
            if (response) {
                toast.success('Account deleted successfully', 'success');
                // Optionally, redirect or update state to reflect deletion
                if (getAccountData.length > 0) {
                    setSelectedAccountId(filteredAccount[0].id)
                }
                else {
                    setSelectedAccountId('')
                }
            }
        } catch (error) {
            toast.error('Account Not Deleted', 'error');
            return null;
        }

        fetchAccountData(); // Update the customer list
    };

    const handleSaveChanges = async () => {


        if (!accountInputData.name.trim()) {
            toast.error("Please Enter Account Name", 'error')
            return;
        }

        if (!accountInputData.account_number.trim()) {
            toast.error("Please Enter Account Number", 'error')
            return;
        } else if (!/^\d+$/.test(accountInputData.account_number.trim())) {
            toast.error("Account Number must be a number", 'error')
            return;
        }

        if (!accountInputData.tax || accountInputData.tax.length <= 0) {
            toast.error("Please Select a Tax", 'error');
            return;
        }
        
        if (!accountInputData.category_id || accountInputData.category_id.length <= 0) {
            toast.error("Please Select a Category", 'error');
            return;
        }
        
        if (!accountInputData.account_across_branch || accountInputData.account_across_branch.length <= 0) {
            toast.error("Please select Account Across Branch", 'error');
            return;
        }
        
        if (accountInputData.account_type !== 0 && accountInputData.account_type !== 1) {
            toast.error("Please select Account Type (Credit/Debit)", 'error')
            return;
        }

        if (!accountInputData.opening_balance) {
            toast.error("Please Enter Opening Balance", 'error')
            return;
        }

        if (!accountInputData.share_of_profit || !accountInputData.share_of_profit.trim()) {
            toast.error("Please Enter Share of Profit", 'error')
            return;
        }

        if (!accountInputData.holder_name.trim()) {
            toast.error("Please Enter Holder Name", 'error')
            return;
        }

        if (!accountInputData.budget.trim()) {
            toast.error("Please Enter Budget", 'error')
            return;
        }

        if(!accountInputData.acc_gst_number || !accountInputData.acc_gst_number.trim()){
            toast.error("Please Enter Account GST Number", 'error')
            return;
        }

        const isExists = Array.isArray(getAccountData) && getAccountData.find(account =>
             account.account_number === accountInputData.account_number
             && account.id!== selectedAccountId
            );

        if(isExists)
        {
            toast.error("Account Number already exists", 'error')
            return;
        }

        try {
            // Update the branch data with the new values
            await putToAPI(`FtsAccount/${selectedAccountId}/`, accountInputData);
            // Show a success toast
            toast.success('Account details updated successfully', 'success');
            // Close the edit modal
            handleFormModalClose();
            // Refetch the branch data to update the table
            fetchAccountData();
            // Refetch the branch table data to update the view
            fetchAccountTableData();
        } catch (error) {
            // Log any errors
            // Show an error toast
            toast.error('Failed to update Account details', 'error');
            return null;
        }
    };

    useEffect(() => {
        FetchCategoryData();
        FetchTaxData();
        FetchStateData();
    }, []);

    const getAccountCategoryByCode = async (code) => {
        try {
            const AccountResponse = await getFromAPI(`FtsAccountCategory/?id=${code}`);
            // 
            //Assuming stateResponse is an array of states
            if (AccountResponse) {
                return AccountResponse[0].name; // Return the state name
            } else {
                return null; // Return null if not found
            }
        } catch (error) {
            return null; // Return null in case of an error
        }
    };
    // Fetch category data
    const FetchCategoryData = () => {
        getFromAPI('FtsAccountCategory/').then(response => {

            // Directly set categories if the response is an array of categories
            if (Array.isArray(response)) {
                setCategories(response);  // Set the category data in state
            } else {
                console.error('Unexpected response structure:', response);
            }
        }).catch(error => {
            return null;
        });
    };

    const FetchTaxData = () => {
        getFromAPI('FtsTaxes/').then(response => {
            if (Array.isArray(response)) {
                setTax(response);  // Set the category data in state
            } else {
                console.error('Unexpected response structure:', response);
            }
        }).catch(error => {
            return null;
        });
    };

    const FetchStateData = () => {
        getFromAPI('FtsState/').then(response => {
            if (Array.isArray(response)) {
                setStates(response);  // Set the category data in state
            } else {
                console.error('Unexpected response structure:', response);
            }
        }).catch(error => {
            return null;
        });
    };

    useEffect(() => {
        fetchAccountTableData();
        fetchAccountData();
    }, [selectedAccountId]);

    // Edit Account modal
    const [formModalShow, setShowFormModal] = useState(false);
    const handleVaEdit = () => setShowFormModal(true);
    const handleFormModalClose = () => setShowFormModal(false);

    const handleInputChange = (index, field, value) => {
        setAccountInputData({ ...accountInputData, [field]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowFormModal(false);
    };
    const rows = [
        {
            columns: [
                { size: { lg: 6 }, label: 'Account Name', type: 'text', name: 'name', id: 'name', placeholder: 'Enter account name...', value: accountInputData.name, errormsg: '', },
                { size: { lg: 6 }, label: 'Account Number', type: 'number', name: 'account_number', id: 'account_number', placeholder: 'Enter account number...', value: accountInputData.account_number, errormsg: '', },
                {
                    size: { md: 6, lg: 3 }, label: 'Tax', type: 'select', name: 'tax', id: 'tax', value: accountInputData.tax, errormsg: '',
                    options: [
                        { value: '', label: 'Select Tax' },
                        ...tax.map(tax => ({ value: tax.id, label: tax.name }))
                    ],
                },
                {
                    size: { md: 6, lg: 5 }, label: 'Category', type: 'select', name: 'category_id', id: 'category_id', value: accountInputData.category_id, errormsg: '',
                    options: [
                        { value: '', label: 'Select Category' },
                        ...categories.map(category => ({ value: category.id, label: category.name }))
                    ],
                },
                { size: { lg: 4 }, label: 'GST Number', type: 'text', name: 'acc_gst_number', id: 'acc_gst_number', placeholder: 'Enter GST Number...', value: accountInputData.acc_gst_number, errormsg: '', },
                { size: { sm: 12 }, label: 'Description', type: 'textarea', name: 'description', formrow: '3', id: 'description', placeholder: 'Type description...', value: accountInputData.description, errormsg: '', },
                { size: { md: 6 }, label: 'Opening Balance', type: 'text', name: 'opening_balance', id: 'opening_balance', placeholder: 'Enter opening balance...', value: accountInputData.opening_balance, errormsg: '', },
                { size: { md: 6 }, label: '(%)Share of Profit', type: 'text', name: 'share_of_profit', id: 'share_of_profit', placeholder: 'Enter share of profit...', value: accountInputData.share_of_profit, errormsg: '', },
                { size: { lg: 5 }, label: 'Holder Name', type: 'text', name: 'holder_name', id: 'holder_name', placeholder: 'Enter holder name...', value: accountInputData.holder_name, errormsg: '', },
                {
                    size: { md: 5, lg: 3 }, label: 'Account Across Branch', type: 'select', name: 'account_across_branch', id: 'account_across_branch', value: accountInputData.account_across_branch, errormsg: '',
                    options: [
                        { value: 'Select', label: 'Select' },
                        { value: '1', label: 'Yes' },
                        { value: '2', label: 'No' },
                    ],
                },
                { size: { md: 7, lg: 4 }, label: 'Budget', type: 'text', name: 'budget', id: 'budget', placeholder: 'Enter budget...', value: accountInputData.budget, errormsg: '', },
                {
                    size: { md: 6, xxl: 4 }, type: 'radios', name: 'account_type', id: 'radioOptions', value: accountInputData.account_type,
                    options: [
                        { value: 1, label: 'Credit' },
                        { value: 0, label: 'Debit' },
                    ]
                },
            ]
        },
    ];
    const Buttons = [
        { variant: "primary", text: "Save Changes", onClick: handleSaveChanges },
        { variant: "secondary", text: "Close", onClick: handleFormModalClose },
    ]


    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>Details</Card.Title>
                        <div className='d-flex'>
                            <Button variant="soft-info p-0 me-3" onClick={handleVaEdit} disabled={!selectedAccountId} >
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                </div>
                            </Button>
                            <Button variant="soft-danger p-0" onClick={() => handleDelete(selectedAccountId)} disabled={!selectedAccountId}>
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                </div>
                            </Button>
                        </div>
                    </div>

                    <Table responsive className='table-lg border table-nowrap mb-0'>
                        <tbody>
                            {tableData.map((data, i) =>
                                <tr key={i}>
                                    <th>{data.accountheading1}</th>
                                    <td>{data.accountsubtext1}</td>

                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            {/* From Modal */}
            <FormModal title="Edit Account" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
        </>
    );
}
