import React,{useState,useEffect,useRef} from 'react';
import { Card, Form, Row, Col, Button } from 'react-bootstrap';
import {getFromAPI,postToAPI,putToAPI,showAlert,formatISODateWithTimezone,handleDateFormat} from '../../../Utils/utils';
import { toast } from 'react-toastify';
import { useBranchAdmin,BranchAdminProvider } from './getBranchAdminData'; // Import the useCustomer hook
import { readFile } from 'xlsx';

export default function NewBranchAdmin({ onSuccess }) {

    const initialBranchAdminData = {
        email : '',
        firstname : '',
        lastname : 'NA',
        facebook : null,
        linkedin : null,
        phonenumber : '',
        skype : null,
        password : '',
        datecreated : formatISODateWithTimezone(new Date()),
        profile_image : null,
        last_ip : null,
        last_login : formatISODateWithTimezone(new Date()),
        last_activity : formatISODateWithTimezone(new Date()),
        last_password_change : formatISODateWithTimezone(new Date()),
        new_pass_key : null,
        new_pass_key_requested : null,
        admin : 0,
        role : 0,
        active : 0,
        default_language : null,
        direction : null,
        media_path_slug : null,
        is_not_staff : 0,
        hourly_rate : 0.0,
        two_factor_auth_enabled : 0,
        two_factor_auth_code : null,
        two_factor_auth_code_requested : formatISODateWithTimezone(new Date()),
        email_signature : null,
        created_branch_id : 0,
        gender : 'Male',
        birth_date : '',
        joining_date : '',
        designation : 'NA',
        address : 'NA',
        alternate_name : '',
        alternate_phonenumber : '',
        alternate_address : '',
        id_proof : '',
        past_work_experience : 'NA',
        show_on_calendar : 0,
        referred_by_name : 'NA',
        referred_by_number : 'NA',
        average_rating : 0.0,
        nature : 'Good',
        check_in_time : "09:00", // 9 AM
        check_out_time : "17:00", // 5 PM        
        week_off_day : 0,
        proof_image : 'NA',
        proof_type : 'NA',
        report_to_id : 0,
        bank_account_number : 'NA',
        bank_account_holder_name : 'NA',
        bank_branch_name : 'NA',
        bank_branch_address : 'NA',
        bank_ifsc_code : 'NA',
        created_user_id : 0,
        last_modified_user_id : 0,
        created_date_time : formatISODateWithTimezone(new Date()),
        last_modified_date_time : formatISODateWithTimezone(new Date()),
        isdeleted : 0,
        reserve_varchar1 : null,
        reserve_varchar2 : null,
        reserve_float1 : 0.0,
        reserve_float2 : 0.0,
        reserve_int1 : 0,
        reserve_int2 : 0,
        reserve_double1 : 0.0,
        reserve_date1 : handleDateFormat(formatISODateWithTimezone(new Date())),
        report_to_id: 0,
    };
    
    const[branchAdminInputData,setBranchAdminInputData] = useState(initialBranchAdminData)
    const[branch,setBranch] = useState([]);
    const [staff, setStaff] = useState([]);
    const {fetchBranchAdminData,getBranchAdmin} = useBranchAdmin();
      // Function for handle input for phone_number
      const handleMobileChange = (e) => {
        const {name,value} = e.target;
        //Remove space from the input value
        setBranchAdminInputData((prev) => ({
            ...prev,
            [name] : value.replace(/\+/g,''),
        }));
    };

    useEffect(() => {
        const fetchBranch = async () => {
          try {
            const response = await getFromAPI('FtsBranch/');
            if (response) {
              setBranch(response);
            } else {
              toast.error('Failed to get branch');
            }
          } catch (error) {
            console.error(error);
            toast.error('Failed to get branch');
          }
        };
    
        fetchBranch();
      }, []); // Empty dependency array to run only once when the component mounts
    
    const handleAdd = async() => {
        try{
            if(!branchAdminInputData.firstname)
            {
                toast.error("Please enter the Name",'error');
                return;
            }
            if(!branchAdminInputData.created_branch_id)
            {
                toast.error("Please select the Branch Name",'error');
                return;
            }
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

            if (!branchAdminInputData.email) {
                toast.error("Please enter the Email", 'error');
                return;
            } else if (!emailPattern.test(branchAdminInputData.email)) {
                toast.error("Please enter a valid Email", 'error');
                return;
            }
            
            if(!branchAdminInputData.password || branchAdminInputData.password.length < 8)
            { 
              toast.error("Please enter valid 8 character password",'error')
              return; 
            }

            if(!branchAdminInputData.gender)
            {
                toast.error("Please select Gender",'error');
                return;
            }

            if(!branchAdminInputData.birth_date)
            {
                toast.error("Please select Birth Date",'error');
                return;
            }

            if(!branchAdminInputData.joining_date)
            {
                toast.error("Please select Joining Date",'error');
                return;
            }

            if (!branchAdminInputData.phonenumber) {
                toast.error("Please enter the Mobile Number", 'error');
                return;
            }
    
            // Validate mobile number format
            const mobileRegex1 = /^[6-9]\d{9}$/; // Indian mobile number example (10 digits starting with 6-9)
            if (!mobileRegex1.test(branchAdminInputData.phonenumber)) {
                toast.error("Please enter a valid Mobile Number", 'error');
                return;
            }

             // Validate mobile number format
             const mobileRegex2 = /^[6-9]\d{9}$/; // Indian mobile number example (10 digits starting with 6-9)
             if(branchAdminInputData.alternate_phonenumber)
             if (!mobileRegex2.test(branchAdminInputData.alternate_phonenumber)) {
                 toast.error("Please enter a valid Alternate Mobile Number", 'error');
                 return;
             }

                 
            const emailExists = getBranchAdmin.some(
                (branch) => branch.email === branchAdminInputData.email
            );
            if (emailExists) {
                toast.error('Email already exists. Please choose a different email.');
                return;
            }
            
            const response = await postToAPI('FtsStaff/', branchAdminInputData);
            if(response){
                toast.success('Branch Admin added successfully','success');
                setBranchAdminInputData(initialBranchAdminData);
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Clear file input
                }
                if (onSuccess && response.data.staffid) {
                    onSuccess(response.data.staffid); // Call the callback with new customer ID
                }
                fetchBranchAdminData();
            }else{
                toast.error('Error adding Branch Admin','error');
            }
        }catch(error){
            return null;
        }
    };

    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result); // Directly resolve with the Base64 string
          };
          reader.onerror = reject; // Reject if there's an error
          reader.readAsDataURL(file); // Read the file as a Data URL
        });
      };
    
      const handleFileUpload = async (e) => {
        try {
            const file = e.target.files[0]; // Get the selected file
            if (file) {
                // Validate if the file is a PDF
                const fileType = file.type;
                if (fileType !== 'application/pdf') {
                    toast.error('Only PDF files are allowed for proof');
                    e.target.value = null;
                    return; // Stop further processing if it's not a PDF
                }
    
                const base64PDF = await readFileAsDataURL(file);
                const base64String = base64PDF.split(',')[1]; // Get the Base64 part
    
                setBranchAdminInputData((prevData) => ({
                    ...prevData,
                    id_proof: base64String, // Store only the Base64 part
                }));
            }
        } catch (error) {
            toast.error('Error uploading file', 'error');
            return null;
        }
    };
    
    // Fetch staff based on created_branch_id
    useEffect(() => {
        const fetchStaff = async () => {
        if (branchAdminInputData.created_branch_id>0) {
            try {
            const response = await getFromAPI(`FtsStaff/?created_branch_id=${branchAdminInputData.created_branch_id}&admin=1`);
            setStaff(response);
            } catch (error) {
            toast.error('Failed to fetch staff');
            }
        }
        };
        fetchStaff();
    }, [branchAdminInputData.created_branch_id]);

    // Handle report to dropdown change
    const handleReportToChange = (e) => {
        setBranchAdminInputData({
        ...branchAdminInputData,
        report_to_id: e.target.value,
        });
    };

    const fileInputRef = useRef(null); // Reference for the file input

    const handleCancel = () => {
      setBranchAdminInputData(initialBranchAdminData); // Reset state
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Clear file input
      }
    };
    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <Card.Title>New Branch Admin</Card.Title>
                    <Form>
                        <Row>
                            <Col md={6} lg={12} xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Name <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control 
                                       type="text" 
                                       placeholder='Enter Name...' 
                                       name='firstname' 
                                       id='firstname' 
                                       value = {branchAdminInputData.firstname}
                                       onChange={e => setBranchAdminInputData({ ...branchAdminInputData, firstname: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6} lg={12} xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Branch <span className='text-danger'>*</span> </Form.Label>
                                     <Form.Select
                                            name='created_branch_id'
                                            id='created_branch_id'
                                            value={branchAdminInputData.created_branch_id}
                                            onChange={e => setBranchAdminInputData({ ...branchAdminInputData, created_branch_id: parseInt(e.target.value) })}>
                                            <option value="">Select Branch</option>
                                            {branch.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))}
                                        </Form.Select>
                                </div>
                            </Col>
                            <Col md={3} lg={12} xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Email ID <span className='text-danger'>*</span> </Form.Label>
                                    <Form.Control 
                                       type="email" 
                                       placeholder='Enter Email...' 
                                       name='emailId' 
                                       id='emailId' 
                                       value = {branchAdminInputData.email}
                                       onChange={e => setBranchAdminInputData({ ...branchAdminInputData, email: e.target.value })}

                                    />
                                </div>
                            </Col>
                            <Col md={3} lg={12} xl={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Password <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control 
                                        type="password" 
                                        placeholder='Enter password...' 
                                        name='password' 
                                        id='password' 
                                        value = {branchAdminInputData.password}
                                        onChange={e => setBranchAdminInputData({ ...branchAdminInputData, password: e.target.value })}
                                        />
                                </div>
                            </Col>
                             {/* Report To Dropdown */}
                            <Col md={3} lg={12} xl={6}>
                                <div className="mb-3 custom-form-input">
                                <Form.Label>Report To</Form.Label>
                                <Form.Select
                                    name="report_to_id"
                                    id="report_to_id"
                                    value={branchAdminInputData.report_to_id}
                                    onChange={(e) => {
                                        // Prevent changes if disabled
                                        if (!branchAdminInputData.created_branch_id) {
                                            e.preventDefault();
                                            return;
                                        }
                                        handleReportToChange(e);
                                    }}                                   
                                    disabled={!branchAdminInputData.created_branch_id} // Disable if no branch is selected
 
                                 >
                                    <option value="">Select Report To</option>
                                    {staff.map((staffMember) => (
                                    <option key={staffMember.staffid} value={staffMember.staffid}>
                                        {staffMember.firstname}
                                    </option>
                                    ))}
                                </Form.Select>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        placeholder='Enter Address...' 
                                        name='address' 
                                        id='address' 
                                        value = {branchAdminInputData.address}
                                        onChange={e => setBranchAdminInputData({ ...branchAdminInputData, address: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Mobile Number <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control 
                                       type="number" 
                                       placeholder='Enter mobile number ...' 
                                       name='phonenumber' 
                                       id='phonenumber' 
                                       value={branchAdminInputData.phonenumber}
                                       onChange={handleMobileChange} 
                                    />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Gender</Form.Label>
                                    <Form.Select  
                                        name='gender' 
                                        id='gender'
                                        value = {branchAdminInputData.gender}
                                        onChange={e => setBranchAdminInputData({ ...branchAdminInputData, gender: e.target.value })} 
                                        >
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Birthday <span className='text-danger'>*</span> </Form.Label>
                                    <Form.Control 
                                        type="date"
                                        placeholder='DD/MM/YYYY'
                                        name='birthday' 
                                        id='birthday' 
                                        max = '9999-12-31' // Restricting year to 4 digits 
                                        value = {branchAdminInputData.birth_date}
                                        onChange={e => setBranchAdminInputData({ ...branchAdminInputData, birth_date: e.target.value })} 
                                        
                                        />
                                </div>
                            </Col>
                            <Col sm={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Date of Join <span className='text-danger'>*</span></Form.Label>
                                    <Form.Control 
                                       type="date" 
                                       placeholder='DD/MM/YYYY' 
                                       name='dateofJoin' 
                                       id='dateofJoin' 
                                       max = '9999-12-31' // Restricting year to 4 digits 
                                       value = {branchAdminInputData.joining_date}
                                       onChange={e => setBranchAdminInputData({ ...branchAdminInputData, joining_date: e.target.value })} 
                                    />
                                </div>
                            </Col>
                            <Col md={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alternate Name</Form.Label>
                                    <Form.Control 
                                        type="text" 
                                        placeholder='Enter alternate name...' 
                                        name='alternateName' 
                                        id='alternateName'
                                        value = {branchAdminInputData.alternate_name}
                                        onChange={e => setBranchAdminInputData({ ...branchAdminInputData, alternate_name: e.target.value })}
                                    />
                                </div>
                            </Col>
                            <Col md={6} xl={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alternate Phone Number</Form.Label>
                                    <Form.Control 
                                       type="number" 
                                       placeholder='Enter alternate phone number...' 
                                       name='alternate_phonenumber' 
                                       id='alternate_phonenumber'
                                       value = {branchAdminInputData.alternate_phonenumber}
                                       onChange={handleMobileChange}
                                       />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Alternate Address</Form.Label>
                                    <Form.Control 
                                       type="text" 
                                       placeholder='Enter alternate address...' 
                                       name='alternateAddress' 
                                       id='alternateAddress' 
                                       value = {branchAdminInputData.alternate_address}
                                       onChange = {e => setBranchAdminInputData({ ...branchAdminInputData, alternate_address: e.target.value })}   
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Id Proof (PDF)</Form.Label>
                                <Form.Control  
                                    type="file" 
                                    accept="application/pdf" // Only allow PDF files
                                    name="id_proof" 
                                    id="id_proof" 
                                    onChange={e => handleFileUpload(e)}
                                    ref={fileInputRef} // Attach reference
                                />
                                </div>
                            </Col>
                            <Col md={6} xl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Check 
                                    type="checkbox" 
                                    label="Branch Admin"
                                    name="admin" 
                                    id="branchAdminCheckbox" 
                                    checked={branchAdminInputData.admin === 1} 
                                    onChange={(e) => setBranchAdminInputData({ 
                                        ...branchAdminInputData, 
                                        admin: e.target.checked ? 1 : 0 
                                    })} 
                                />
                            </div>
                        </Col>

                        </Row>
                    </Form>
                </Card.Body>
                <div className='custom-card-footer'>
                    <div className='text-end btn-list'>
                        <Button variant="primary" onClick={handleAdd}>Add</Button>{' '}
                        <Button variant="secondary" onClick={handleCancel}>Cancel</Button>{' '}
                    </div>
                </div>
            </Card>
        </>
    )
}
