import React, { useEffect,useState,useContext} from 'react';
import { Row, Col, Card, Form, Tab, Button, Table, Tabs, Badge } from 'react-bootstrap';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import { getFromAPI,postToAPI,putToAPI} from '../../Utils/utils';

// icon
import Icon from '@mdi/react';
import { mdiCart, mdiStorefront, mdiReload } from '@mdi/js';
import { toast } from 'react-toastify';
import { productContext } from './productContext';
import { ProductType, StoreType, TransactionType } from '../../Utils/constants';
import { stream } from 'exceljs';

export default function MyStore() {

    const [StockData,setStockData] = useState([]);
    const [productVisibility, setProductVisibility] = useState(null); // New state for fetched value
    const [productVisibility1, setProductVisibility1] = useState(null); // New state for fetched value

    const selectedProductId = useContext(productContext);
    const [product, setProductDetails] = useState({
        consumable_stock_in_floor: 0,
        consumable_stock_in_store: 0,
        retail_stock_in_floor: 0,
        retail_stock_in_store: 0,
        productName: '',
        productCode: '',
        productId: 0,
        branchId: 0,
        productType: 0,
      }); 

       // Fetch the option data to determine visibility
       const fetchInstoreOnFloorData = async () => {
        try {
            const response = await getFromAPI('FtsOptions/?name=product_on_floor_in_store');
            if (response[0]?.value) setProductVisibility(response[0].value); // Store the fetched value in state
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    // Fetch the option data to determine visibility
    const fetchConsumbleRetailData = async () => {
        try {
            const response = await getFromAPI('FtsOptions/?name=product_consumable_stock');
            if (response[0]?.value) setProductVisibility1(response[0].value); // Store the fetched value in state
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchInstoreOnFloorData();
        fetchConsumbleRetailData();
    }, []);


    const fetchStockData = async() => {
        try{
        const response = await getFromAPI(`FtsWarehouse/?product=${selectedProductId}`)
        if(response&&response.length>0)
        {
            setStockData(response)
            setProductDetails({
                consumable_stock_in_floor: response[0].onfloor_consumable_qty,
                consumable_stock_in_store: response[0].instore_consumable_qty,
                retail_stock_in_floor: response[0].onfloor_retail_qty,
                retail_stock_in_store: response[0].instore_retail_qty,
                opening_consumable_qty : response[0].opening_consumable_qty,
                opening_retail_qty : response[0].opening_retail_qty,
                productName: response[0].product.description,
                productCode: response[0].product.product_code,
                productId: response[0].product.id,
                branchId: response[0].branch.id,
                productType: response[0].product.type,
              });


        }
        else
        {
            toast.error('Error in getting Stock Data','error')
        }
    } catch(error) {
        toast.error('Error fetching Stock Data','error')
    }
};
  
 
  useEffect (()=>{
    fetchStockData()
  },[selectedProductId]);

    // datatable
    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    useEffect(() => {
        if (!$.fn.dataTable.isDataTable('.mainDatatable')) {
            $('.mainDatatable').DataTable({
                dom: "flrtip",
                language: {
                    search: '',
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: '«',
                        next: '»'
                    },
                }
            });
        }
    }, []);

    const [checkInputData, setCheckInputData] = useState({
        product_id: selectedProductId,
        transaction_type: TransactionType.CHECKOUT,
        from_product_type: parseInt(product.productType),
        to_product_type: parseInt(product.productType),
        qty: '',
        weight: 1,
        from_store_id: 0, // Initialize as integer
        to_store_id: 0,   // Initialize as integer
        comment: '',
        ref_number: '',
        branch_id: product.branchId,
    });

    const [convertInputData, setConvertInputData] = useState({
        product_id: selectedProductId,
        transaction_type: 13,
        from_product_type: parseInt(product.productType),
        to_product_type: parseInt(product.productType),
        qty: '',
        weight: 1,
        from_store_id: 0, // Initialize as integer
        to_store_id: 0,   // Initialize as integer
        comment: '',
        ref_number: '',
        branch_id: product.branchId,
    })
    
    useEffect(() => {
        if (selectedProductId) {
            setCheckInputData((prevState) => ({
                ...prevState,
                product_id: selectedProductId,
                branch_id: product.branchId,
            }));
        }
    }, [selectedProductId, product.branchId]);

    const handleAdd = async () => {
        try {
    
                const addData = {
                    ...checkInputData,
                    qty: Math.abs(checkInputData.qty), // Use the original quantity for addition
                    from_store_id: checkInputData.from_store_id,
                    to_store_id: checkInputData.to_store_id, // Specify the target store (where the stock is being added)
                };
    
                //  Post the addition to the API
                const addResponse = await postToAPI(`mystore_update_product_stock/`, addData);
                
                // Check if the addition was successful
                if (addResponse) {
                    toast.success('Stock Updated Successfully', 'success');
                    fetchStockData(); // Refresh stock data
                    fetchTransactionData(); // Refresh transaction data
                } else {
                    toast.error('Error in adding stock', 'error');
                }
        } catch (errors) {
            toast.error('Error in updating stock', 'error');
        }
    };

    const handleConvertStock = async () => {
        try {
                const addData = {
                    ...convertInputData,
                    qty: Math.abs(convertInputData.qty), // Use the original quantity for addition
                    from_store_id: convertInputData.from_store_id,
                    to_store_id: convertInputData.to_store_id, // Specify the target store (where the stock is being added)
                };
    
                //  Post the addition to the API
                const addResponse = await postToAPI(`mystore_update_product_stock/`, addData);
                
                // Check if the addition was successful
                if (addResponse) {
                    toast.success('Stock Updated Successfully', 'success');
                    fetchStockData(); // Refresh stock data
                    fetchTransactionData();  // Refresh transaction data
                } else {
                    toast.error('Error in adding stock', 'error');
                }
        } catch (errors) {
            toast.error('Error in updating stock', 'error');
        }
    };

    useEffect(() => {
        if (selectedProductId) {
            setConvertInputData((prevState) => ({
                ...prevState,
                product_id: selectedProductId,
                branch_id: product.branchId,
                product_type: parseInt(product.productType),

            }));
        }
    }, [selectedProductId, product.branchId,product.productType]);

       // Function to get the stock quantity based on selected "From" store and product types
       const getStockQuantity = (productType, storeType) => {
        if (productType === "1") { // Consumable
            return storeType === "1"
                ? product.consumable_stock_in_store
                : product.consumable_stock_in_floor;
        } else if (productType === "2") { // Retail
            return storeType === "1"
                ? product.retail_stock_in_store
                : product.retail_stock_in_floor;
        }
        return 0;
    };

    // Current stock values based on "From" store and product types
    const currentConsumableStock = getStockQuantity("1", convertInputData.from_store_id);
    const currentRetailStock = getStockQuantity("2", convertInputData.from_store_id);

    const [productData, setProductData] = useState([]);

    const fetchTransactionData = async () => {
      try {
        const response = await getFromAPI(`FtsWarehouseTransaction/?product_id=${selectedProductId}`);

        setProductData(response);
      } catch (error) {
        console.error('Error fetching transaction data:', error);
      }
    };
  
    useEffect(() => {
      if (selectedProductId != null) {
        fetchTransactionData();
      }
    }, [selectedProductId]);

    // Define a mapping for transaction type codes to their names
    const TransactionTypeNames = {
        1: "Opening Qty",
        2: "Purchase Invoice",
        3: "Internal Branch Transfer",
        4: "Transfer Order",
        5: "Invoice Retail",
        6: "Invoice Consumable Service",
        7: "Return Stock",
        8: "Check Out",
        9: "Adjustment",
        10: "Challan",
        11: "Purchase Return",
        12: "Sales Return",
        13: "Convert Stock",
    };
  
    const initializeDataTable = (tableId, data) => {
        const tableElement = $(`#${tableId}`);
        if ($.fn.dataTable.isDataTable(tableElement)) {
            tableElement.DataTable().clear().destroy();
        }
    
        tableElement.DataTable({
            data,
            columns: [
                { title: "SRNO." },
                { title: "Particular" },
                { title: "Credit" },
                { title: "Debit" },
                { title: "Closing" }
            ],
            autoWidth: false,
            language: {
                search: "",
                searchPlaceholder: "Search...",
                paginate: { previous: "«", next: "»" }
            }
        });
    };
    
    useEffect(() => {
        const getFirstTransactionQuantity = (filterType, filterLocation) => {
            const firstTransaction = productData
                .filter(data => 
                    data.from_type === String(filterType) && 
                    data.to_warehouse_id === filterLocation
                )[0]; // Get the first transaction that matches the filter
            
            return firstTransaction ? firstTransaction.quantity : 0; // Return the quantity or 0 if no transactions
        };
    
        const getDataTableData = (filterType, filterLocation, startingBalance, openingBalance) => {
            let currentBalance = openingBalance; // Initialize balance for each table
            let firstRow = true; // Flag for the first row
    
            return productData
                .filter(data => 
                    data.from_type === String(filterType) && 
                    data.to_warehouse_id === filterLocation
                )
                .map((data, i) => {
                    let debit = 0;
                    let credit = 0;
                    const quantity = data.quantity;
    
                    if (quantity < 0) {
                        debit = Math.abs(quantity);
                        credit = 0;
                    } else if (quantity > 0) {
                        credit = quantity;
                        debit = 0;
                    }
    
                    // Set closing balance for the current row
                    let closingBalance;
                    if (firstRow) {
                        // For the first row, set closing to the specified opening balance
                        closingBalance = openingBalance;
                        firstRow = false; // Turn off first row flag after setting initial balance
                    } else {
                        // Calculate closing balance: add credit, subtract debit, ensure non-negative
                        currentBalance = Math.max(0, currentBalance + credit - debit);
                        closingBalance = currentBalance;
                    }    
                    const transactionTypeWithDescription = `${TransactionTypeNames[data.transaction_type] || "Unknown"}${data.description ? ` (${data.description})` : ''}`;
                    return [
                        i + 1, // SRNO
                        transactionTypeWithDescription || 'NA', // Particular
                        credit || 0, // Credit
                        debit || 0, // Debit
                        closingBalance || 0, // Updated Closing
                    ];
                });
        };
    
        // Set the initial balance for each table
        const initialBalanceConsumableInStore = product.consumable_stock_in_store;
        const initialBalanceConsumableOnFloor = product.consumable_stock_in_floor;
        const initialBalanceRetailInStore = product.retail_stock_in_store;
        const initialBalanceRetailOnFloor = product.retail_stock_in_floor;
    
        // Set the opening balance for the first row in specific tables
        const openingConsumableQty = product.opening_consumable_qty;
        const openingRetailQty = product.opening_retail_qty;
    
        // Get first transaction quantities for Consumable On Floor and Retail On Floor
        const firstConsumableOnFloorQty = getFirstTransactionQuantity(ProductType.CONSUMABLE, StoreType.ONFLOOR);
        const firstRetailOnFloorQty = getFirstTransactionQuantity(ProductType.RETAIL, StoreType.ONFLOOR);
    
        // Get data for each specific table with respective starting and opening balance
        const consumableInStoreData = getDataTableData(ProductType.CONSUMABLE, StoreType.INSTORE, initialBalanceConsumableInStore, openingConsumableQty);
        const consumableOnFloorData = getDataTableData(ProductType.CONSUMABLE, StoreType.ONFLOOR, initialBalanceConsumableOnFloor, firstConsumableOnFloorQty);
        const retailInStoreData = getDataTableData(ProductType.RETAIL, StoreType.INSTORE, initialBalanceRetailInStore, openingRetailQty);
        const retailOnFloorData = getDataTableData(ProductType.RETAIL, StoreType.ONFLOOR, initialBalanceRetailOnFloor, firstRetailOnFloorQty);
    
        // Initialize each DataTable with its filtered data
        initializeDataTable("transactionDetailsDataTable1", consumableInStoreData);
        initializeDataTable("transactionDetailsDataTable2", consumableOnFloorData);
        initializeDataTable("transactionDetailsDataTable3", retailInStoreData);
        initializeDataTable("transactionDetailsDataTable4", retailOnFloorData);
    }, [productData]);
    
    const isSaveButtonDisabled = !checkInputData.from_product_type || !checkInputData.to_product_type;

    return (
        <>
            <div className='my-store-content d-none' id='my-store'>
            <Row>
            {(productVisibility === '1' || productVisibility === '0')&&(productVisibility1=='1') ? (
                    <Col sm={6} xxl={3}>
                        <Card className='text-center'>
                        <Card.Body>
                            <div className='avatar avatar-xl bg-soft-primary text-primary mx-auto mb-3'>
                            <Icon path={mdiCart} className='avatar-icon' />
                            </div>
                            <h2 className='mb-0'>{product.consumable_stock_in_store}</h2>
                            <p className='text-muted mb-0'>Consumable Stock (In Store)</p>
                        </Card.Body>
                        </Card>
                    </Col>
                    ) : null}

                    {(productVisibility === '2' || productVisibility === '0' ) && (productVisibility1 === '1')? (
                    <Col sm={6} xxl={3}>
                        <Card className='text-center'>
                        <Card.Body>
                            <div className='avatar avatar-xl bg-soft-success text-success mx-auto mb-3'>
                            <Icon path={mdiCart} className='avatar-icon' />
                            </div>
                            <h2 className='mb-0'>{product.consumable_stock_in_floor}</h2>
                            <p className='text-muted mb-0'>Consumable Stock (On Floor)</p>
                        </Card.Body>
                        </Card>
                    </Col>
                    ) : null}

                    {productVisibility === '1' || productVisibility === '0' ? (
                    <Col sm={6} xxl={3}>
                        <Card className='text-center'>
                        <Card.Body>
                            <div className='avatar avatar-xl bg-soft-info text-info mx-auto mb-3'>
                            <Icon path={mdiStorefront} className='avatar-icon' />
                            </div>
                            <h2 className='mb-0'>{product.retail_stock_in_store}</h2>
                            <p className='text-muted mb-0'>Retail Stock (In Store)</p>
                        </Card.Body>
                        </Card>
                    </Col>
                    ) : null}

                    {productVisibility === '2' || productVisibility === '0' ? (
                    <Col sm={6} xxl={3}>
                        <Card className='text-center'>
                        <Card.Body>
                            <div className='avatar avatar-xl bg-soft-danger text-danger mx-auto mb-3'>
                            <Icon path={mdiStorefront} className='avatar-icon' />
                            </div>
                            <h2 className='mb-0'>{product.retail_stock_in_floor}</h2>
                            <p className='text-muted mb-0'>Retail Stock (On Floor)</p>
                        </Card.Body>
                        </Card>
                    </Col>
                    ) : null}
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Tabs defaultActiveKey="openingQuantity" id="uncontrolled-tab-example" className="mb-3">
                                    <Tab eventKey="openingQuantity" title="Opening Quantity">
                                        <div className='mb-3'>
                                            <Form.Select  size="sm" className='w-auto ms-auto'>
                                                <option>All</option>
                                                <option value="1">Today</option>
                                                <option value="2">Monthly</option>
                                                <option value="3">Yearly</option>
                                            </Form.Select>
                                        </div>
                                        <Card className='border shadow-none' >
                                            <Card.Body>
                                                <div className='d-md-flex justify-content-between'>
                                                    <h5 className='fs-16 mb-3'>Opening Quantity</h5>

                                                </div>
                                                <Table responsive bordered className='table-nowrap mainDatatable'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Opening Consumable Quantity</th>
                                                            <th>Opening Retail Quantity</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>0</td>
                                                            <td>0</td>
                                                        </tr>
                                                        <tr>
                                                            <td>0</td>
                                                            <td>2</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>

                                        <Card className='border shadow-none' >
                                            <Card.Body>
                                                <div className='d-md-flex justify-content-between'>
                                                    <h5 className='fs-16 mb-3'>Product Order</h5>

                                                </div>
                                                <Table responsive bordered className='table-nowrap mainDatatable'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Vendor</th>
                                                            <th>Consumable Qty</th>
                                                            <th>Retail Qty</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>28/12/2022</td>
                                                            <td>Dianne M. Rico</td>
                                                            <td>8</td>
                                                            <td>6</td>
                                                            <td>
                                                                <Badge bg="soft-success">Available</Badge>{' '}
                                                            </td>
                                                            <td>
                                                                <Button type='button' variant='light btn-sm'>View More</Button>{' '}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Card.Body>
                                        </Card>

                                        <div className='text-center mt-4'>
                                            {/* <Button variant="primary"><Icon spin path={mdiReload} className="btn-icon me-1" /> Load More</Button>{' '} */}
                                        </div>
                                    </Tab>
                                    <Tab eventKey="checkOut" title="Check Out">
                                        <h5 className='fs-16 mb-3'>Check Out</h5>
                                        <Form>
                                            <Row>
                                                <Col xxl={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Product Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter product name..."
                                                            // value={'01 test product'} 
                                                            name='checkProductName' 
                                                            id='checkProductName'
                                                            value = {product.productName}
                                                            disabled 
                                                        />
                                                    </div>
                                                </Col>
                                                <Col sm={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Product Code</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter product code..."
                                                            // value={'31221244'} 
                                                            name='checkProductCode' id='checkProductCode' value={product.productCode} disabled/>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Check Out From</Form.Label>
                                                        <Form.Select  
                                                           name='checkOutFrom' 
                                                           id='checkOutFrom'
                                                           value = {checkInputData.from_product_type}
                                                           onChange={(e) => {
                                                            let pType = 0
                                                            parseInt(e.target.value) === 1 ? pType = ProductType.CONSUMABLE : pType = ProductType.RETAIL
                                                            setCheckInputData({ ...checkInputData, from_store_id: StoreType.INSTORE, from_product_type: pType})
                                                        } }
                                                        >
                                                          <option>Select Check Out From</option>
                                                            {((productVisibility === '1') || (productVisibility === '0')) && productVisibility1 === '1' && (
                                                                <option value="1">Consumable (Instore)</option>
                                                            )}
                                                            {(productVisibility === '1' || productVisibility === '0') && (
                                                                <option value="2">Retails (Instore)</option>
                                                            )}

                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Quantity</Form.Label>
                                                        <Form.Control type="number" placeholder="Enter current stock..."
                                                            // value={'0'} 
                                                            name='checkQuantity' 
                                                            id='checkQuantity' 
                                                            value = {(checkInputData.qty)}
                                                            onChange={(e) => setCheckInputData({...checkInputData, qty: parseInt(e.target.value) })}
                                                            
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>To</Form.Label>
                                                        <Form.Select  name='To' id='To' value = {parseInt(checkInputData.to_product_type)} onChange={(e) =>{ 
                                                            let pType = 0
                                                            parseInt(e.target.value) === 1 ? pType = ProductType.CONSUMABLE : pType = ProductType.RETAIL
                                                            setCheckInputData({ ...checkInputData, to_store_id: StoreType.ONFLOOR, to_product_type: pType })
                                                            }}>
                                                           <option>Select Check To</option>
                                                            {((productVisibility === '2')||(productVisibility === '0')) && productVisibility1 === '1' && (
                                                                <option value="1">Consumable (Onfloor)</option>
                                                            )}
                                                            {(productVisibility === '2' || productVisibility === '0') && (
                                                                <option value="2">Retails (Onfloor)</option>
                                                            )}

                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Comment</Form.Label>
                                                        <Form.Control as="textarea" placeholder='Type your comment...' rows={3} name='checkComment' id='checkComment' value = {checkInputData.comment} onChange={(e)=>setCheckInputData({...checkInputData,comment:e.target.value})}/>
                                                    </div>

                                                    <div className='btn-list text-end'>
                                                        <Button variant="primary" tupe = "submit"   disabled={isSaveButtonDisabled}  // Disable button when either field is not selected
                                                         onClick = {handleAdd}>Save</Button>
                                                        <Button variant="secondary" type="submit">Cancel</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                         
                                                   
                                        {/* Include the TransactionDataTable component and pass in productData */}
                                        {/* <TransactionDataTable data={productData} /> */}
                                    </Tab>
                                    <Tab eventKey="consumption" title="Consumption">
                                        <h5 className='fs-16 mb-3'>Consumption</h5>
                                        <Form>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Product Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter product name..."
                                                            // value={'01 test product'} 
                                                            name='consumptionProductName' id='consumptionProductName' />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Check Out From</Form.Label>
                                                        <Form.Select  name='consumptionOutFrom' id='consumptionOutFrom'>
                                                            <option>42</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Product Code</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter current stock..."
                                                            // value={'31221244'} 
                                                            name='consumptionProductCode' id='consumptionProductCode' value = {product.productCode} />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Current Stock</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter current stock..."
                                                            // value={'0'} 
                                                            name='consumptionCurrentStock' id='consumptionCurrentStock' />
                                                    </div>
                                                </Col>
                                                <Col md={4}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Consumption (weight)</Form.Label>
                                                        <Form.Control type="text" placeholder="0"
                                                            // value={'0'} 
                                                            name='consumptionConsumptionWeight' id='consumptionConsumptionWeight' />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Comment</Form.Label>
                                                        <Form.Control as="textarea" placeholder='Type your comment...' rows={3} name='consumptionComment' id='consumptionComment' value={checkInputData.comment} onChange={e=>setCheckInputData({...checkInputData,comment:e.target.value})}/>
                                                    </div>
                                                    <div className='btn-list text-end'>
                                                        <Button variant="primary" type="submit">Save</Button>
                                                        <Button variant="secondary" type="submit">Cancel</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Tab>
                                    <Tab eventKey="convertStock" title="Convert Stock">
                                        <h5 className='fs-16 mb-3'>Convert Stock</h5>
                                        <Form>
                                            <Row>
                                                <Col md={6}>
                                                    <h5 className='fs-16 mb-3'>From</h5>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Product Type</Form.Label>
                                                        <Form.Select  name='convertStockFromProductType' id='convertStockFromProductType'
                                                        value={convertInputData.from_product_type}
                                                        onChange = {(e) => setConvertInputData({...convertInputData,from_product_type: parseInt(e.target.value)})}
                                                        >
                                                            <option>Select Product Type</option>
                                                            {((productVisibility1 === '1')&&
                                                            <option value="1">Consumable</option>
                                                             )}
                                                            <option value="2">Retails</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Store Type</Form.Label>
                                                        <Form.Select  
                                                           name='convertStockFromStoreType' 
                                                           id='convertStockFromStoreType'
                                                           value = {convertInputData.from_store_id}
                                                           onChange = {(e) => setConvertInputData({...convertInputData,from_store_id: parseInt(e.target.value)})}
                                                           >
                                                            <option>Select Store Type</option>
                                                           {(productVisibility === '1' || productVisibility === '0') && (
                                                                <option value="1">Instore</option>
                                                            )}
                                                            {(productVisibility === '2' || productVisibility === '0') && (
                                                                <option value="2">OnFloor</option>
                                                            )}
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <h5 className='fs-16 mb-3'>To</h5>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Product Type</Form.Label>
                                                        <Form.Select  
                                                          name='convertStockToProductType' id='convertStockToProductType' 
                                                          value = {convertInputData.to_product_type}
                                                          onChange = {(e) => setConvertInputData({...convertInputData,to_product_type: parseInt(e.target.value)})}
                                                        >
                                                        <option>Select Product Type</option>
                                                            {((productVisibility1 === '1')&&
                                                            <option value="1">Consumable</option>
                                                             )}
                                                            <option value="2">Retails</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Store Type</Form.Label>
                                                        <Form.Select  name='convertStockToStoreType' id='convertStockToStoreType' value = {convertInputData.to_store_id} onChange = {(e)=>setConvertInputData({...convertInputData,to_store_id:parseInt(e.target.value)})}>
                                                        <option>Select Store Type</option>
                                                        {(productVisibility === '1' || productVisibility === '0') && (
                                                                <option value="1">Instore</option>
                                                            )}
                                                        {(productVisibility === '2' || productVisibility === '0') && (
                                                            <option value="2">OnFloor</option>
                                                        )}
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Convert Quantity</Form.Label>
                                                        <Form.Control type="number" placeholder="Enter convert quantity..." name='convertStockConvertQuantity' id='convertStockConvertQuantity' 
                                                        value = {convertInputData.qty}
                                                            onChange = {(e)=>setConvertInputData({...convertInputData,qty:parseInt(e.target.value)})}/>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Comment</Form.Label>
                                                        <Form.Control as="textarea" placeholder='Type your comment...' rows={3} name='checkComment' id='checkComment' value = {convertInputData.comment} onChange={(e)=>setConvertInputData({...convertInputData,comment:e.target.value})}/>
                                                    </div>
                                                </Col>
                                               
                                                <Col md={12}>
                                                    <div className='btn-list text-end'>
                                                        <Button variant="primary" onClick = {handleConvertStock}>Save</Button>
                                                        <Button variant="secondary">Cancel</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                        
                                    </Tab>
                                    <Tab eventKey="returnProducts" title="Return Products">
                                        <h5 className='fs-16 mb-3'>Return Products</h5>
                                        <Form>
                                            <Row>
                                                <Col md={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Return From</Form.Label>
                                                        <Form.Select  name='returnProductsFrom' id='returnProductsFrom'>
                                                            <option>Consumable(onfloor)</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                        </Form.Select>
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Return Quantity</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Return Quantity..." name='returnProductsQuantity' id='returnProductsQuantity' />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="mb-3 custom-form-input">
                                                        <Form.Label>Current Stock</Form.Label>
                                                        <Form.Control type="text" placeholder="0"
                                                            // value={'0'}
                                                            name='returnProductsStock' id='returnProductsStock' />
                                                    </div>
                                                </Col>
                                                <Col md={12}>
                                                    <div className='btn-list text-end'>
                                                        <Button variant="primary" type="submit">Save</Button>
                                                        <Button variant="secondary" type="submit">Cancel</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Tab>
                                    <Tab eventKey="reportQuantity" title="Report">
                                    {(productVisibility === '1' || productVisibility === '0') && (productVisibility1 === '1') && (
                                    <Card className='border shadow-none'>
                                        <Card.Body>
                                            <>
                                                <div className='d-md-flex justify-content-between'>
                                                <h5 className='fs-16 mb-3'>Consumable (In Store)</h5>
                                                </div>
                                                <Table responsive bordered className="mb-0 table-nowrap" id="transactionDetailsDataTable1">
                                                <thead className="table-light"></thead>
                                                </Table>
                                            </>
                                            
                                        </Card.Body>
                                        </Card>
                                       )}
                                        {(productVisibility === '2' || productVisibility === '0') && (productVisibility1 === '1') && (
                                        <Card className='border shadow-none'>
                                        <Card.Body>
                                            <>
                                                <div className='d-md-flex justify-content-between'>
                                                <h5 className='fs-16 mb-3'>Consumable (On Floor)</h5>
                                                </div>
                                                <Table responsive bordered className="mb-0 table-nowrap" id="transactionDetailsDataTable2">
                                                <thead className="table-light"></thead>
                                                </Table>
                                            </>
                                            
                                        </Card.Body>
                                        </Card>
                                         )}
                                        {(productVisibility === '0' || productVisibility === '1') && (
                                        <Card className='border shadow-none'>
                                        <Card.Body>
                                            <>
                                                <div className='d-md-flex justify-content-between'>
                                                <h5 className='fs-16 mb-3'>Retail (In Store)</h5>
                                                </div>
                                                <Table responsive bordered className="mb-0 table-nowrap" id="transactionDetailsDataTable3">
                                                <thead className="table-light"></thead>
                                                </Table>
                                            </>
                                            
                                        </Card.Body>
                                        
                                        </Card>
                                        )}
                                        {(productVisibility === '0' || productVisibility === '2') && (
                                        <Card className='border shadow-none'>
                                        <Card.Body>
                                            <>
                                                <div className='d-md-flex justify-content-between'>
                                                <h5 className='fs-16 mb-3'>Retail (On Floor)</h5>
                                                </div>
                                                <Table responsive bordered className="mb-0 table-nowrap" id="transactionDetailsDataTable4">
                                                <thead className="table-light"></thead>
                                                </Table>
                                            </>
                                            
                                        </Card.Body>
                                        </Card>
                                         )}



                                        <div className='text-center mt-4'>
                                            {/* <Button variant="primary"><Icon spin path={mdiReload} className="btn-icon me-1" /> Load More</Button>{' '} */}
                                        </div>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}
