import React, { createContext, useState, useContext } from 'react';
import { getFromAPI } from '../../../Utils/utils';

// Create Context
export const serviceModeContext = createContext();

// Custom hook to use the AccountCategoryContext
export const useServiceCategory = () => useContext(serviceModeContext);


// Create a Context Provider component
export const ServiceModeProvider = ({ children }) => {
    const [serviceModeData, setServiceModeData] = useState([]);

    const fetchServiceCategory = async () => {
        try {
            const response = await getFromAPI('FtsServiceCategory/');
            setServiceModeData(response);
        } catch (error) {
            console.error('Error fetching service category data:', error);
        }
    };

    return (
        <serviceModeContext.Provider value={{ serviceModeData, fetchServiceCategory }}>
            {children}
        </serviceModeContext.Provider>
    );
};
