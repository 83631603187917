import React, { useState, useEffect } from 'react';
import Footer from '../../layout/Footer';
import ViewUnit from './ViewUnit';
import { Container, Row, Col, Card, Form, Nav, Button } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import { getFromAPI, postToAPI, putToAPI } from '../../../Utils/utils';
import { fetchUnitData } from '../../../Utils/function_list';
import { toast } from 'react-toastify';
import { ProductProvider, useProduct } from './getUnit';
import { UnitContext } from './unitContext';

// Icons
import Icon from '@mdi/react';
import { mdiFormatListBulletedSquare, mdiPlus, mdiMagnify } from '@mdi/js';

function Unit() {
    const [Unit1, setUnit] = useState([]);
    const [selectedUnitId, setSelectedUnitId] = useState('');
    const { unitData, fetchUnitData1 } = useProduct();
    const [searchTerm, setSearchTerm] = useState('');

    const initialUnitData = {
        name: '',
        isdeleted: 0
    };
    const [unitInputData, setUnitInputData] = useState(initialUnitData);

    const handleCancel = () => {
        setUnitInputData(initialUnitData);
    }

    useEffect(() => {
        fetchUnitData1(setUnit);
    }, [selectedUnitId]);

    // Modified handleAddUnit function
    const handleAddUnit = async () => {
        if (!unitInputData.name.trim()) {
            toast.error('Please Enter Add Unit', 'error');
            return;
        }

        // Check if unit already exists
        const existingUnit = unitData.find(unit => unit.name === unitInputData.name);

        if (existingUnit) {
            toast.error('Unit Already Exists!', 'error');
            return;
        }

        try {
            // Post the data to the API
            const response = await postToAPI("FtsUnit/", unitInputData);

            if (response.status) {
                toast.success('Unit Added Successfully', 'success');
                setUnitInputData(initialUnitData);

                // Fetch updated unit data
                await fetchUnitData1();

                // Get the ID of the newly added unit
                const newUnitId = response.data.id;

                // Set the newly added unit as selected
                setSelectedUnitId(newUnitId);

                // Switch to view tab
                const viewUnitTab = document.getElementById("pills-view-unit-tab");
                if (viewUnitTab) {
                    viewUnitTab.click();
                }

                // Set focus and highlight the new unit in sidebar
                setTimeout(() => {
                    const sidebarItem = document.querySelector(`.left-link-item[data-id="${newUnitId}"] a`);
                    if (sidebarItem) {
                        // Remove active class from all items
                        const allLinks = document.querySelectorAll(".left-link-item a");
                        allLinks.forEach(link => link.classList.remove("active"));

                        // Add active class to new item
                        sidebarItem.classList.add("active");
                        sidebarItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    }
                }, 100);
            } else {
                toast.error('Unit Already Exists!', 'error');
            }
        } catch (error) {
            toast.error('An unexpected error occurred', 'error');
        }
    };


    const filteredUnitData = unitData.filter(admin =>
        admin.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        const sidebarItems = document.querySelectorAll(".left-link-item"); // Use querySelectorAll for better targeting

        // Loop over all sidebar items
        sidebarItems.forEach((item) => {
            item.addEventListener('click', function () {
                // Remove active class from all sidebar links
                const activeLink = document.querySelector(".left-link-item a.active");
                if (activeLink) activeLink.classList.remove("active");

                // Add active class to the clicked item
                const link = this.querySelector('a');
                if (link) link.classList.add('active');

                // Update selected vendor ID
                const unitId = this.getAttribute('data-id');
                setSelectedUnitId(unitId);

                // Trigger click on the "View Unit" tab
                const viewUnitTab = document.getElementById("pills-view-unit-tab");
                if (viewUnitTab) {
                    viewUnitTab.click();  // Ensure the tab is switched programmatically
                }

            });
        });

        // Cleanup listeners on component unmount
        return () => {
            sidebarItems.forEach((item) => {
                item.removeEventListener('click', () => { });
            });
        };
    }, [filteredUnitData]);

    // Modified useEffect to handle initial selection and updates
    useEffect(() => {
        if (unitData.length > 0) {
            if (!selectedUnitId || !unitData.find(unit => unit.id === selectedUnitId)) {
                setSelectedUnitId(unitData[0].id);
                setFocusOnSidebarItem(unitData[0].id);
            }
        }
    }, [unitData, selectedUnitId]);

    // Modified setFocusOnSidebarItem function
    const setFocusOnSidebarItem = (unitId) => {
        // Remove active class from all items
        const allLinks = document.querySelectorAll(".left-link-item a");
        allLinks.forEach(link => link.classList.remove("active"));

        // Add active class to the new item
        const newItem = document.querySelector(`.left-link-item[data-id="${unitId}"] a`);
        if (newItem) {
            newItem.classList.add("active");
            newItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    };


    return (
        <>
            <UnitContext.Provider value={selectedUnitId}>
                <div className="page-wrapper">
                    <div className='page-content'>
                        <Container>
                            <Row>
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <div className='d-md-flex justify-content-between align-items-center'>
                                                <Form className='custom-form-width'>
                                                    <div className='custom-search-bar'>
                                                        <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                        <Form.Control className='' type="text" placeholder="Search unit..." name='unitSearch' id='unitSearch' onChange={handleSearchChange} value={searchTerm} />
                                                    </div>
                                                </Form>
                                                <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                    <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link active" id="pills-view-unit-tab" data-bs-toggle="pill" data-bs-target="#pills-view-unit" type="button" role="tab" aria-controls="pills-view-unit" aria-selected="true">
                                                                <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />View Unit
                                                            </button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="pills-new-unit-tab" data-bs-toggle="pill" data-bs-target="#pills-new-unit" type="button" role="tab" aria-controls="pills-new-unit" aria-selected="false">
                                                                <Icon path={mdiPlus} className="btn-icon me-1" />New Unit
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col lg={4} xxl={3}>
                                    <Card className='procust-left-list leftbar-list'>
                                        <SimpleBarReact>
                                            <Card.Body>
                                                <div className='left-list-bar'>
                                                    {filteredUnitData.map((data, i) =>
                                                        <Nav.Item className='left-link-item' key={i} data-id={data.id} onClick={() => {
                                                            setSelectedUnitId(data.id);
                                                            setFocusOnSidebarItem(data.id);
                                                        }}>
                                                            <Nav.Link className={data.id === selectedUnitId ? "active" : ""}>
                                                                <h5 className='product-tab-title'>{data.name}</h5>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    )}
                                                </div>
                                            </Card.Body>
                                        </SimpleBarReact>
                                    </Card>
                                </Col>
                                <Col lg={8} xxl={9}>

                                    <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-view-unit" role="tabpanel" aria-labelledby="pills-view-unit-tab" tabIndex="0">
                                            <ViewUnit setSelectedUnitId={setSelectedUnitId} />
                                        </div>
                                        <div className="tab-pane fade" id="pills-new-unit" role="tabpanel" aria-labelledby="pills-new-unit-tab" tabIndex="0">
                                            <Card className='right-content-details'>
                                                <Card.Body>
                                                    <Card.Title>New Unit</Card.Title>
                                                    <Form>
                                                        <div className="mb-3 custom-form-input">
                                                            <Form.Label>Unit Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder='Enter unit Name...'
                                                                name='weightUnitName'
                                                                id='weightUnitName'
                                                                value={unitInputData.name}
                                                                onChange={e => setUnitInputData({ ...unitInputData, name: e.target.value })}

                                                            />
                                                        </div>
                                                    </Form>
                                                </Card.Body>
                                                <div className='custom-card-footer'>
                                                    <div className='text-end btn-list'>
                                                        <Button variant="primary" onClick={handleAddUnit}>Add</Button>{' '}
                                                        <Button variant="secondary" onClick={handleCancel}>Cancel</Button>{' '}
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Footer />
                </div>
            </UnitContext.Provider>
        </>
    )
}

export default function CustomersWithProvider() {
    return (
        <ProductProvider>
            <Unit />
        </ProductProvider>
    );
}