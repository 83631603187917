import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import locationData from './location_data.json';
import TempData from './temp_data.json';
import { postToAPI, formatISODateWithTimezone,getFromAPI } from './utils';
import { toast } from "react-toastify";
import messages from './lang'; // Adjust the path based on your project structure

export default function QuickVendorModal({ addVendorShow, addVendorClose }) {
    const [TempDataOption] = useState(TempData);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');

    const initialInputData = {
        name: '',
        company_name: '',
        phone_number1: '',
        email: '',
        gst_number: '',
        country: 0,
        state: 0,
        city: 0,
        tax: 0,
        pan_card_number: '',
        opening_balance: 0.0,
        current_balance: 0.0,
        bank_branch_name: null,
        bank_branch_ifsc_code: null,
        bank_account_number: 0,
        website: null,
        phone_number2: null,
        primary_address: null,
        tax_id: 1,
        tax_type: 1,
        isactive: 1,
        isdeleted: 0,
        created_branch_id: 1,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        created_user_id: 1,
        last_modified_user_id: 1
    };
    const [inputData, setInputData] = useState({ ...initialInputData });
    
    useEffect(() => {
            const fetchCountryData = async () => {
                try {
                    const response = await getFromAPI("FtsCountries/");
                    setCountries(response); // Save country data
                } catch (error) {
                    return null;
                }
            };
    
            fetchCountryData();
     }, []);

    const handleCountryChange = async (e) => {
            const selectedCountry = e.target.value;
            setInputData(prevState => ({ ...prevState, country: parseInt(selectedCountry) }));
    
            try {
                const response = await getFromAPI(`FtsState/?country_code=${selectedCountry}`);
                setStates(response); // Save state data
                setCities([]); // Reset city data
            } catch (error) {
                return null;
            }
    };
    
    const handleStateChange = async (e) => {
            const selectedState = e.target.value;
            setInputData(prevState => ({ ...prevState, state: parseInt(selectedState) }));
    
            try {
                const response = await getFromAPI(`FtsCity/?state_id=${selectedState}`);
                setCities(response); // Save city data
                // Set the first city as default if cities are available
                if (response && response.length > 0) {
                    setInputData(prevState => ({
                        ...prevState,
                        city: response[0].id
                        }));
                }
            } catch (error) {
                return null;
            }
    };


    const handleSubmit = async () => {
        if (!inputData.company_name || !inputData.company_name.trim()) {  // Fix: Add parentheses to trim()
            toast.error(messages.vendor.company_name_required, 'error');
            return;
        }
        if (!inputData.name || !inputData.name.trim()) {  // Fix: Add parentheses to trim()
            toast.error(messages.vendor.contact_person_name_required, 'error');
            return;
        }
    
        if (!inputData.phone_number1 || inputData.phone_number1.length != 10) {
            toast.error(messages.vendor.phone_number_required, 'error');
            return;
        }
    
        try {
            const response = await postToAPI("add_vendor/", inputData);
            if (response.status) {
                toast.success(messages.vendor.vendor_added_successfully, 'success');
                setInputData(initialInputData);
                addVendorClose();
            } else {
                toast.error(messages.vendor.failed_to_add);
            }
        } catch (error) {
            return null;
        }
    };

    const handleCancel = () => {
        addVendorClose();
        setInputData(initialInputData);
    };
    return (
        <>
            <Modal centered show={addVendorShow} onHide={addVendorClose} backdrop="static" size='lg'>
                <Modal.Header className='custom-close-btn' closeButton></Modal.Header>
                <Modal.Body>
                    <h5 className='mb-4'>Add New Vendor</h5>
                    <Form>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Vendor Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter name..." name='vendorName' id='vendorName' value={inputData.company_name} onChange={(e) => setInputData({ ...inputData, company_name: e.target.value })}/>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Contact Person Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Contact Person name..." name='name' id='name' value={inputData.name} onChange={(e) => setInputData({ ...inputData, name: e.target.value })}/>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <Form.Control type="number" placeholder="Enter number..." name='vendorNumber' id='vendorNumber' value={inputData.phone_number1} onChange={(e) => setInputData({ ...inputData, phone_number1: e.target.value })} />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email..." name='vendorEmail' id='vendorEmail' value={inputData.email} onChange={(e) => setInputData({ ...inputData, email: e.target.value })} />
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>GST</Form.Label>
                                    <Form.Control type="text" placeholder="Enter gst number..." name='vendorGstNumber' id='vendorGstNumber' value={inputData.gst_number} onChange={(e) => setInputData({...inputData,gst_number:e.target.value})} />
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input" controlId="formCountry">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select value={inputData.country}onChange={handleCountryChange}>
                                        <option value="">Select Country</option>
                                        {countries.map(item => (
                                            <option key={item.id} value={item.calling_code}>{item.short_name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input" controlId="formState">
                                    <Form.Label>State</Form.Label>
                                    <Form.Select value={inputData.state} onChange={handleStateChange} disabled={!states.length}>
                                        <option value="">Select State</option>
                                        {states.map(item => (
                                            <option key={item.id} value={item.state_id}>{item.state_name}</option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input" controlId="formCity">
                                    <Form.Label>City</Form.Label>
                                    <Form.Select disabled={!cities.length} value={inputData.city} onChange={e => setInputData({ ...inputData, city: parseInt(e.target.value) })}>
                                        <option value="">Select City</option>
                                            {cities.map(item => (
                                                <option key={item.id} value={item.id}>{item.city_name}</option>
                                            ))}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select>
                                    {TempDataOption.TempTransactionTaxType.map((data, i) =>
                                        <option key={i}>{data.transactionTaxTypeOption}</option>
                                    )}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>PAN</Form.Label>
                                    <Form.Control type="text" className='text-uppercase' placeholder="Enter PAN..." name='vendorPan' id='vendorPan'  value={inputData.pan_card_number} onChange={e => setInputData({ ...inputData, pan_card_number: e.target.value })}/>
                                </div>
                            </Col>
                            <Col md={6} lg={4}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Opening Balance</Form.Label>
                                    <Form.Control type="number" placeholder="Enter Opening Balance..." name='vendorOpeningBalance' id='vendorOpeningBalance' value={inputData.opening_balance} onChange={e => setInputData({ ...inputData, opening_balance: e.target.value })} />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax</Form.Label>
                                    <Form.Select>
                                    {TempDataOption.TempTaxType.map((data, i) =>
                                        <option key={i}>{data.taxTypeOption}</option>
                                    )}
                                    </Form.Select>
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="mb-3 custom-form-input">
                                    <Form.Label>Tax Type</Form.Label>
                                    <Form.Select>
                                    {TempDataOption.TempGstTaxType.map((data, i) =>
                                        <option key={i}>{data.gstTaxTypeOption}</option>
                                    )}
                                    </Form.Select>
                                </div>
                            </Col>
                        </Row>
                        <div className="text-end">
                            <Button variant="primary me-2" onClick={handleSubmit}>Add</Button>
                            <Button variant="secondary" onClick={handleCancel}>Close</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}
