import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import { postToAPI, getFromAPI } from '../../Utils/utils';
import { useCustomer, CustomerProvider } from './getCustomerData'; // Import the useCustomer hook
import { toast } from "react-toastify";
import { fetchBankData, fetchCountryData, fetchTaxData, fetchCutsomerData, fetchBranchData } from '../../Utils/function_list';
import message from '../../Utils/lang';
export default function NewCustomer({ onSuccess }) {
    const [country, setCountry] = useState([]);
    const [State, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [Tax, setTax] = useState([]);
    const [Customer, setCustomer] = useState([]);
    const [Bank, setBank] = useState([]);
    const { customerData,fetchCustomerData } = useCustomer();
    const [errors, setErrors] = useState({});
    const getLoginBranchId = localStorage.getItem('createdBranchId');
    const [branchData, setBranchData] = useState([]);

    // Initialize branch input data state
    const [branchInputData, setBranchInputData] = useState({
        bcountryCode: '',
        bStateCode: '',
        bcityCode: ''
    });

    // Modify the useEffect for branch data to include tax handling
    useEffect(() => {
        if (branchData.length) {
            const filteredBranchData = branchData.find(
                branch => branch.id === parseInt(getLoginBranchId, 10)
            );

            if (filteredBranchData) {
                const { country, state, city } = filteredBranchData;
                setBranchInputData({
                    bcountryCode: country,
                    bStateCode: state,
                    bcityCode: city,
                });

                // Set initial tax based on branch state
                const initialTax = determineAppropiateTax(state, state);

                setInputData(prev => ({
                    ...prev,
                    country: country,
                    state: state,
                    city: city,
                    tax: initialTax
                }));

                handleCountryChange({ target: { value: country } });
                handleStateChange({ target: { value: state } });
            }
        }
    }, [branchData, getLoginBranchId]);

    // Fetch initial data
    useEffect(() => {
        fetchBranchData(setBranchData);
        fetchCountryData(setCountry);
        fetchBankData(setBank);
        fetchTaxData(setTax);
        fetchCutsomerData(setCustomer);
    }, []);

    const initialInputData = {
        name: '',
        email: '',
        phone_number2: '',
        gender: '',
        birth_date: '',
        anniversary_date: '',
        address: '',
        reffered_by_id: 0,
        gst: '',
        country: '',
        state: '',
        city: '',
        tax: 0,
        pan: '',
        opening_balance: 0.0,
        bank_name: 0,
        ifsc_code: '',
        account_number: '',
    };

    const [inputData, setInputData] = useState({ ...initialInputData });

    const validateField = (field, value) => {
        let error = '';
        switch (field) {
            case 'name':
                if (!value) error = message.customer.required_name;
                break;
            case 'phone_number2':
                if (!value) error = message.customer.required_phone_number;
                break;

            default:
                break;
        }
        setErrors(prevErrors => ({
            ...prevErrors,
            [field]: error,
        }));
        return error;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setInputData(prevData => ({
            ...prevData,
            [name]: value
        }));
        validateField(name, value);
    };

    const handleCountryChange = async (e) => {
        const selectedCountry = e.target.value;
        setInputData(prevState => ({ ...prevState, country: selectedCountry }));

        try {
            const response = await getFromAPI(`FtsState/?country_code=${selectedCountry}`);
            setState(response);
            setCity([]);
        } catch (error) {
            return null;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation
        if (!inputData.name || !inputData.name.trim()) {
            toast.error(message.customer.required_name);
            return;
        }

        if (!inputData.phone_number2 || inputData.phone_number2.length !== 10) {
            toast.error(message.customer.required_phone_number);
            return;
        }        

        // Check for already existing mobile number
        const isExist = customerData.find(customer => customer.phone_number2 === inputData.phone_number2);

        if (isExist) {
            toast.error(message.customer.already_exist);
            return;
        }

        try {
            const response = await postToAPI("add_customer/", inputData);
            if (response.status) {
                toast.success(message.customer.add_customer);
                if (onSuccess && response.user_id) {
                    onSuccess(response.user_id); // Call the callback with new customer ID
                }
                fetchCustomerData();
                setInputData(initialInputData);
            } else {
                toast.error(message.customer.failed_to_add);
            }
        } catch (error) {
            toast.error(message.customer.failed_to_add);
        }
    };

    // Add new state for tax options
    const [taxOptions] = useState([
        { id: 1, name: 'B2C', code: 'b2c' },
        { id: 2, name: 'B2B', code: 'b2b' },
        { id: 3, name: 'B2B-IGST', code: 'b2b-igst' },
        { id: 4, name: 'B2C-IGST', code: 'b2c-igst' }
    ]);

    // Function to determine appropriate tax based on states
    const determineAppropiateTax = (customerState, branchState) => {
        if (customerState && branchState) {
            if (customerState === branchState) {
                return taxOptions.find(tax => tax.code === 'b2c-igst')?.id || '';
            } else {
                return taxOptions.find(tax => tax.code === 'b2c')?.id || '';
            }
        }
        return taxOptions.find(tax => tax.code === 'b2c')?.id || '';;
    };

    const handleStateChange = async (e) => {
        const selectedState = e.target.value;
        const newTax = determineAppropiateTax(selectedState, branchInputData.bStateCode);

        setInputData(prevState => ({ ...prevState, state: selectedState, tax: newTax }));

        try {
            const response = await getFromAPI(`FtsCity/?state_id=${selectedState}`);
            setCity(response);
            // Set the first city as default if cities are available
            if (response && response.length > 0) {
                setInputData(prevState => ({
                    ...prevState,
                    city: response[0].id
                }));
            }
        } catch (error) {
            return null;
        }
    };

    // Function to extract state code and PAN from GST number
    const handleGSTChange = async (e) => {
        const gstNumber = e.target.value;
        setInputData(prevState => ({ ...prevState, gst: gstNumber }));

        if (gstNumber.length >= 15) { // Standard GST number length is 15
            const stateCode = gstNumber.substring(0, 2);
            const panNumber = gstNumber.substring(2, 12);

            // Update PAN automatically
            setInputData(prevState => ({
                ...prevState,
                pan: panNumber
            }));

            try {
                // First get the country code for India (assuming GST is Indian)
                const countryResponse = await getFromAPI('FtsCountries/?calling_code=91');
                if (countryResponse.length > 0) {
                    const countryCode = countryResponse[0].calling_code;

                    // Set country to India
                    setInputData(prevState => ({
                        ...prevState,
                        country: countryCode
                    }));

                    // Fetch state data using the state code from GST
                    const stateResponse = await getFromAPI(`FtsState/?state_id=${stateCode}`);
                    if (stateResponse.length > 0) {
                        const stateData = stateResponse[0];
                        // Update tax based on GST state code
                        const newTax = determineAppropiateTax(stateData.state_code, branchInputData.bStateCode);

                        // Update state
                        setInputData(prevState => ({
                            ...prevState,
                            state: stateData.state_id,
                            pan: panNumber,
                            tax: newTax,
                        }));

                        // Fetch and set cities for the selected state
                        const cityResponse = await getFromAPI(`FtsCity/?state_id=${stateData.state_code}`);
                        setCity(cityResponse);
                        // Set the first city as default if cities are available
                        if (cityResponse && cityResponse.length > 0) {
                            setInputData(prevState => ({
                                ...prevState,
                                city: cityResponse[0].id
                            }));
                        }
                    }
                }
            } catch (error) {
                toast.error('Error processing GST number');
            }
        }
    };

    const handleCancel = (e) => {
        setInputData(initialInputData)
    };

    const isFormValid = () => {
         return (
            inputData.name.trim() !== '' && inputData.phone_number2.trim() !== ''  
        );
    };

    return (
        <>
            <Card.Body className='pb-0'>
                <Card.Title>Add Customer</Card.Title>
                <Form>
                    <Row>
                        <Col md={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>
                                    Customer Name <span className='text-danger'>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name='name'
                                    id='name'
                                    placeholder="Enter Customer Name..."
                                    value={inputData.name}
                                    onChange={handleInputChange}
                                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                                />

                            </div>
                        </Col>
                        <Col md={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>
                                    Email Address
                                </Form.Label>
                                <Form.Control
                                    type="email"
                                    name='email'
                                    id='email'
                                    placeholder="Enter Email Address..."
                                    value={inputData.email}
                                    onChange={handleInputChange}
                                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                                />

                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>
                                    Mobile Number  <span className='text-danger'>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    name='phone_number2'
                                    id='phone_number2'
                                    placeholder="Please Enter Mobile Number..."
                                    value={inputData.phone_number2}
                                    onChange={handleInputChange}
                                    onBlur={(e) => validateField(e.target.name, e.target.value)}
                                />

                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Gender</Form.Label>
                                <Form.Select

                                    name='gender'
                                    id='gender'
                                    value={inputData.gender}
                                    onChange={e => setInputData({ ...inputData, gender: e.target.value })}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </Form.Select>
                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Birthday</Form.Label>
                                <Form.Control
                                    type="date"
                                    name='birth_date'
                                    id='birth_date'
                                    placeholder="DD/MM/YYYY"
                                    value={inputData.birth_date}
                                    onChange={e => setInputData({ ...inputData, birth_date: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Anniversary</Form.Label>
                                <Form.Control
                                    type="date"
                                    name='anniversary_date'
                                    id='anniversary_date'
                                    placeholder="DD/MM/YYYY"
                                    value={inputData.anniversary_date}
                                    onChange={e => setInputData({ ...inputData, anniversary_date: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='address'
                                    id='address'
                                    placeholder="Enter address..."
                                    value={inputData.address}
                                    onChange={e => setInputData({ ...inputData, address: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Referred By</Form.Label>
                                <Form.Select

                                    name='referred_by'
                                    id='referred_by'
                                    value={inputData.reffered_by_id}
                                    onChange={e => setInputData({ ...inputData, reffered_by_id: e.target.value })}
                                >
                                    <option value="">None</option>
                                    {Customer.map(item => (
                                        <option key={item.id} value={item.userid}>{item.name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                        <Col sm={6}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>GST</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='GST'
                                    id='GST'
                                    placeholder="Enter GST number..."
                                    value={inputData.gst}
                                    onChange={handleGSTChange}
                                    maxLength={15}
                                />
                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Country</Form.Label>
                                <Form.Select

                                    name='country'
                                    id='country'
                                    value={inputData.country}
                                    onChange={handleCountryChange}
                                >
                                    <option value="">Select Country</option>
                                    {country.map(item => (
                                        <option key={item.id} value={item.calling_code}>{item.short_name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>State</Form.Label>
                                <Form.Select

                                    name='state'
                                    id='state'
                                    value={inputData.state}
                                    onChange={handleStateChange}
                                >
                                    <option value="">Select State</option>
                                    {State.map(item => (
                                        <option key={item.id} value={item.state_code}>{item.state_name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>City</Form.Label>
                                <Form.Select

                                    name='city'
                                    id='city'
                                    value={inputData.city}
                                    onChange={e => setInputData({ ...inputData, city: e.target.value })}
                                >
                                    <option value="">Select City</option>
                                    {city.map(item => (
                                        <option key={item.id} value={item.id}>{item.city_name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                        <Col sm={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Tax</Form.Label>
                                <Form.Select
                                    name='tax'
                                    id='tax'
                                    value={inputData.tax}
                                    onChange={e => setInputData({ ...inputData, tax: e.target.value })}
                                >
                                    <option value="">Select Tax</option>
                                    {taxOptions.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                        <Col md={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>PAN</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='pan'
                                    id='pan'
                                    placeholder="Enter PAN number..."
                                    value={inputData.pan}
                                    onChange={e => setInputData({ ...inputData, pan: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col md={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Opening Balance</Form.Label>
                                <Form.Control
                                    type="number"
                                    name='opening_balance'
                                    id='opening_balance'
                                    placeholder="Enter opening balance..."
                                    value={inputData.opening_balance}
                                    onChange={e => setInputData({ ...inputData, opening_balance: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col md={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Bank Name</Form.Label>
                                <Form.Select

                                    name='bank_name'
                                    id='bank_name'
                                    value={inputData.bank_name}
                                    onChange={e => setInputData({ ...inputData, bank_name: e.target.value })}
                                >
                                    <option value="">Select Bank Name</option>
                                    {Bank.map(item => (
                                        <option key={item.id} value={item.bank_id}>{item.bank_name}</option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                        <Col md={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>IFSC Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    name='ifsc_code'
                                    id='ifsc_code'
                                    placeholder="Enter IFSC code..."
                                    value={inputData.ifsc_code}
                                    onChange={e => setInputData({ ...inputData, ifsc_code: e.target.value })}
                                />
                            </div>
                        </Col>
                        <Col md={6} xxl={4}>
                            <div className="mb-3 custom-form-input">
                                <Form.Label>Account Number</Form.Label>
                                <Form.Control
                                    type="number"
                                    name='account_number'
                                    id='account_number'
                                    placeholder="Enter Account Number..."
                                    value={inputData.account_number}
                                    onChange={e => setInputData({ ...inputData, account_number: e.target.value })}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Col md={12}>
                        <div className="btn-list text-end">
                            <Button variant="primary" onClick={handleSubmit} disabled = {!isFormValid()}>Add</Button>
                            <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
                        </div>
                    </Col>             .
                </Form>
            </Card.Body>
        </>
    );
}
