import React, { useState, useEffect, useContext } from 'react';
import { Card, Button, Table, Form } from 'react-bootstrap';
import { getFromAPI, deleteFromAPI, showAlert, putToAPI, formatISODateWithTimezone, handleDateFormat } from '../../../Utils/utils';
import { toast } from 'react-toastify'
import { BranchAdminContext1 } from './branchAdminContext';
import { useBranchAdmin } from './getBranchAdminData';
import FormModal from '../../../Utils/FormModal';
import PermissionData from './permision.json';

// Icon
import Icon from '@mdi/react';
import { mdiSquareEditOutline, mdiTrashCanOutline } from '@mdi/js';

export default function ViewBranchAdmin({ setSelectedBranchAdminId }) {
    const [branch, setBranch] = useState([]);
    const { fetchBranchAdminData, getBranchAdmin } = useBranchAdmin();
    const selectBranchAdminId = useContext(BranchAdminContext1);
    const initialBranchAdminData = {
        email: '',
        firstname: '',
        lastname: 'NA',
        facebook: null,
        linkedin: null,
        phonenumber: null,
        skype: null,
        password: '',
        datecreated: formatISODateWithTimezone(new Date()),
        profile_image: null,
        last_ip: null,
        last_login: formatISODateWithTimezone(new Date()),
        last_activity: formatISODateWithTimezone(new Date()),
        last_password_change: formatISODateWithTimezone(new Date()),
        new_pass_key: null,
        new_pass_key_requested: null,
        admin: 1,
        role: 0,
        active: 0,
        default_language: null,
        direction: null,
        media_path_slug: null,
        is_not_staff: 0,
        hourly_rate: 0.0,
        two_factor_auth_enabled: 0,
        two_factor_auth_code: null,
        two_factor_auth_code_requested: formatISODateWithTimezone(new Date()),
        email_signature: null,
        created_branch_id: 0,
        gender: 'Male',
        birth_date: handleDateFormat(formatISODateWithTimezone(new Date())),
        joining_date: handleDateFormat(formatISODateWithTimezone(new Date())),
        designation: 'NA',
        address: 'NA',
        alternate_name: null,
        alternate_phonenumber: null,
        alternate_address: null,
        id_proof: null,
        past_work_experience: 'NA',
        show_on_calendar: 0,
        referred_by_name: 'NA',
        referred_by_number: 'NA',
        average_rating: 0.0,
        nature: 'Good',
        check_in_time: "09:00", // 9 AM
        check_out_time: "17:00", // 5 PM        
        week_off_day: 0,
        proof_image: 'NA',
        proof_type: 'NA',
        report_to_id: 0,
        bank_account_number: 'NA',
        bank_account_holder_name: 'NA',
        bank_branch_name: 'NA',
        bank_branch_address: 'NA',
        bank_ifsc_code: 'NA',
        created_user_id: 0,
        last_modified_user_id: 0,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isdeleted: 0,
        reserve_varchar1: null,
        reserve_varchar2: null,
        reserve_float1: 0.0,
        reserve_float2: 0.0,
        reserve_int1: 0,
        reserve_int2: 0,
        reserve_double1: 0.0,
        reserve_date1: handleDateFormat(formatISODateWithTimezone(new Date()))

    };
    const [branchAdminInputData, setBranchAdminInputData] = useState({ ...initialBranchAdminData });
    const [branchTableData, setBranchTableData] = useState([]);
    const getBranchName = async (code) => {
        try {
            const branchAdmin = await getFromAPI(`FtsBranch/?id=${code}`);
            if (branchAdmin) {
                return branchAdmin[0].name; // Return the state name
            } else {
                return null; // Return null if not found
            }
        } catch (error) {
            return null; // Return null in case of an error
        }
    };

    useEffect(() => {
        const fetchBranchAdminDetails = async () => {
            try {
                const response = await getFromAPI(`FtsStaff/?staffid=${selectBranchAdminId}`);

                if (response && response.length > 0) {

                    const branchAdmin = response[0];
                    setBranchAdminInputData({
                        email: branchAdmin.email,
                        firstname: branchAdmin.firstname,
                        lastname: branchAdmin.lastname,
                        facebook: branchAdmin.facebook,
                        linkedin: branchAdmin.linkedin,
                        phonenumber: branchAdmin.phonenumber,
                        skype: branchAdmin.skype,
                        password: branchAdmin.password,
                        datecreated: branchAdmin.datecreated,
                        profile_image: branchAdmin.profile_image,
                        last_ip: branchAdmin.last_ip,
                        last_login: branchAdmin.last_login,
                        last_activity: branchAdmin.last_activity,
                        last_password_change: branchAdmin.last_password_change,
                        new_pass_key: branchAdmin.new_pass_key,
                        new_pass_key_requested: branchAdmin.new_pass_key_requested,
                        admin: branchAdmin.admin,
                        role: branchAdmin.role,
                        active: branchAdmin.active,
                        default_language: branchAdmin.default_language,
                        direction: branchAdmin.direction,
                        media_path_slug: branchAdmin.media_path_slug,
                        is_not_staff: branchAdmin.is_not_staff,
                        hourly_rate: branchAdmin.hourly_rate,
                        two_factor_auth_enabled: branchAdmin.two_factor_auth_enabled,
                        two_factor_auth_code: branchAdmin.two_factor_auth_code,
                        two_factor_auth_code_requested: branchAdmin.two_factor_auth_code_requested,
                        email_signature: branchAdmin.email_signature,
                        created_branch_id: branchAdmin.created_branch_id,
                        gender: branchAdmin.gender,
                        birth_date: branchAdmin.birth_date,
                        joining_date: branchAdmin.joining_date,
                        designation: branchAdmin.designation,
                        address: branchAdmin.address,
                        alternate_name: branchAdmin.alternate_name,
                        alternate_phonenumber: branchAdmin.alternate_phonenumber,
                        alternate_address: branchAdmin.alternate_address,
                        id_proof: branchAdmin.id_proof,
                        past_work_experience: branchAdmin.past_work_experience,
                        show_on_calendar: branchAdmin.show_on_calendar,
                        referred_by_name: branchAdmin.referred_by_name,
                        referred_by_number: branchAdmin.referred_by_number,
                        average_rating: branchAdmin.average_rating,
                        nature: branchAdmin.nature,
                        check_in_time: branchAdmin.check_in_time,
                        check_out_time: branchAdmin.check_out_time,
                        week_off_day: branchAdmin.week_off_day,
                        proof_image: branchAdmin.proof_image,
                        proof_type: branchAdmin.proof_type,
                        report_to_id: branchAdmin.report_to_id,
                        bank_account_number: branchAdmin.bank_account_number,
                        bank_account_holder_name: branchAdmin.bank_account_holder_name,
                        bank_branch_name: branchAdmin.bank_branch_name,
                        bank_branch_address: branchAdmin.bank_branch_address,
                        bank_ifsc_code: branchAdmin.bank_ifsc_code,
                        created_user_id: branchAdmin.created_user_id,
                        last_modified_user_id: branchAdmin.last_modified_user_id,
                        created_date_time: branchAdmin.created_date_time,
                        last_modified_date_time: branchAdmin.last_modified_date_time,
                        isdeleted: branchAdmin.isdeleted,
                        reserve_varchar1: branchAdmin.reserve_varchar1,
                        reserve_varchar2: branchAdmin.reserve_varchar2,
                        reserve_float1: branchAdmin.reserve_float1,
                        reserve_float2: branchAdmin.reserve_float2,
                        reserve_int1: branchAdmin.reserve_int1,
                        reserve_int2: branchAdmin.reserve_int2,
                        reserve_double1: branchAdmin.reserve_double1,
                        reserve_date1: branchAdmin.reserve_date1,
                        reserve_date2: branchAdmin.reserve_date2

                    });

                }
            } catch (error) {
                return null;
            }
        };
        if (selectBranchAdminId) {
            fetchBranchAdminDetails();
        }
    }, [selectBranchAdminId]);

    const fetchBranchAdminTableData = async () => {
        try {
            const response = await getFromAPI(`FtsStaff/?staffid=${selectBranchAdminId}`);
            const branchAdminData = response[0].created_branch_id;

            const getBranchName1 = await getBranchName(branchAdminData);
            const report_to = response[0].report_to_id
            const staffName = getReportToName(report_to);

            // Function to create a downloadable link for the PDF
            const createDownloadablePDFLink = (base64String) => {
                try {
                    // Ensure the string is Base64 encoded before decoding
                    if (!base64String || !/^[A-Za-z0-9+/=]+$/.test(base64String)) {
                        throw new Error('Invalid Base64 string');
                    }

                    const byteCharacters = atob(base64String); // Decode Base64
                    const byteNumbers = new Uint8Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i); // Convert characters to byte numbers
                    }
                    const blob = new Blob([byteNumbers], { type: 'application/pdf' }); // Create a Blob
                    const url = URL.createObjectURL(blob); // Create a URL for the Blob

                    return url; // Return the URL for the PDF download
                } catch (error) {
                    return null; // Return null if an error occurs
                }
            };


            const mappedData = [
                { tableheading1: "Name :", tablesubtext1: response[0].firstname, tableheading2: "Mobile Number :", tablesubtext2: response[0].phonenumber },
                { tableheading1: "Email :", tablesubtext1: response[0].email, tableheading2: "Branch :", tablesubtext2: getBranchName1 },
                { tableheading1: "Birthday :", tablesubtext1: handleDateFormat(response[0].birth_date), tableheading2: "Joining Date :", tablesubtext2: handleDateFormat(response[0].joining_date) },
                { tableheading1: "Gender :", tablesubtext1: response[0].gender, tableheading2: "Alternate Phone Number :", tablesubtext2: response[0].alternate_phonenumber },
                {
                    tableheading1: "Alternate Name:", tablesubtext1: response[0].alternate_name,
                    tableheading2: "Id Proof:",
                    tablesubtext2: <a href={createDownloadablePDFLink(response[0].id_proof)} target="_blank" rel="noopener noreferrer">Download Id Proof</a>
                },
                { tableheading1: "Alternate Address:", tablesubtext1: response[0].alternate_address, tableheading2: "Report To:", tablesubtext2: staffName },
            ];

            setBranchTableData(mappedData);
        } catch (error) {
            return null;
        }
    };


    useEffect(() => {
        fetchBranchAdminTableData();
        fetchBranchAdminData();
    }, [selectBranchAdminId]);


    const getReportToName = (report_to_id) => {
        // Filter the getBranchAdmin data to find the staff that matches the report_to_id
        const staffMember = getBranchAdmin.find(staff => staff.staffid === report_to_id);

        // If a match is found, return the firstname, else return an empty string or a default value
        return staffMember ? staffMember.firstname : 'NA';
    };


    const handleDelete = async (Id) => {
        try {
            showAlert('Are you sure you want to delete this Branch Admin?', 'confirm', (result) => {
                if (result) {
                    // User confirmed the deletion
                    handleDeleteBranchAdmin(Id);
                } else {
                    // User canceled the deletion
                    // You can handle this case if needed
                }
            });
        } catch (error) {
            toast.error('Error deleting Customer', 'error');
            return null;
        }
    };

    const handleDeleteBranchAdmin = async (itemId) => {
        try {
            const response = await deleteFromAPI(`FtsStaff/staffid/${itemId}/`);
            if (response) {
                toast.success('Branch Admin deleted successfully', 'success');
                // Optionally, redirect or update state to reflect deletion
                if (getBranchAdmin.length > 0) {
                    setSelectedBranchAdminId(getBranchAdmin[0].staffid)
                }
                else {
                    setSelectedBranchAdminId('');
                }
            }
        } catch (error) {
            toast.error('Customer Not Deleted', 'error');
            return null;
        }

        fetchBranchAdminData(); // Update the customer list
    };

    const handleSaveChanges = async () => {
        if (!branchAdminInputData.firstname.trim() || !branchAdminInputData.firstname) {
            toast.error('Please Enter First Name', 'error');
            return null;
        }

        if (!branchAdminInputData.created_branch_id) {
            toast.error("Please select the Branch Name", 'error');
            return;
        }
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        if (!branchAdminInputData.email) {
            toast.error("Please enter the Email", 'error');
            return;
        } else if (!emailPattern.test(branchAdminInputData.email)) {
            toast.error("Please enter a valid Email", 'error');
            return;
        }

        if (!branchAdminInputData.password || branchAdminInputData.password.length < 8) {
            toast.error("Please enter valid 8 character password", 'error')
            return;
        }

        if (!branchAdminInputData.gender) {
            toast.error("Please select Gender", 'error');
            return;
        }

        if (!branchAdminInputData.birth_date) {
            toast.error("Please select Birth Date", 'error');
            return;
        }

        if (!branchAdminInputData.joining_date) {
            toast.error("Please select Joining Date", 'error');
            return;
        }

        if (!branchAdminInputData.phonenumber) {
            toast.error("Please enter the Mobile Number", 'error');
            return;
        }

        // Validate mobile number format
        const mobileRegex1 = /^[6-9]\d{9}$/; // Indian mobile number example (10 digits starting with 6-9)
        if (!mobileRegex1.test(branchAdminInputData.phonenumber)) {
            toast.error("Please enter a valid Mobile Number", 'error');
            return;
        }

        // Validate mobile number format
        const mobileRegex2 = /^[6-9]\d{9}$/; // Indian mobile number example (10 digits starting with 6-9)
        if (branchAdminInputData.alternate_phonenumber)
            if (!mobileRegex2.test(branchAdminInputData.alternate_phonenumber)) {
                toast.error("Please enter a valid Alternate Mobile Number", 'error');
                return;
            }

        try {
            await putToAPI(`FtsStaff/staffid/${selectBranchAdminId}/`, branchAdminInputData);
            toast.success('Branch Admin details updated successfully', 'success');
            // setShow(false);
            handleFormModalClose();
            fetchBranchAdminData();
            fetchBranchAdminTableData();

        } catch (error) {
            console.error('Error updating Branch Admin details:', error);
            toast.error('Failed to update Branch Admin details', 'error');
        }
    };

    useEffect(() => {
        const fetchBranch = async () => {
            try {
                const response = await getFromAPI('FtsBranch/');
                if (response) {
                    setBranch(response);
                } else {
                    toast.error('Failed to get branch');
                }
            } catch (error) {
                console.error(error);
                toast.error('Failed to get branch');
            }
        };

        fetchBranch();
    }, []); // Empty dependency array to run only once when the component mounts


    const readFileAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result); // Directly resolve with the Base64 string
            };
            reader.onerror = reject; // Reject if there's an error
            reader.readAsDataURL(file); // Read the file as a Data URL
        });
    };

    const handleFileUpload = async (e) => {
        try {
            const file = e.target.files[0]; // Get the selected file
            if (e.target.id === 'id_proof' && file) {
                const base64PDF = await readFileAsDataURL(file);
                const base64String = base64PDF.split(',')[1]; // Get the Base64 part

                setBranchAdminInputData((prevData) => ({
                    ...prevData,
                    id_proof: base64String, // Store only the Base64 part
                }));
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    // Add Branch modal
    const [formModalShow, setShowFormModal] = useState(false);
    const handleEdit = () => setShowFormModal(true);
    const handleFormModalClose = () => setShowFormModal(false);

    const handleInputChange = (index, field, value) => {
        setBranchAdminInputData({ ...branchAdminInputData, [field]: value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowFormModal(false);
    };

    const rows = [
        {
            columns: [
                { size: { lg: 6 }, label: 'Name', type: 'text', name: 'firstname', id: 'firstname', placeholder: 'Enter name...', value: branchAdminInputData.firstname, onChange: e => setBranchAdminInputData({ ...branchAdminInputData, firstname: e.target.value }), errormsg: '', },
                {
                    size: { lg: 6 }, label: 'Branch', type: 'select', name: 'created_branch_id', id: 'created_branch_id', value: branchAdminInputData.created_branch_id, onChange: e => setBranchAdminInputData({ ...branchAdminInputData, created_branch_id: e.target.value }), errormsg: '', disabled: true,
                    options: [
                        { value: '', label: 'Select Branch' },
                        ...branch.map(item => ({ value: item.id, label: item.name }))
                    ],
                },
                { size: { lg: 6 }, label: 'Email ID', type: 'email', name: 'email', id: 'emailId', placeholder: 'Enter Email...', value: branchAdminInputData.email, onChange: e => setBranchAdminInputData({ ...branchAdminInputData, email: e.target.value }), errormsg: '', },
                { size: { lg: 6 }, label: 'Password', type: 'password', name: 'password', id: 'password', placeholder: 'Enter Password...', value: branchAdminInputData.password, onChange: e => setBranchAdminInputData({ ...branchAdminInputData, password: e.target.value }), errormsg: '', },
                { size: { md: 12 }, label: 'Address', type: 'text', name: 'address', id: 'address', placeholder: 'Enter Address...', value: branchAdminInputData.address, onChange: e => setBranchAdminInputData({ ...branchAdminInputData, address: e.target.value }), errormsg: '', },
                { size: { sm: 6, lg: 4 }, label: 'Mobile Number', type: 'text', name: 'phonenumber', id: 'mobileNumber', placeholder: 'Enter mobile number...', value: branchAdminInputData.phonenumber, onChange: e => setBranchAdminInputData({ ...branchAdminInputData, phonenumber: e.target.value }), errormsg: '', },
                {
                    size: { sm: 6, lg: 4 }, label: 'Gender', type: 'select', name: 'gender', id: 'gender', value: branchAdminInputData.gender, onChange: e => setBranchAdminInputData({ ...branchAdminInputData, gender: e.target.value }), errormsg: '',
                    options: [
                        { value: 'Male', label: 'Male' }, { value: 'Female', label: 'Female' }
                    ],
                },
                { size: { sm: 6, lg: 4 }, label: 'Birthday', type: 'date', name: 'birth_date', id: 'birthday', placeholder: 'DD/MM/YYYY', value: branchAdminInputData.birth_date, onChange: e => setBranchAdminInputData({ ...branchAdminInputData, birth_date: e.target.value }), errormsg: '', },
                { size: { sm: 6, lg: 4 }, label: 'Date of Join', type: 'date', name: 'joining_date', id: 'dateofJoin', placeholder: 'DD/MM/YYYY', value: branchAdminInputData.joining_date, onChange: e => setBranchAdminInputData({ ...branchAdminInputData, joining_date: e.target.value }), errormsg: '', },
                { size: { md: 6, lg: 4 }, label: 'Alternate Name', type: 'text', name: 'alternate_name', id: 'alternateName', placeholder: 'Enter alternate name...', value: branchAdminInputData.alternate_name, onChange: e => setBranchAdminInputData({ ...branchAdminInputData, alternate_name: e.target.value }), errormsg: '', },
                { size: { md: 6, lg: 4 }, label: 'Alternate Phone Number', type: 'text', name: 'alternate_phonenumber', id: 'alternatePhoneNumber', placeholder: 'Enter alternate phone number...', value: branchAdminInputData.alternate_phonenumber, onChange: e => setBranchAdminInputData({ ...branchAdminInputData, alternate_phonenumber: e.target.value }), errormsg: '', },
                { size: { lg: 6 }, label: 'Alternate Address', type: 'text', name: 'alternate_address', id: 'alternateAddress', placeholder: 'Enter alternate address...', value: branchAdminInputData.alternate_address, onChange: e => setBranchAdminInputData({ ...branchAdminInputData, alternate_address: e.target.value }), errormsg: '', },
                { size: { lg: 6 }, label: 'Id Proof (PDF)', type: 'file', accept: 'application/pdf', name: 'id_proof', id: 'id_proof', onChange: e => handleFileUpload(e) },
            ]
        }
    ];
    const Buttons = [
        { variant: "primary", text: "Update", onClick: handleSaveChanges },
        { variant: "secondary", text: "Cancel", onClick: handleFormModalClose },
    ]

    // Permission Checkbox
    const [permissions, setPermissions] = useState(PermissionData);

    const handleModulePermissionChange = (colIndex, subCardIndex) => {
        const updatedPermissions = [...permissions];
        const subCard = updatedPermissions[colIndex].permissionData[subCardIndex];

        // Toggle the "Module Permission"
        subCard.modulePermission = !subCard.modulePermission;

        if (!subCard.modulePermission) {
            // If "Module Permission" is unchecked, uncheck all child checkboxes
            subCard.checkboxes.forEach(checkbox => {
                checkbox.checked = false;
                checkbox.disabled = true; // Disable all checkboxes
            });
        } else {
            // If "Module Permission" is checked, enable only 'View all' and 'View Own'
            subCard.checkboxes.forEach(checkbox => {
                if (checkbox.label === 'View all' || checkbox.label === 'View Own') {
                    checkbox.disabled = false;
                } else {
                    checkbox.disabled = true;
                }
            });
        }

        setPermissions(updatedPermissions);
    };

    const handleCheckboxChange = (colIndex, subCardIndex, checkboxIndex) => {
        const updatedPermissions = [...permissions];
        const checkbox = updatedPermissions[colIndex].permissionData[subCardIndex].checkboxes[checkboxIndex];
        const subCard = updatedPermissions[colIndex].permissionData[subCardIndex];

        // Toggle the checkbox checked state
        checkbox.checked = !checkbox.checked;

        // If "View all" or "View Own" is unchecked, disable the rest of the checkboxes
        if (checkbox.label === 'View all' || checkbox.label === 'View Own') {
            // If both "View all" and "View Own" are unchecked, uncheck and disable the rest of the checkboxes
            const isAnyViewChecked = subCard.checkboxes.some(cbox => (cbox.label === 'View all' || cbox.label === 'View Own') && cbox.checked);
            subCard.checkboxes.forEach(cbox => {
                if (cbox.label !== 'View all' && cbox.label !== 'View Own') {
                    cbox.disabled = !isAnyViewChecked;
                    if (!isAnyViewChecked) cbox.checked = false; // Uncheck if disabled
                }
            });
        }

        setPermissions(updatedPermissions);
    };

    const handleViewTab = () => {
        const viewTab = document.getElementById("pills-view-tab");
        if (viewTab) {
            viewTab.click();  // Ensure the tab is switched programmatically
        }
    };

    return (
        <>
            <Card className='right-content-details'>
                <Card.Body>
                    <div className='d-flex justify-content-between align-items-center mb-3'>
                        <Card.Title className='mb-0'>Details</Card.Title>
                        <div className='d-flex align-items-center flex-wrap gap-3'>
                            <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                                <li className="nav-item d-none" role="presentation">
                                    <button className="nav-link active" id="pills-view-tab" data-bs-toggle="pill" data-bs-target="#pills-view" type="button" role="tab" aria-controls="pills-view" aria-selected="true">View</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-permission-tab" data-bs-toggle="pill" data-bs-target="#pills-permission" type="button" role="tab" aria-controls="pills-permission" aria-selected="false">Permission</button>
                                </li>
                            </ul>
                            <Button variant="soft-info p-0" onClick={handleEdit} disabled={!selectBranchAdminId} >
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiSquareEditOutline} className='avatar-icon' />
                                </div>
                            </Button>
                            <Button variant="soft-danger p-0" onClick={() => handleDelete(selectBranchAdminId)} disabled={!selectBranchAdminId}>
                                <div className='avatar avatar-sm'>
                                    <Icon path={mdiTrashCanOutline} className='avatar-icon' />
                                </div>
                            </Button>
                        </div>
                    </div>

                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-view" role="tabpanel" aria-labelledby="pills-view-tab">
                            <Table responsive className='table-lg border table-nowrap mb-0'>
                                <tbody>
                                    {branchTableData.map((data, i) =>
                                        <tr key={i}>
                                            <th>{data.tableheading1}</th>
                                            <td>{data.tablesubtext1}</td>
                                            <th>{data.tableheading2}</th>
                                            <td>{data.tablesubtext2}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <div className="tab-pane fade" id="pills-permission" role="tabpanel" aria-labelledby="pills-permission-tab">
                            <div className='border rounded p-4'>
                                <Card.Title className='mb-4 pb-2'>Module Name</Card.Title>
                                {permissions.map((permissionCard, colIndex) => (
                                    <React.Fragment key={colIndex}>
                                        {permissionCard.permissionData.map((subCard, subCardIndex) => (
                                            <div key={`${colIndex}-${subCardIndex}`}>
                                                <div className='d-flex align-items-center flex-wrap gap-2'>
                                                    <div className='d-flex align-items-center gap-1 me-xl-4 cw-40'>
                                                        <Form.Check
                                                            type="checkbox"
                                                            id={`module-permission-${colIndex}-${subCardIndex}`}
                                                            checked={subCard.modulePermission || false}
                                                            label=''
                                                            onChange={() => handleModulePermissionChange(colIndex, subCardIndex)}
                                                            className='checkbox-lg'
                                                        />
                                                        <h5 className='fs-18 fw-normal mb-0'>{subCard.cardSubTitle}</h5>
                                                    </div>
                                                    <ul className='list-inline mb-0'>
                                                        {subCard.checkboxes.map((checkbox, checkboxIndex) => (
                                                            <li className='list-inline-item me-4 pe-2' key={`${colIndex}-${subCardIndex}-${checkboxIndex}`}>
                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label={checkbox.label}
                                                                    id={`checkbox-${colIndex}-${subCardIndex}-${checkboxIndex}`}
                                                                    checked={checkbox.checked}
                                                                    onChange={() => handleCheckboxChange(colIndex, subCardIndex, checkboxIndex)}
                                                                    disabled={checkbox.disabled || !subCard.modulePermission}
                                                                    className='checkbox-lg'
                                                                />
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                {permissionCard.permissionData.length - 1 !== subCardIndex && <hr className='my-4' />}
                                            </div>
                                        ))}
                                    </React.Fragment>
                                ))}

                                <div className='d-flex gap-3 justify-content-end mt-4'>
                                    <Button variant="primary" onClick={() => handleViewTab()}>Update</Button>
                                    <Button variant="secondary" onClick={() => handleViewTab()}>Cancel</Button>
                                </div>
                            </div>
                        </div>
                    </div>

                </Card.Body>
            </Card>

            {/* From Modal */}
            <FormModal title="Edit Details" formModalShow={formModalShow} Buttons={Buttons} handleFormModalClose={handleFormModalClose} rows={rows} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
        </>
    )
}
