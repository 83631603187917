import React, { useState, useEffect,useCallback  } from 'react';
import Footer from '../layout/Footer';
import { Container, Row, Col, Card, Form, Table, Button, Nav } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import { postToAPI, getFromAPI, deleteFromAPI, putToAPI, showAlert, formatISODateWithTimezone } from '../../Utils/utils';
import { toast, ToastContainer } from 'react-toastify';
import { fetchBranchData, fetchServiceCategory, fetchProductData } from '../../Utils/function_list';
// Datatable
import JSZip from 'jszip';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from 'datatables.net-dt';

// icon
import Icon from '@mdi/react';
import { mdiMagnify, mdiAccountDetails, mdiPlus, mdiAirplaneCog, mdiSquareEditOutline, mdiTrashCanOutline, mdiCardAccountDetailsOutline } from '@mdi/js';
import FormModal from '../../Utils/FormModal';
import FormInputs from '../../Utils/FormInputs';
import messagebox from '../../Utils/lang';
import messages from '../../Utils/lang';

export default function Services() {
    // multi-select
    const initialInputData = {
        name: '',
        sac: '',
        duration: '',
        category: '',
        earning: '',
        description: '',
        branch_id: '',
        price: '',
    };

    const [inputData, setInputData] = useState(initialInputData)
    const [serviceData, setServiceData] = useState([]);
    const [serviceCategory, setServiceCategoryData] = useState([]);
    const [branchData, setBranchData] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editServiceId, setEditServiceId] = useState(null);
    const [sideBarClickId, setSideBarClickId] = useState(null);
    const [ServiceProductData1, setServiceProductData] = useState([]);
    const serviceProductData = {
        weight_used: '',
        consumption_type: 'NA',
        description: '',
        created_branch_id: 0,
        created_user_id: 0,
        last_modified_user_id: 0,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        prod_detils_prod_weight: '',
        isdelete: 0,
        service: editServiceId,
        product: '',

    }
    const [searchTerm, setSearchTerm] = useState('');
    const [branchServiceDataMap, setBranchServiceDataMap] = useState({}); // To store branch data for each service
    const [addProductInputData, setAddProductInputData] = useState(serviceProductData)
    const [productData, setProductData] = useState([]);

    const fetchServices = async () => {
        try {
            const response = await getFromAPI('FtsService/');
            setServiceData(response); // Assuming response contains an array of service objects
        } catch (error) {
            return null;
        }
    };

    // Add these to your existing state declarations
    const [serviceCategoryInput, setServiceCategoryInput] = useState({
        name: '',
        description: '',
        created_branch_id: 0,
        created_user_id: 0,
        last_modified_user_id: 0,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isdeleted: 0
    });


    const fetchBranchServiceData = async (id) => {
        try {
            const response = await getFromAPI(`FtsServiceBranchDetails/?service_id=${id}`);

            // Assuming response is an array and the first item contains the branch data
            setBranchServiceDataMap(prevState => ({
                ...prevState,
                [id]: response[0] // Store the first item in the response
            }));

        } catch (error) {
            return null;
        }
    };

    const fetchServiceProdutData = async () => {
        try {
            const response = await getFromAPI(`FtsServiceProductDetails/?service=${editServiceId}`);
            setServiceProductData(response);
        }
        catch (error) {
            return null;
        }
    }

    useEffect(() => {
        // Fetch service product data
        fetchServiceProdutData();
    }, [editServiceId])

    // useEffect to fetch branch service data for each service
    useEffect(() => {
        if (serviceData.length > 0) {
            serviceData.forEach(service => {
                fetchBranchServiceData(service.id); // Fetch branch data for each service
            });
        }
    }, [serviceData]); // Run this effect whenever serviceData changes

    useEffect(() => {
        fetchServices(); // Fetch the services when the component mounts
        fetchServiceCategory(setServiceCategoryData)
        fetchBranchData(setBranchData); // Fetch branch data
        fetchProductData(setProductData);
    }, []);


    // Filter the service data based on search term
    const filteredCustomerData = serviceData.filter(service =>
        service?.name?.toLowerCase().includes(searchTerm.toLowerCase()) || false
    );

    // Add a null check for the useEffect that depends on filteredCustomerData
    useEffect(() => {
        if (!filteredCustomerData) return; // Early return if data is not available

        const sidebarItems = document.querySelectorAll(".left-link-item");
        if (!sidebarItems.length) return; // Early return if elements not found

        const handleClick = function () {
            const activeLink = document.querySelector(".left-link-item a.active");
            if (activeLink) activeLink.classList.remove("active");

            const link = this.querySelector('a');
            if (link) link.classList.add('active');

            const vendorId = this.getAttribute('data-id');
            setSideBarClickId(vendorId);

            const viewVendorTab = document.getElementById("pills-view-services-tab");
            if (viewVendorTab) {
                viewVendorTab.click();
            }
        };

        sidebarItems.forEach(item => {
            item.removeEventListener('click', handleClick); // Remove existing listeners
            item.addEventListener('click', handleClick);
        });

        return () => {
            sidebarItems.forEach(item => {
                item.removeEventListener('click', handleClick);
            });
        };
    }, [filteredCustomerData]);

    // Set initial sideBarClickId
    useEffect(() => {
        if (serviceData.length > 0 && !sideBarClickId) {
            setSideBarClickId(serviceData[0].id);
        }
    }, [serviceData, sideBarClickId]);

    DataTable.Buttons.jszip(JSZip);
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    // Function to handle Edit
    const handleEdit = async (id, e) => {
        e.preventDefault();
        try {
            const response = await getFromAPI(`FtsService/${id}/`);

            const service = response.find(item => item.id === id);

            if (service) {
                const branchId = branchServiceDataMap[service.id].branch_id;

                const branchCategoryData = await getFromAPI(`FtsBranch/?id=${branchId}`);

                setInputData({
                    name: service.name,
                    category: service.category,
                    sac: service.sac_code,
                    duration: branchServiceDataMap[service.id].duration,
                    price: branchServiceDataMap[service.id].price,
                    earning: branchServiceDataMap[service.id].earning,
                    description: branchServiceDataMap[service.id].description,
                    branch_id: branchServiceDataMap[service.id].branch_id,
                });

                setAddProductInputData(prevState => ({
                    ...prevState,
                    service: id,
                }));
                setShowServiceModal(true);
                // Set the edit service ID
                setEditServiceId(id);

                // Set the active tab to 'editService'
                setActiveTab('editService');

                // Open the modal
            }
        } catch (error) {
            toast.error("Failed to load service details");
        }
    };

    const handleDelete = async (id) => {
        try {
            const response = await deleteFromAPI(`FtsService/${id}/`);
            if (response.status) {
                toast.success(messages.service.deleted_successfully, 'success');
                fetchServices(); // Refresh the service data
            } else {
                toast.error(messages.service.failed_to_delete);
            }
        } catch (error) {
            toast.error(messages.service.unexpected_error);
        }
    };

    // Function to handle Delete
    const handleDeleteService = (id) => {
        try {
            showAlert('Are you sure you want to delete this Service?', 'confirm', (result) => {
                if (result) {
                    handleDelete(id);
                }
            });
        } catch (error) {
            console.error("Error in deleting service:", error);
        }
    };

    useEffect(() => {
        // Destroy existing DataTable
        if ($.fn.dataTable.isDataTable('#mainDatatableViewServices')) {
            $('#mainDatatableViewServices').DataTable().destroy();
        }
    
        // Initialize DataTable
        const table = $('#mainDatatableViewServices').DataTable({
            dom: "flrtip",
            pageLength: 25,
            data: serviceData.map((data) => [
                data.name || 'No Name',
                branchServiceDataMap[data.id] && branchServiceDataMap[data.id].price ? branchServiceDataMap[data.id].price : 'Price Not Available',
                data.id, // Keep the ID for reference
            ]),
            columns: [
                { title: "Service Details" },
                { title: "Amount" },
                {
                    title: "Action",
                    createdCell: (td) => {
                        td.classList.add('py-2');
                    },
                    render: (data, type, row) => {
                        if (type === 'display') {
                            return `
                                <div class="d-flex gap-2">
                                    <button class="btn btn-soft-info p-0 table-btn edit-button" data-service-id="${row[2]}">
                                        <div class="avatar avatar-xs">
                                            <svg viewBox="0 0 24 24" role="presentation" class="avatar-icon">
                                                <path d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z" style="fill: currentcolor;"></path>
                                            </svg>
                                        </div>
                                    </button>
                                    <button class="btn btn-soft-danger p-0 table-btn delete-button" data-service-id="${row[2]}">
                                        <div class="avatar avatar-xs">
                                            <svg viewBox="0 0 24 24" role="presentation" class="avatar-icon">
                                                <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" style="fill: currentcolor;"></path>
                                            </svg>
                                        </div>
                                    </button>
                                </div>`;
                        }
                        return data;
                    }
                }
            ],
            autoWidth: false,
            language: {
                search: '',
                searchPlaceholder: "Search...",
                paginate: {
                    previous: '«',
                    next: '»',
                },
            }
        });
    
        // Add click event handlers
        $('#mainDatatableViewServices').on('click', '.edit-button', function(e) {
            e.preventDefault();
            const serviceId = $(this).data('service-id');
            if (serviceId) {
                setEditServiceId(serviceId);
                handleEdit(serviceId, e);
                setShowServiceModal(true);
            }
        });
    
        $('#mainDatatableViewServices').on('click', '.delete-button', function(e) {
            e.preventDefault();
            const serviceId = $(this).data('service-id');
            if (serviceId) {
                handleDeleteService(serviceId);
            }
        });
    
        return () => {
            // Clean up event listeners
            $('#mainDatatableViewServices').off('click', '.edit-button');
            $('#mainDatatableViewServices').off('click', '.delete-button');
            if ($.fn.dataTable.isDataTable('#mainDatatableViewServices')) {
                $('#mainDatatableViewServices').DataTable().destroy();
            }
        };
    }, [serviceData, branchServiceDataMap]);

    // Edit Service modal
    const [serviceModalShow, setShowServiceModal] = useState(false);
    const handleServiceModalClose = () => setShowServiceModal(false);
    const [activeTab, setActiveTab] = useState('editService');
    const handleClose = () => {
        setShowServiceModal(false)
    };

    // Function to switch to View Product Category tab
    const switchToViewTab = () => {
        const viewUnitTab = document.getElementById("pills-view-services-tab");
        if (viewUnitTab) {
            viewUnitTab.click();
        }
    };

    // Function to set focus on sidebar item
    const setFocusOnSidebarItem = (unitId) => {
        // Remove active class from all items
        const allLinks = document.querySelectorAll(".left-link-item a");
        allLinks.forEach(link => link.classList.remove("active"));

        // Add active class to the new item
        const newItem = document.querySelector(`.left-link-item[data-id="${unitId}"] a`);
        if (newItem) {
            newItem.classList.add("active");
            // Scroll the item into view
            newItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    };

    const handleServiceSubmit = async () => {

        try {
            if (!inputData.name.trim()) {
                toast.error(messages.service.required_name, 'error')
                return;
            }
            if (!inputData.price) {
                toast.error(messages.service.required_price, 'error')
                return;
            }
            if (inputData.price.includes('.')) {
                // Split the value into the part before and after the decimal
                const [beforeDecimal, afterDecimal] = inputData.price.split('.');
                
                // Check if the part before the decimal has more than 10 digits
                if (beforeDecimal.length > 10) {
                    toast.error(messages.service.price_limit, 'error');
                    return;
                }
            } else {
                // If there's no decimal, check the entire length
                if (inputData.price.length > 10) {
                    toast.error(messages.service.price_limit, 'error');
                    return;
                }
            }
            
            if (!inputData.category || inputData.category.length === 0) {
                toast.error(messages.service.required_service_category, 'error')
                return;
            }
            if (!inputData.sac.trim()) {
                toast.error(messages.service.required_service_sac, 'error');
                return;
            }
            if (!inputData.description.trim()) {
                toast.error(messages.service.required_description, 'error');
                return;
            }
            if (!inputData.duration) {
                toast.error(messages.service.required_duration, 'error')
                return;
            }
            if(inputData.duration.length > 10)
            {
                toast.error(messages.service.duration_length,'error')
                return;
            }
            if (!inputData.earning) {
                toast.error(messages.service.required_earning, 'error')
                return;
            }
            if(inputData.earning.length > 10)
            {
                toast.error(messages.service.earning_length,'error')
                return;
            }
            if (!inputData.branch_id || inputData.branch_id.length == 0) {
                toast.error(messages.service.required_branch, 'error')
                return;
            }

            const santizedData = {
                name: inputData.name,
                category: parseInt(inputData.category),
                sac: inputData.sac,
                duration: parseInt(inputData.duration),
                price: parseFloat(inputData.price),
                earning: parseInt(inputData.earning),
                description: inputData.description,
                branch_id: inputData.branch_id,
            }

            if (editServiceId) {
                // Duplicate name
                const isExist = serviceData.find(service => service.name === inputData.name)
                if (isExist && isExist.id !== parseInt(editServiceId)) {
                    toast.error(messages.service.already_exist, 'error')
                    return;
                }
                // Edit mode
                const response = await putToAPI(`FtsService/${editServiceId}/`, santizedData);
                if (response.status) {
                    toast.success(messages.service.updated_successfully, 'success');
                    setShowServiceModal(false);
                    setEditServiceId('')
                    fetchServices();
                } else {
                    toast.error(messages.service.failed_to_update);
                }
            } else {
                // Duplicate name
                const isExist = serviceData.find(service => service.name === inputData.name)
                if (isExist && isExist.id !== editServiceId) {
                    toast.error(messages.service.already_exist, 'error')
                    return;
                }
                // Add mode
                const response = await postToAPI("create_service/", santizedData);
                if (response) {
                    toast.success(messages.service.added_successfully, 'success');
                    fetchServices();
                    setInputData(initialInputData)
                    const newId = response.id;
                    setSideBarClickId(newId);
                    switchToViewTab();
                    setTimeout(() => {
                        setFocusOnSidebarItem(newId);
                    }, 100);
                } else {
                    toast.error(messages.service.failed_to_add);
                }
            }

        } catch (error) {
            toast.error(messages.service.unexpected_error);
        }
    };

    const handleSubmit1 = async () => {
        setShowEditModal(false);
    }

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleAddServiceTabClick = () => {
        setInputData({
            name: '',
            category: '',
            sac: '',
            duration: '',
            price: '',
            earning: '',
            description: '',
            branch_id: '',
        });
        setEditServiceId(null); // Reset editServiceId to null to switch to Add mode

    };

    // Add New Service Category modal
    const [addNewSerCatModalShow, setShowAddSerCatModal] = useState(false);
    const handleAddSerCatOpen = () => setShowAddSerCatModal(true);
    const handleAddNewSerCatModalClose = () => setShowAddSerCatModal(false);

    // Update the handleAddNewSerCatInputChange function
    const handleAddNewSerCatInputChange = (index, field, value) => {
        setServiceCategoryInput(prev => ({
            ...prev,
            [field]: value
        }));
    };

    // Update the handleAddNewSerCatSubmit function
    const handleAddNewSerCatSubmit = async (e) => {
        e.preventDefault();

        try {
            if (!serviceCategory.name) {
                toast.error(messages.serviceCategory.required_service_category);
                return;
            }
            if (!serviceCategory.description) {
                toast.error(messages.serviceCategory.required_description);
                return;
            }

            const response = await postToAPI('FtsServiceCategory/', {
                ...serviceCategory,
                created_date_time: formatISODateWithTimezone(new Date()),
                last_modified_date_time: formatISODateWithTimezone(new Date())
            });

            if (response) {
                toast.success(messages.serviceCategory.added_successfully);
                setShowAddSerCatModal(false);
                // Reset form
                setServiceCategoryInput({
                    name: '',
                    description: '',
                    created_branch_id: 0,
                    created_user_id: 0,
                    last_modified_user_id: 0,
                    created_date_time: formatISODateWithTimezone(new Date()),
                    last_modified_date_time: formatISODateWithTimezone(new Date()),
                    isdeleted: 0
                });
                // Refresh service categories
                fetchServiceCategory(setServiceCategoryData);
            } else {
                toast.error(messages.serviceCategory.failed_to_add);
            }
        } catch (error) {
            toast.error(messages.serviceCategory.failed_to_add);
        }
    };


    // Update the addNewSerCatRows array
    const addNewSerCatRows = [
        {
            columns: [
                {
                    size: { lg: 12 },
                    label: <>Service Category Name <span style={{ color: 'red' }}>*</span></>,
                    type: 'text',
                    placeholder: 'Enter service category name...',
                    name: 'name',
                    id: 'serviceCategoryName',
                    value: serviceCategory.name,
                    errormsg: '',
                    onChange: e => handleAddNewSerCatInputChange(null, 'name', e.target.value)
                },
                {
                    size: { lg: 12 },
                    label: <>Description <span style={{ color: 'red' }}>*</span></>,
                    type: 'text',
                    placeholder: 'Enter description...',
                    name: 'description',
                    id: 'serviceDescription',
                    value: serviceCategory.description,
                    errormsg: '',
                    onChange: e => handleAddNewSerCatInputChange(null, 'description', e.target.value)
                },
            ]
        }
    ];

    // Update the addNewSerCatButtons array
    const addNewSerCatButtons = [
        {
            variant: "primary",
            text: "Add",
            onClick: handleAddNewSerCatSubmit
        },
        {
            variant: "secondary",
            text: "Cancel",
            onClick: () => {
                setShowAddSerCatModal(false);
                setServiceCategoryInput({
                    name: '',
                    description: '',
                    created_branch_id: 0,
                    created_user_id: 0,
                    last_modified_user_id: 0,
                    created_date_time: formatISODateWithTimezone(new Date()),
                    last_modified_date_time: formatISODateWithTimezone(new Date()),
                    isdeleted: 0
                });
            }
        },
    ];

    const handleCloseproductModule = () => {
        setAddProductInputData(serviceProductData)
    }

    const handleServiceInputChange = (index, field, value) => {

        if (field === "product") {
            const selectedProduct = productData.find(item => item.id === parseInt(value));
            setAddProductInputData({
                ...addProductInputData,
                product: value,
                description: selectedProduct ? selectedProduct.description : '',
                prod_detils_prod_weight: selectedProduct ? selectedProduct.weight : ''
            });
        }
        if (field === "weight_used") {
            setAddProductInputData({
                ...addProductInputData,
                [field]: value
            });
        }
        setInputData({ ...inputData, [field]: value });
    };

    const handleAddProdutDetails = async (editServiceId) => {
        if (!addProductInputData.weight_used) {
            toast.error(messages.serviceProductDetails.required_weight, 'error');
            return;
        }

        if (addProductInputData.product.length === 0) {
            toast.error(messages.serviceProductDetails.reuired_product, 'error');
            return;
        }

        setAddProductInputData({
            ...addProductInputData,
            service: editServiceId
        })

        try {
            const response = await postToAPI('FtsServiceProductDetails/', addProductInputData);
            if (response) {
                toast.success(messages.product.added_successfully, 'success');
                setAddProductInputData(serviceProductData);
                fetchServiceProdutData();
                setShowEditModal(false);
            } else {
                toast.error(messages.product.failed_to_add);
            }
        } catch (error) {
            toast.error(messages.product.unexpected_error);
        }
    }
    const getHeadingText = () => {
        switch (activeTab) {
            case 'editService':
                return 'Edit Service';
            case 'addProduct':
                return 'Product Details';
            case 'addMixture':
                return 'Mixture Details';
            default:
                return 'Edit Service';
        }
    };

    // Function to delete a product
    const handleDeleteProductDetails = async (id) => {
        try {
            const response = await deleteFromAPI(`FtsServiceProductDetails/${id}/`);
            if (response.status) {
                toast.success("Product details deleted successfully");
                fetchServiceProdutData(); // Refresh the product data
            } else {
                toast.error("Failed to delete product details");
            }
        } catch (error) {
            toast.error("An error occurred while deleting product details");
        }
    };

    // Function to confirm and delete a product
    const handleDeleteProduct = (id) => {
        showAlert('Are you sure you want to delete this product details?', 'confirm', async (result) => {
            if (result) {
                await handleDeleteProductDetails(id);
            }
        });
    };

    // Function to get product names
    const getProductName = (id) => {
        return productData.find((item) => item.id === id)?.description || `Product ${id}`;
    };

    // Product list table header
    const productListTHead = ['Product Name', 'Weight Used (Min)', 'Action'];

    // Generate product list table data dynamically
    const productListTData = ServiceProductData1.map((item) => [
        getProductName(item.product), // Map ID to product name or fallback
        `${item.weight_used}`, // Display weight used
        <Button
            variant="soft-danger p-0 avatar avatar-xs"
            key={item.id}
            onClick={() => handleDeleteProduct(item.id)}
        >
            <Icon path={mdiTrashCanOutline} className="avatar-icon" />
        </Button>,
    ]);
    // Mixture List
    const mixtureListTHead = ['Product Details', 'Description', 'Action'];
    const mixtureListTData = [
        ['Product 1', 'Product 001',
            <Button variant="soft-danger p-0 avatar avatar-xs"><Icon path={mdiTrashCanOutline} className='avatar-icon' /></Button>
        ],
        ['Product 2', 'Product 002',
            <Button variant="soft-danger p-0 avatar avatar-xs"><Icon path={mdiTrashCanOutline} className='avatar-icon' /></Button>
        ],
    ];
    // First, create separate button arrays for each tab
    const editServiceButtons = [
        {
            variant: "primary",
            text: "Update",
            onClick: handleServiceSubmit
        },
        {
            color: 'secondary',
            id: 'cancelButton',
            text: 'Cancel',
            onClick: handleClose
        }
    ];

    const addProductButtons = [
        {
            color: 'primary',
            id: 'addProdDetailsButton',
            text: 'Add Now',
            onClick: handleAddProdutDetails
        },
        {
            color: 'secondary',
            id: 'cancelButton',
            text: 'Cancel',
            onClick: handleCloseproductModule
        }
    ];

    const addMixtureButtons = [
        {
            color: 'primary',
            id: 'addProdDetailsButton',
            text: 'Add',
            onClick: handleSubmit1
        },
        {
            color: 'secondary',
            id: 'cancelButton',
            text: 'Cancel',
            onClick: () => setShowEditModal(false)
        }
    ];

    // Function to get the appropriate buttons based on active tab
    const getActiveTabButtons = (activeTab) => {
        switch (activeTab) {
            case 'editService':
                return editServiceButtons;
            case 'addProduct':
                return addProductButtons;
            case 'addMixture':
                return addMixtureButtons;
            default:
                return [];
        }
    };


    // Update the serviceButtons to be dynamic based on active tab
    const serviceButtons = getActiveTabButtons(activeTab);

    // Modify the serviceRows array to remove button definitions
    const serviceRows = [
        {
            columns: [
                { size: { lg: 6 }, tag: 'h5', type: 'normaltext', class: 'mb-2 mb-lg-0', text: getHeadingText(), },
                {
                    size: { lg: 6 }, type: 'button', additionalClass: 'flex-wrap justify-content-sm-end mb-3',
                    buttons: [
                        {
                            color: activeTab === 'editService' ? 'primary' : 'light',
                            id: 'buttonEditService',
                            text: 'Edit Service',
                            handleOnClick: () => setActiveTab('editService')
                        },
                        {
                            color: activeTab === 'addProduct' ? 'primary' : 'light',
                            id: 'buttonAddProduct',
                            text: 'Add Product',
                            handleOnClick: () => setActiveTab('addProduct')
                        },
                        // {
                        //     color: activeTab === 'addMixture' ? 'primary' : 'light',
                        //     id: 'buttonAddMixture',
                        //     text: 'Add Mixture',
                        //     handleOnClick: () => setActiveTab('addMixture')
                        // }
                    ]
                },
            ]
        },
        {
            rowClass: '' + (activeTab === 'editService' ? '' : 'd-none'),
            columns: [
                { size: { lg: 6, xl: 4 }, label: 'Name', type: 'text', name: 'name', id: 'name', placeholder: 'Enter service name...', value: inputData.name, onChange: e => setInputData({ ...inputData, name: e.target.value }), errormsg: '', },
                {
                    size: { lg: 6, xl: 5 }, label: 'Service Category', type: 'select', name: 'category', id: 'serviceCategory', value: inputData.category, handleOnClick: handleAddSerCatOpen, errormsg: '',
                    options: [
                        { value: '', label: 'Select Service Category' },
                        ...serviceCategory.map(item => ({ value: item.id, label: item.name }))
                    ],
                },
                { size: { sm: 6, lg: 6, xl: 3 }, label: 'SAC', type: 'text', name: 'sac', id: 'sac', placeholder: 'Enter SAC...', value: inputData.sac, onChange: e => setInputData({ ...inputData, sac: e.target.value }), errormsg: '', },
                { size: { sm: 6, lg: 6, xl: 3 }, label: 'Duration (Min)', type: 'number', name: 'duration', id: 'duration', placeholder: 'Enter duration...', value: inputData.duration, onChange: e => setInputData({ ...inputData, duration: e.target.value }), errormsg: '', },
                { size: { sm: 6, lg: 3, xl: 2 }, label: 'Price (₹)', type: 'number', name: 'price', id: 'price', placeholder: 'Enter Price...', value: inputData.price, onChange: e => setInputData({ ...inputData, earning: e.target.value }), errormsg: '', },
                { size: { sm: 6, lg: 4, xl: 2 }, label: 'Earning (%)', type: 'number', name: 'earning', id: 'earning', placeholder: 'Enter Earning...', value: inputData.earning, onChange: e => setInputData({ ...inputData, price: e.target.value }), errormsg: '', },
                {
                    size: { lg: 5 }, label: 'Branch', type: 'select', name: 'branch_id', id: 'branch_id', value: inputData.branch_id, onChange: e => setInputData({ ...inputData, branch_id: e.target.value }), errormsg: '',
                    options: [
                        { value: '', label: 'Select Branch' },
                        ...branchData.map(item => ({ value: item.id, label: item.name }))
                    ],
                },
                { size: { sm: 12 }, label: 'Description', type: 'textarea', name: 'description', formrow: '3', id: 'description', placeholder: 'Type description...', value: inputData.description, onChange: e => setInputData({ ...inputData, description: e.target.value }), errormsg: '', }
            ]
        },
        {
            rowClass: '' + (activeTab === 'addProduct' ? '' : 'd-none'),
            columns: [
                {
                    size: { lg: 6 }, label: <>Product Category <span style={{ color: 'red' }}> *</span> </>, type: 'select', name: 'product', id: 'product', value: addProductInputData.product, errormsg: '', onChange: e => handleServiceInputChange(null, 'product', e.target.value), // Attach the onChange handler
                    options: [
                        { value: '', label: 'Select Product' },  // aap dekho na
                        ...productData.map(item => ({ value: item.id, label: item.description }))
                    ],
                },
                {
                    size: { lg: 6 }, label: <>Product Name <span style={{ color: 'red' }}> *</span></>, type: 'text', name: 'product_name', placeholder: 'Enter Product Name...', id: 'productName', value: addProductInputData.description, errormsg: '', readOnly: true,
                },
                { size: { lg: 6 }, label: <>Weight <span style={{ color: 'red' }}> *</span> </>, type: 'number', placeholder: 'Enter weight...', name: 'prod_detils_prod_weight', id: 'prodDetilsProdWeight', value: addProductInputData.prod_detils_prod_weight, errormsg: '', readOnly: true, },
                { size: { lg: 6 }, label: <>Weight Used(Min) <span style={{ color: 'red' }}> *</span></>, type: 'number', placeholder: 'Enter weight used...', name: 'weight_used', id: 'weight_used', value: addProductInputData.weight_used, errormsg: '', onChange: e => setAddProductInputData({ ...addProductInputData, weight_used: e.target.value }) },
                { type: 'table', tableSize: 'sm', class: 'mb-0 table-nowrap', thead: productListTHead, tbodydata: productListTData, }
            ]
        },
        {
            rowClass: '' + (activeTab === 'addMixture' ? '' : 'd-none'),
            columns: [
                {
                    size: { lg: 6 }, label: 'Mixture', type: 'select', name: 'mixture_name', id: 'mixtureName', value: inputData.mixture_name, errormsg: '',
                    options: [
                        { value: '', label: 'Select mixture' },
                    ],
                },
                {
                    size: { lg: 6 }, label: 'Product Name', type: 'select', name: 'mixture_product_name', id: 'mixtureProductName', value: inputData.mixture_product_name, errormsg: '',
                    options: [
                        { value: '', label: 'Select Name' },
                    ],
                },
                { size: { lg: 6 }, label: 'Ratio', type: 'text', placeholder: 'Enter ratio...', name: 'mixture_ratio', id: 'mixtureRatio', value: inputData.mixture_ratio, errormsg: '', },
                { size: { lg: 6 }, label: 'Weight Used(Min)', type: 'text', placeholder: 'Enter Weight Used...', name: 'mixture_weight_used', id: 'mixtureWeightUsed', value: inputData.mixture_weight_used, errormsg: '', },
                { type: 'table', tableSize: 'sm', class: 'mb-0 table-nowrap', thead: mixtureListTHead, tbodydata: mixtureListTData, }
            ]
        }
    ];
    // Add Service Input
    const handleAddServiceChange = (index, field, value) => {
        setInputData({ ...inputData, [field]: value });
    };
    const handleAddServiceSubmit = (e) => {
        e.preventDefault();
    };

    const handleCancel = () => {
        setInputData(initialInputData)
    }

    const isFormValid = () => {
        return (
            inputData.name.trim() !== '' &&
            inputData.category!== '' &&
            inputData.sac.trim()!== '' &&
            inputData.duration > 0 &&
            inputData.price > 0 &&
            inputData.earning > 0 
        );
    };

    const addServiceRows = [
        {
            columns: [
                { size: { md: 6 }, label: <>Name <span style={{ color: 'red' }}> *</span> </>, type: 'text', placeholder: 'Enter your Name...', name: 'name', id: 'yourName', value: inputData.name, errormsg: '', },
                {
                    size: { md: 6 }, label: <>Service Category <span style={{ color: 'red' }}> *</span> </>, type: 'select', name: 'category', id: 'serviceCategory', value: inputData.category, handleOnClick: handleAddSerCatOpen, errormsg: '',
                    options: [
                        { value: '', label: 'Select Service Category' },
                        ...serviceCategory.map(item => ({ value: item.id, label: item.name }))
                    ],
                },
                { size: { md: 6, xxl: 3 }, label: <>SAC <span style={{ color: 'red' }}> *</span> </>, type: 'text', placeholder: 'Enter SAC...', name: 'sac', id: 'sac', value: inputData.sac, errormsg: '', },
                { size: { md: 6, xxl: 3 }, label: <>Duration(Min) <span style={{ color: 'red' }}> *</span> </>, type: 'number', placeholder: 'Enter duration...', name: 'duration', id: 'durationMin', value: inputData.duration, errormsg: '', },
                { size: { md: 6, xxl: 3 }, label: <>Price (₹) <span style={{ color: 'red' }}> *</span> </>, type: 'number', placeholder: 'Enter price...', name: 'price', id: 'price', value: inputData.price, errormsg: '', },
                { size: { md: 6, xxl: 3 }, label: <> Earning(%) <span style={{ color: 'red' }}> *</span> </>, type: 'number', placeholder: 'Enter earning...', name: 'earning', id: 'Earning', value: inputData.earning, errormsg: '', },
                { size: { md: 12 }, label: <>Description <span style={{ color: 'red' }}> *</span> </>, type: 'textarea', placeholder: 'Type description...', formrow: '4', name: 'description', id: 'description', value: inputData.description, },
                {
                    size: { md: 12 }, label: <>Branch <span style={{ color: 'red' }}> *</span>  </>, type: 'select', isMulti: 'true', placeholder: 'Select branch...', name: 'branch_id', id: 'branch_id', value: inputData.branch_id, errormsg: '',
                    options: [
                        { value: "", label: "Select Branch" },
                        ...branchData.map(item => ({ value: item.id, label: item.name }))
                    ],
                },
            ]
        },
    ];
    const addServiceButtons = [
        { variant: "primary", text: "Add", onClick: handleServiceSubmit, disabled: !isFormValid() },
        { variant: "secondary", text: "Cancel", onClick: handleCancel },
    ];


    // Update the serMRCardRows definition to use the selected service data
    const handleSerMRCardChange = (index, field, value) => {
        setInputData({ ...inputData, [field]: value });
    };

    // When a service is selected from the sidebar, populate the input data
    useEffect(() => {
        if (sideBarClickId && serviceData.length > 0) {
            const selectedService = serviceData.find(service => service.id === parseInt(sideBarClickId));
            const selectedBranchService = branchServiceDataMap[sideBarClickId];

            if (selectedService && selectedBranchService) {
                setInputData({
                    name: selectedService.name,
                    category: selectedService.category,
                    sac: selectedService.sac_code,
                    duration: selectedBranchService.duration,
                    price: selectedBranchService.price,
                    earning: selectedBranchService.earning,
                    description: selectedBranchService.description,
                    branch_id: selectedBranchService.branch_id,
                    newRate: '' // For the new rate input
                });
            }
        }
    }, [sideBarClickId, serviceData, branchServiceDataMap]);


    const handleSerMRCardSubmit = async (e) => {
        e.preventDefault();
        try {

            const santizedData = {
                name: inputData.name,
                category: inputData.category,
                sac: inputData.sac,
                duration: inputData.duration,
                price: parseFloat(inputData.newRate), // Use the new rate
                earning: inputData.earning,
                description: inputData.description,
                branch_id: inputData.branch_id,
            }
            const response = await putToAPI(`FtsService/${sideBarClickId}/`, santizedData);
            if (response.status) {
                toast.success("Rate updated successfully");
                // Refresh the branch service data
                fetchBranchServiceData(sideBarClickId);
            } else {
                toast.error("Failed to update rate");
            }
        } catch (error) {
            toast.error("An unexpected error occurred");
        }
    };


    // Get the selected service data
    const selectedService = serviceData.find(service => service.id === parseInt(sideBarClickId));
    const selectedBranchService = branchServiceDataMap[sideBarClickId];
    const serMRCardRows = [
        {
            columns: [
                { size: { xxl: 4 }, label: 'Service Name', type: 'text', placeholder: 'Enter service name...', name: 'serviceName', id: 'serviceName', value: selectedService?.name || '', errormsg: '', readOnly: true },
                { size: { sm: 6, xxl: 4 }, label: 'Current Rate', type: 'text', placeholder: 'Enter current rate...', name: 'price', id: 'currentRate', value: selectedBranchService?.price || '', errormsg: '', readOnly: true },
                { size: { sm: 6, xxl: 4 }, label: 'New Rate', type: 'number', placeholder: 'Enter new rate...', name: 'newRate', id: 'newRate', value: inputData.newRate, errormsg: '', onChange: e => setInputData({ ...inputData, newRate: e.target.value }), },
            ]
        },
    ];
    const serMRCardButtons = [
        { variant: "primary", text: "Update", onClick: handleSerMRCardSubmit },
        { variant: "secondary", text: "Cancel" },
    ];

    return (
        <>
            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='d-lg-flex justify-content-between align-items-center'>
                                            <Form className='custom-form-width'>
                                                <div className='custom-search-bar'>
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control
                                                        className=''
                                                        type="text"
                                                        placeholder={`Search service from ${filteredCustomerData.length} services...`}
                                                        name='serviceSearch'
                                                        id='serviceSearch'
                                                        value={searchTerm}
                                                        onChange={handleSearchChange}
                                                    />
                                                </div>
                                            </Form>
                                            <div className="custom-top-nav-tab mt-3 mt-lg-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-view-services-tab" data-bs-toggle="pill" data-bs-target="#pills-view-services" type="button" role="tab" aria-controls="pills-view-services" aria-selected="true">
                                                            <Icon path={mdiAccountDetails} className="btn-icon me-1" />View Services
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-add-service-tab" data-bs-toggle="pill" data-bs-target="#pills-add-service" type="button" role="tab" aria-controls="pills-add-service" aria-selected="false" onClick={handleAddServiceTabClick}>
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />Add Service
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-manage-rate-card-tab" data-bs-toggle="pill" data-bs-target="#pills-manage-rate-card" type="button" role="tab" aria-controls="pills-manage-rate-card" aria-selected="false">
                                                            <Icon path={mdiCardAccountDetailsOutline} className="btn-icon me-1" />Manage Rate Card
                                                        </button>
                                                    </li>
                                                    {/* <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-import-service-tab" data-bs-toggle="pill" data-bs-target="#pills-import-service" type="button" role="tab" aria-controls="pills-import-service" aria-selected="false">
                                                            <Icon path={mdiAirplaneCog} className="btn-icon me-1" />Import Service
                                                        </button>
                                                    </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} xxl={3}>
                                <Card className='procust-left-list leftbar-list'>
                                    <SimpleBarReact>
                                        <Card.Body>
                                            <div className='left-list-bar'>
                                                {filteredCustomerData && filteredCustomerData.length > 0 ? (
                                                    filteredCustomerData.map((data, i) => (
                                                        <Nav.Item className='left-link-item' key={i} data-id={data.id}>
                                                            <Nav.Link className={data.id === sideBarClickId ? "active" : ""}>

                                                                <h5 className='product-tab-sub-title'>{data.name}</h5>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ))
                                                ) : (
                                                    <p>No data available</p>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>
                            <Col lg={8} xxl={9}>

                                <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-view-services" role="tabpanel" aria-labelledby="pills-view-services-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>View Services</Card.Title>

                                                <Table responsive bordered className='table-nowrap' id='mainDatatableViewServices'>
                                                    <thead className='table-light'></thead>
                                                </Table>
                                            </ Card.Body>
                                        </Card>
                                    </div>
                                    <div className="tab-pane fade" id="pills-add-service" role="tabpanel" aria-labelledby="pills-add-service-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>Add Service</Card.Title>
                                                <FormInputs Buttons={addServiceButtons} rows={addServiceRows} handleInputChange={handleAddServiceChange} handleSubmit={handleAddServiceSubmit} />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="tab-pane fade" id="pills-manage-rate-card" role="tabpanel" aria-labelledby="pills-manage-rate-card-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>Manage Rate Card</Card.Title>
                                                <FormInputs Buttons={serMRCardButtons} rows={serMRCardRows} handleInputChange={handleSerMRCardChange} handleSubmit={handleSerMRCardSubmit} />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="tab-pane fade" id="pills-import-service" role="tabpanel" aria-labelledby="pills-import-service-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>Import Services</Card.Title>
                                                <Row>
                                                    <Col md={12}>
                                                        <Form.Group controlId="formFileImportProducts" className="mb-3">
                                                            <Form.Label>Select File</Form.Label>
                                                            <Form.Control type="file" name='importProducts' />
                                                        </Form.Group>
                                                        <Row className='justify-content-center'>
                                                            <Col xl={8} className="text-center">
                                                                <h5 className='mb-3'>Download a sample csv file that you can populate with your own data</h5>
                                                                <p className='text-muted lh-lg fs-14'>Use the button below to upload a csv file of your service list. Once you have selected a file to upload, your service list will be imported.
                                                                    Duplicate services(based on service name & service category) are not created by this process, so you can correct errors and
                                                                    upload the same file more than once, if necessary.</p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                            <div className='custom-card-footer'>
                                                <div className="btn-list text-end">
                                                    <Button variant="primary">Download Sample</Button>{' '}
                                                    <Button variant="success">Import</Button>{' '}
                                                    <Button variant="secondary">Cancel</Button>{' '}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>

            {/* From Modal */}
            <FormModal formModalShow={serviceModalShow} Buttons={serviceButtons} handleFormModalClose={handleServiceModalClose} rows={serviceRows} handleInputChange={handleServiceInputChange} handleSubmit={handleSubmit1} />
            <FormModal title="Add New Service Category" modalSize="md" formModalShow={addNewSerCatModalShow} Buttons={addNewSerCatButtons} handleFormModalClose={handleAddNewSerCatModalClose} rows={addNewSerCatRows} handleInputChange={handleAddNewSerCatInputChange} handleSubmit={handleAddNewSerCatSubmit} />
        </>
    )
}
