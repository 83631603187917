import React, { useState, useEffect } from 'react';
import Footer from '../../layout/Footer';
import { Container, Row, Col, Card, Form, Nav, Button } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import { getFromAPI,postToAPI,formatISODateWithTimezone } from '../../../Utils/utils';
import PaymentModeData from "./payment-mode-data.json";

// Icons
import Icon from '@mdi/react';
import { mdiFormatListBulletedSquare, mdiPlus, mdiMagnify } from '@mdi/js';
import ViewPaymentMode from './ViewPaymentMode';
import {PaymentModeContext1 } from './paymentModeContext'
import {PaymentModeProvider,usePaymentMode} from './getPaymentModeData';
import {toast} from "react-toastify";
import messages from '../../../Utils/lang';
function PaymentMode() {
    // left navlink active
    const[paymentMode,setPaymentMode] = useState([]);
    const [paymentModeId, setPaymentModeId] = useState('');
    const {paymentModeData,fetchPaymentModeData} = usePaymentMode();
    const [account,setAccount] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const initialInputData = {
        name : '',
        created_branch_id :0,
        description : '',
        show_on_pdf : 0,
        invoices_only : 0,
        expenses_only : 0,
        selected_by_default : 0,
        active : 1,
        isdeleted : 0
    }
    const [inputData, setInputData] = useState({...initialInputData });

    const handleCancel = () => {
        setPaymentModeId(null);
        setInputData({...initialInputData });
    }
    
    const handleSubmit = async (e) => {
        if (!inputData.name || !inputData.name.trim()) {
            toast.error(messages.payment_mode.required_name,'error');
            return;
        }
        if(inputData.selected_by_default.length===0) {
            toast.error(messages.payment_mode.select_account,'error');
            return;
        }
        
        // Already exists
        const isExists = Array.isArray(paymentModeData) && paymentModeData.find(payment => payment.name.trim() === inputData.name.trim());
        
        if (isExists) {
            toast.error(messages.payment_mode.already_exist, 'error');
            return;
        }
        

        try {
            const response = await postToAPI("add_paymentModes_And_AutoEntry/",inputData);
            if(response)
            {
                toast.success('Payment Mode Added Successfully','success');
                setInputData({...initialInputData });
                fetchPaymentModeData();
                 // Get the ID of the newly added product
                
                const newUnitId = response.user_id;
 
                 // Set the newly added product as selected
                 setPaymentModeId(newUnitId);
            // Switch to view tab
                const viewUnitTab = document.getElementById("pills-view-payment-mode-tab");
                if (viewUnitTab) {
                    viewUnitTab.click();
                }

                // Set focus and highlight the new unit in sidebar
                setTimeout(() => {
                    const sidebarItem = document.querySelector(`.left-link-item[data-id="${newUnitId}"] a`);
                    if (sidebarItem) {
                        // Remove active class from all items
                        const allLinks = document.querySelectorAll(".left-link-item a");
                        allLinks.forEach(link => link.classList.remove("active"));

                        // Add active class to new item
                        sidebarItem.classList.add("active");
                        sidebarItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    }
                }, 100);
            } 
        } catch (error) {
            toast.error('An unexpected error occurred', 'error');
        }
    };

    const filteredPaymentModeData = paymentModeData.filter(admin =>
        admin.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

    useEffect(() => {
        const sidebarItems = document.querySelectorAll(".left-link-item"); // Use querySelectorAll for better targeting
    
        // Loop over all sidebar items
        sidebarItems.forEach((item) => {
            item.addEventListener('click', function () {
                // Remove active class from all sidebar links
                const activeLink = document.querySelector(".left-link-item a.active");
                if (activeLink) activeLink.classList.remove("active");
    
                // Add active class to the clicked item
                const link = this.querySelector('a');
                if (link) link.classList.add('active');
    
                // Update selected vendor ID
                const paymentModeId = this.getAttribute('data-id');
                setPaymentModeId(paymentModeId);
    
                // Trigger click on the "View Unit" tab
                const viewModeTab = document.getElementById("pills-view-payment-mode-tab");
                if (viewModeTab) {
                    viewModeTab.click();  // Ensure the tab is switched programmatically
                }
      
            });
        });
    
        // Cleanup listeners on component unmount
        return () => {
            sidebarItems.forEach((item) => {
                item.removeEventListener('click', () => {});
            });
        };
    }, [filteredPaymentModeData]);

    const fetchAccount = async() =>{
        try{
            const response = await getFromAPI("FtsAccount/");
            if(response)
            {
                setAccount(response);
            }
            else
            {
                toast.error('Failed to fetch Payment Modes','error');
            }
        }catch(error){
            toast.error('An unexpected error occurred');
        }
    };
    useEffect(() => {
        fetchAccount();
        fetchPaymentModeData();
    }, []);

    useEffect(() => {
        if (paymentModeData.length>0&&!paymentModeId) {
            setPaymentModeId(paymentModeData[0].id);
        }
    }, [paymentModeData, setPaymentModeId]);  
    
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
      };
    
        // Modified useEffect to handle initial selection and updates
        useEffect(() => {
            if (paymentModeData.length > 0) {
                if (!paymentModeId || !paymentModeData.find(payment => payment.id === paymentModeId)) {
                    setPaymentModeId(paymentModeData[0].id);
                    setFocusOnSidebarItem(paymentModeData[0].id);
                }
            }
        }, [paymentModeData, setPaymentModeId]);
    
        // Modified setFocusOnSidebarItem function
        const setFocusOnSidebarItem = (unitId) => {
            // Remove active class from all items
            const allLinks = document.querySelectorAll(".left-link-item a");
            allLinks.forEach(link => link.classList.remove("active"));
    
            // Add active class to the new item
            const newItem = document.querySelector(`.left-link-item[data-id="${unitId}"] a`);
            if (newItem) {
                newItem.classList.add("active");
                newItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        };
    

    return (
        <>
        <PaymentModeContext1.Provider value={paymentModeId}>

            <div className="page-wrapper">
                <div className='page-content'>
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className='d-md-flex justify-content-between align-items-center'>
                                            <Form className='custom-form-width'>
                                                <div className='custom-search-bar'>
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control className='' type="text" placeholder="Search payment..." name='paymentSearch' id='paymentSearch' onChange={handleSearchChange}/>
                                                </div>
                                            </Form>
                                            <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="pills-view-payment-mode-tab" data-bs-toggle="pill" data-bs-target="#pills-view-payment-mode" type="button" role="tab" aria-controls="pills-view-payment-mode" aria-selected="true">
                                                            <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />View Payment Mode
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="pills-new-payment-mode-tab" data-bs-toggle="pill" data-bs-target="#pills-new-payment-mode" type="button" role="tab" aria-controls="pills-new-payment-mode" aria-selected="false">
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />New Payment Mode
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4} xxl={3}>
                                <Card className='procust-left-list leftbar-list'>
                                    <SimpleBarReact>
                                        <Card.Body>
                                            <div className='left-list-bar'>
                                                {filteredPaymentModeData.map((data, i) =>
                                                    <Nav.Item className='left-link-item' key={i} data-id={data.id} onClick={() => {
                                                        setPaymentModeId(data.id);
                                                        setFocusOnSidebarItem(data.id);
                                                    }}>
                                                       <Nav.Link className={data.id === paymentModeId ? "active" : ""}>
                                                            <h5 className='product-tab-title'>{data.name}</h5>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>
                            <Col lg={8} xxl={9}>
                                <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-view-payment-mode" role="tabpanel" aria-labelledby="pills-view-payment-mode-tab" tabIndex="0">
                                        <ViewPaymentMode setPaymentModeId={setPaymentModeId}/>
                                    </div>
                                    <div className="tab-pane fade" id="pills-new-payment-mode" role="tabpanel" aria-labelledby="pills-new-payment-mode-tab" tabIndex="0">
                                        <Card className='right-content-details'>
                                            <Card.Body>
                                                <Card.Title>New Payment Mode</Card.Title>
                                                <Form>
                                                    <Row>
                                                        <Col xl={6}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Payment Mode Name</Form.Label>
                                                                <Form.Control 
                                                                  type="text" 
                                                                  placeholder='Enter payment mode name...' 
                                                                  name='name' 
                                                                  id='name' 
                                                                  value={inputData.name} 
                                                                  onChange = {e =>  setInputData({...inputData,name : e.target.value})} 
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col xl={6}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Default Account (For Auto Entry)</Form.Label>
                                                                <Form.Select  
                                                                   name='selected_by_default' 
                                                                   id='selected_by_default'
                                                                   value={inputData.selected_by_default}
                                                                   onChange = {e =>  setInputData({...inputData,selected_by_default : e.target.value})}
                                                                >
                                                                <option value="">None</option>
                                                                        {account.map(item => (
                                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                                        ))}
                                                                </Form.Select>
                                                            </div>
                                                        </Col>
                                                        <Col xl={12}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Description</Form.Label>
                                                                <Form.Control 
                                                                  type="text" 
                                                                  placeholder='Enter description...' 
                                                                  name='descriptionPaymentModeName' 
                                                                  id='descriptionPaymentModeName' 
                                                                  value = {inputData.description} 
                                                                  onChange = {e =>  setInputData({...inputData,description : e.target.value})} 
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                            <div className='custom-card-footer'>
                                                <div className='text-end btn-list'>
                                                    <Button variant="primary" onClick={handleSubmit}>Add</Button>{' '}
                                                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>{' '}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
            </PaymentModeContext1.Provider>
        </>
    )
}

export default function CustomersWithProvider() {
    return (
        <PaymentModeProvider>
            <PaymentMode />
        </PaymentModeProvider>
    );
}