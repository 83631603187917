import React, { useState, useEffect,useRef } from 'react';
import Footer from '../../layout/Footer';
import ViewServiceCategory from './ViewServiceCategory';
import { Container, Row, Col, Card, Form, Nav, Button } from 'react-bootstrap';
import SimpleBarReact from "simplebar-react";
import { getFromAPI, postToAPI, formatISODateWithTimezone, handleDateFormat } from '../../../Utils/utils';
import messages from "../../../Utils/lang";
import { toast } from "react-toastify";
import {ServiceModeProvider,useServiceCategory} from './getServiceCategory';
import {serviceCategoryContext } from './serviceCategoryContext'


// Icons
import Icon from '@mdi/react';
import { mdiFormatListBulletedSquare, mdiPlus, mdiMagnify } from '@mdi/js';

function ServiceCategory() {
    const created_branch_id = localStorage.getItem('createdBranchId');
    const initialServiceCategory = {
        name: "",
        description: "",
        created_branch_id: created_branch_id,
        created_user_id: created_branch_id,
        last_modified_user_id: created_branch_id,
        created_date_time: formatISODateWithTimezone(new Date()),
        last_modified_date_time: formatISODateWithTimezone(new Date()),
        isdeleted: 0,
        reserve_varchar1: "",
        reserve_varchar2: "",
        reserve_float1: 0.0,
        reserve_float2: 0.0,
        reserve_int1: 0,
        reserve_int2: 0,
        reserve_double1: "0",
        reserve_date1: handleDateFormat(formatISODateWithTimezone(new Date()))
    };

    const [inputServiceCategory, setInputServiceCategory] = useState(initialServiceCategory);
    const [serviceCategoryId, setServiceCategoryId] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const {serviceModeData,fetchServiceCategory} = useServiceCategory();
    const [serviceCategory,setServiceCategory] = useState([]);

     useEffect(() => {
            fetchServiceCategory(setServiceCategory);
    }, [serviceCategoryId]);

    const handleAddServiceCategory = async () => {
        if (!inputServiceCategory.name || !inputServiceCategory.name.trim()) {
            toast.error(messages.serviceCategory.required_name);
            return;
        }
        if (!inputServiceCategory.description || !inputServiceCategory.description.trim()) {
            toast.error(messages.serviceCategory.required_description);
            return;
        }

        // Already exists
        const isxists = serviceModeData.find(service => service.name === inputServiceCategory.name.trim());
        if (isxists) {
            toast.error(messages.serviceCategory.already_exist);
            return;
        }

        try {
            const response = await postToAPI('FtsServiceCategory/', inputServiceCategory);
            if (response) {
                toast.success(messages.serviceCategory.added_successfully);
                fetchServiceCategory();
                setInputServiceCategory(initialServiceCategory); // Reset form
                const newId = response.data.id;
                setServiceCategoryId(newId);

                // Switch to the new service category
                const viewTab = document.getElementById("pills-view-service-category-tab")
                if (viewTab) {
                    viewTab.click();
                }
                // Set focus and highlight the new unit in sidebar
                setTimeout(() => {
                    const sidebarItem = document.querySelector(`.left-link-item[data-id="${newId}"] a`);
                    if (sidebarItem) {
                        // Remove active class from all items
                        const allLinks = document.querySelectorAll(".left-link-item a");
                        allLinks.forEach(link => link.classList.remove("active"));

                        // Add active class to new item
                        sidebarItem.classList.add("active");
                        sidebarItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                    }
                }, 100);
            }
        } catch (error) {
            toast.error('An unexpected error occurred', 'error');
            console.error(error);
        }
    };

    const filteredserviceModeData = serviceModeData.filter(service =>
        service.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    const handleCancel = () => {
        setInputServiceCategory(initialServiceCategory);
    };

    useEffect(() => {
        const sidebarItems = document.querySelectorAll(".left-link-item"); // Use querySelectorAll for better targeting

        // Loop over all sidebar items
        sidebarItems.forEach((item) => {
            item.addEventListener('click', function () {
                // Remove active class from all sidebar links
                const activeLink = document.querySelector(".left-link-item a.active");
                if (activeLink) activeLink.classList.remove("active");

                // Add active class to the clicked item
                const link = this.querySelector('a');
                if (link) link.classList.add('active');

                // Update selected vendor ID
                const serviceId = this.getAttribute('data-id');
                setServiceCategoryId(serviceId);

                // Trigger click on the "View Unit" tab
                const viewModeTab = document.getElementById("pills-view-service-category-tab");
                if (viewModeTab) {
                    viewModeTab.click();  // Ensure the tab is switched programmatically
                }

            });
        });

        // Cleanup listeners on component unmount
        return () => {
            sidebarItems.forEach((item) => {
                item.removeEventListener('click', () => { });
            });
        };
    }, [serviceModeData]);

    // Modified useEffect to handle initial selection and updates
    useEffect(() => {
        if (serviceModeData.length > 0) {
            if (!serviceCategoryId || !serviceModeData.find(service => service.id === serviceCategoryId)) {
                setServiceCategoryId(serviceModeData[0].id);
                setFocusOnSidebarItem(serviceModeData[0].id);
            }
        }
    }, [serviceModeData, setServiceCategoryId]);

    // Modified setFocusOnSidebarItem function
    const setFocusOnSidebarItem = (unitId) => {
        // Remove active class from all items
        const allLinks = document.querySelectorAll(".left-link-item a");
        allLinks.forEach(link => link.classList.remove("active"));

        // Add active class to the new item
        const newItem = document.querySelector(`.left-link-item[data-id="${unitId}"] a`);
        if (newItem) {
            newItem.classList.add("active");
            newItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const isFormValid = () =>{
        return (
          inputServiceCategory.name.trim() !== '' && inputServiceCategory.description.trim() !== ''
        );
    }

    const searchInputRef = useRef(null);
    
    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);

    return (
        <>
        <serviceCategoryContext.Provider value={serviceCategoryId}>
        
            <div className="page-wrapper">
                <div className="page-content">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <div className="d-md-flex justify-content-between align-items-center">
                                            <Form className="custom-form-width">
                                                <div className="custom-search-bar">
                                                    <Icon path={mdiMagnify} size={1} className="custom-search-input-icon" />
                                                    <Form.Control
                                                        type="text"
                                                        placeholder={`Search service category from ${serviceModeData.length} from service vategory...`}
                                                        name="serviceCategorySearch"
                                                        id="serviceCategorySearch"
                                                        autoFocus 
                                                        ref={searchInputRef} // Attach ref
                                                        onChange={handleSearchChange}
                                                    />
                                                </div>
                                            </Form>
                                            <div className="custom-top-nav-tab mt-3 mt-md-0">
                                                <ul className="nav nav-pills btn-list" id="pills-tab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className="nav-link active"
                                                            id="pills-view-service-category-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-view-service-category"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-view-service-category"
                                                            aria-selected="true"
                                                        >
                                                            <Icon path={mdiFormatListBulletedSquare} className="btn-icon me-1" />
                                                            View Service Category
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className="nav-link"
                                                            id="pills-new-service-category-tab"
                                                            data-bs-toggle="pill"
                                                            data-bs-target="#pills-new-service-category"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="pills-new-service-category"
                                                            aria-selected="false"
                                                        >
                                                            <Icon path={mdiPlus} className="btn-icon me-1" />
                                                            New Service Category
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={4} xxl={3}>
                                <Card className="procust-left-list leftbar-list">
                                    <SimpleBarReact>
                                        <Card.Body>
                                            <div className="left-list-bar">
                                                {filteredserviceModeData.map((data, i) => (
                                                    <Nav.Item className="left-link-item" key={i} data-id={data.id} onClick={() => {
                                                        setServiceCategoryId(data.id);
                                                        setFocusOnSidebarItem(data.id);
                                                    }}>
                                                       <Nav.Link className={data.id === serviceCategoryId ? "active" : ""}>
                                                            <h5 className="product-tab-title">{data.name}</h5>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                            </div>
                                        </Card.Body>
                                    </SimpleBarReact>
                                </Card>
                            </Col>
                            <Col lg={8} xxl={9}>
                                <div className="tab-content custom-top-nav-tab" id="pills-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="pills-view-service-category"
                                        role="tabpanel"
                                        aria-labelledby="pills-view-service-category-tab"
                                        tabIndex="0"
                                    >
                                        <ViewServiceCategory setServiceCategoryId = {setServiceCategoryId}/>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="pills-new-service-category"
                                        role="tabpanel"
                                        aria-labelledby="pills-new-service-category-tab"
                                        tabIndex="0"
                                    >
                                        <Card className="right-content-details">
                                            <Card.Body>
                                                <Card.Title>New Service Category</Card.Title>
                                                <Form>
                                                    <Row>
                                                        <Col sm={12}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Service Category Name  <span className='text-danger'>*</span></Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter service category name..."
                                                                    name="serviceCategoryName"
                                                                    id="serviceCategoryName"
                                                                    value={inputServiceCategory.name}
                                                                    onChange={(e) =>
                                                                        setInputServiceCategory((prevState) => ({
                                                                            ...prevState,
                                                                            name: e.target.value,
                                                                        }))
                                                                    }
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={12}>
                                                            <div className="mb-3 custom-form-input">
                                                                <Form.Label>Service Description  <span className='text-danger'>*</span></Form.Label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    placeholder="Service category description..."
                                                                    rows={4}
                                                                    name="serviceDescription"
                                                                    id="serviceDescription"
                                                                    value={inputServiceCategory.description}
                                                                    onChange={(e) =>
                                                                        setInputServiceCategory((prevState) => ({
                                                                            ...prevState,
                                                                            description: e.target.value,
                                                                        }))
                                                                    }
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Card.Body>
                                            <div className="custom-card-footer">
                                                <div className="text-end btn-list">
                                                    <Button variant="primary" onClick={handleAddServiceCategory} disabled = {!isFormValid()}>
                                                        Add
                                                    </Button>{' '}
                                                    <Button variant="secondary" onClick={handleCancel}>Cancel</Button>{' '}
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
            </serviceCategoryContext.Provider>
        </>
    );
}

export default function CustomersWithProvider() {
    return (
        <ServiceModeProvider>
            <ServiceCategory/>
        </ServiceModeProvider>
    );
}